import { Form } from 'antd';
import RadioList from '../../../../components/RadioList';
import RadioListItem from '../../../../components/RadioList/RadioListItem';
import SelectAudience from './SelectAudience';

import allCustomer from '../../../../assets/images/all_customers.svg';
import audience from '../../../../assets/images/audience.png';

const AudienceList = function AudienceList({
  handleChooseModalOpen,
  handleRemoveSegment,
}) {
  return (
    <RadioList name='audienceRadio'>
      <RadioListItem
        item={{
          id: 1,
          title: 'All customers',
          description: 'Show this offer to all the customers',
          avatar: allCustomer,
        }}
        value={0}
      />

      <Form.Item dependencies={['audienceRadio']}>
        {({ getFieldValue }) => (
          <RadioListItem
            item={{
              id: 2,
              title: 'Specific customer groups',
              description: 'Show this offer to selected customers',
              avatar: audience,
            }}
            value={1}
          >
            {' '}
            {getFieldValue('audienceRadio') === 1 && (
              <SelectAudience
                handleChooseModalOpen={handleChooseModalOpen}
                handleSegmentDeselect={handleRemoveSegment}
              />
            )}
          </RadioListItem>
        )}
      </Form.Item>
    </RadioList>
  );
};

export default AudienceList;
