import { Empty, message, Row, Col, Spin, Button } from 'antd';
import { default as StarFilled } from '@ant-design/icons/lib/icons/StarFilled';
import { useEffect, useState } from 'react';
import axios from 'axios';
import OfferCard from '../OfferCard';
import classes from './index.module.less';
import useMounted from '../../../hooks/useMounted';

// Data required

//   {
//     id: 1,
//     title: 'Freshdesk Omnichannel Suite',
//     description: 'Delight your customers with effortless omnichannel service.',
//     tag: 'Recommended',
//     tagColor: 'orange',
//     tagIcon: <BulbFilled />,
//     imageSrc: whatsappIcon,
//   }

const Dashboard = function Dashboard() {
  const mounted = useMounted();
  const [offerPacks, setOfferPacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);

  const fetchOfferPacks = async (cursor, loadMoreClicked) => {
    setLoading(true);
    let res;
    try {
      res = await axios.get(`/campaign_templates?next=${cursor || ''}`);
    } catch (err) {
      console.error(err);
    }
    if (!mounted.current) {
      return;
    }
    if (res) {
      /**
       * ! Currently tag are hardcored from frontend only
       * ? Update the tag based on api when getting specific keys
       */
      if (loadMoreClicked) {
        setOfferPacks((prev) => [
          ...prev,
          ...res.data.results.map((offer) => ({
            ...offer,
            tag: 'Top Ranked',
            tagColor: 'green',
            tagIcon: <StarFilled />,
          })),
        ]);
      } else {
        setOfferPacks(
          res.data.results.map((offer) => ({
            ...offer,
            tag: 'Top Ranked',
            tagColor: 'green',
            tagIcon: <StarFilled />,
          })),
        );
      }
      const { results, ...rest } = res.data;
      setMetaData(rest);
    } else {
      // Error might have occured
      message.error('Something went wrong');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOfferPacks(null, false);
  }, []);

  // Load more button click handler
  // if metadata having hasNext value true then show the load more button
  const loadMoreClicked = () => {
    if (metaData.hasNext) {
      fetchOfferPacks(metaData.next, true);
    } else {
      fetchOfferPacks(null, true);
    }
  };

  return (
    <Spin spinning={loading} tip='Loading Offers...'>
      <Row gutter={[0, 12]} className={classes.root}>
        {offerPacks && offerPacks.length !== 0 ? (
          offerPacks.map((offerPack) => (
            <OfferCard key={offerPack.id} offerPack={offerPack} />
          ))
        ) : (
          <Col span={24}>
            <Empty description={loading ? false : 'No offers'} />
          </Col>
        )}
      </Row>
      {metaData?.hasNext && (
        <div className={classes.loadMoreBtn}>
          <Button type='link' onClick={() => loadMoreClicked()}>
            Load More
          </Button>
        </div>
      )}
    </Spin>
  );
};

export default Dashboard;
