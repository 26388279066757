import { Typography, Slider, Form, Row, Col } from 'antd';
import { offerDiscountOptions } from '../../../../utils/constants/discountTypes';

import classes from './PercentageDiscountSlider.module.less';

const { Text } = Typography;

const PercentageDiscountSlider = function PercentageDiscountSlider() {
  return (
    <Form.Item
      dependencies={['discount_type', 'percentage_discount_value']}
      noStyle
    >
      {(form) =>
        form.getFieldValue('discount_type') ===
          offerDiscountOptions.percent.value && (
          <>
            <Row justify='center'>
              <Col>
                <Text className={classes.percentText}>
                  {form.getFieldValue('percentage_discount_value')}%
                </Text>
              </Col>
            </Row>
            <Form.Item
              className={classes.lastFormItem}
              name='percentage_discount_value'
              rules={[
                {
                  required: true,
                  message: 'Add percentage discount.',
                  type: 'number',
                  min: 1,
                },
              ]}
            >
              <Slider tooltipVisible={false} />
            </Form.Item>
          </>
        )
      }
    </Form.Item>
  );
};

export default PercentageDiscountSlider;
