import { Select } from 'antd';
import { default as AppstoreOutlined } from '@ant-design/icons/lib/icons/AppstoreOutlined';
import ContainerSider from '../../../components/ContainerLayout/ContainerSider';

export const filterItems = [
  {
    key: 'all',
    title: 'All',
    getIcon: () => <AppstoreOutlined />,
  },
  // {
  //   key: 'festive',
  //   title: 'Festive',
  //   getIcon: () => <ClockCircleOutlined />,
  // },
];

export const FilterSelect = function FilterSelect({
  selectedFilter,
  onSelectedFilterChange,
}) {
  return (
    <Select value={selectedFilter} onChange={onSelectedFilterChange}>
      {filterItems.map((item) => (
        <Select.Option value={item.key} key={item.key}>
          {item.title}
        </Select.Option>
      ))}
    </Select>
  );
};

const SideBar = function SideBar({ selectedFilter, onSelectedFilterChange }) {
  return (
    <ContainerSider
      items={filterItems}
      selectedFilter={selectedFilter}
      title='Offer Packs'
      onSelectedFilterChange={onSelectedFilterChange}
    />
  );
};

export default SideBar;
