import { InfoCircleOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import classes from './CheckoutStatusRibbonUI.module.less';

export default function CheckoutStatusRibbonUI({ openModal }) {
  return (
    <div className={classes.statusRibbonUi_Wrapper}>
      <Space size={15}>
        <InfoCircleOutlined
          className={classes.infoIcon}
          style={{ fontSize: '40px', color: '#ff0000' }}
        />
        <div className={classes.content}>
          <Typography.Title
            level={2}
            style={{ fontSize: 22, fontWeight: 600, marginBottom: 0 }}
          >
            Enable the Offers365 app on your store
          </Typography.Title>
          <Typography.Title level={3}>
            It is mandatory for the campaigns to run on your store.
          </Typography.Title>
        </div>
      </Space>

      <Button
        className={`primaryButton ${classes.openStatusModalBtn}`}
        onClick={openModal}
        type='primary'
      >
        See the steps
      </Button>
    </div>
  );
}
