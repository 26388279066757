import { Form, List, Radio } from 'antd';

import classes from './index.module.less';

const RadioList = function RadioList({ name, children }) {
  return (
    <div className={classes.list}>
      <List>
        <Form.Item name={name}>
          <Radio.Group>{children}</Radio.Group>
        </Form.Item>
      </List>
    </div>
  );
};

export default RadioList;
