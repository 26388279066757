/* eslint-disable no-nested-ternary */
import {
  Select,
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';

import { default as ArrowLeftOutlined } from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import { default as ArrowRightOutlined } from '@ant-design/icons/lib/icons/ArrowRightOutlined';
import { useContext, useState } from 'react';

import moment from 'moment';
import previewImage1 from '../../../assets/images/preview.png';
import previewImage2 from '../../../assets/images/previewImage2.jpeg';
import previewImage3 from '../../../assets/images/previewImage3.jpg';
import arrowIcon from '../../../assets/images/arrowIcon.png';
import classes from './PreviewCard.module.less';
import ShopContext from '../../../context/ShopContext';
import { offerDiscountOptions } from '../../../utils/constants/discountTypes';
import SettingsContext from '../../../context/SettingContext';
import PreviewTimerHeading from '../../../components/PreviewTimerHeading';

const previewImages = [previewImage1, previewImage2, previewImage3];

const PreviewCard = function PreviewCard() {
  const { shop } = useContext(ShopContext);
  const [count, setCount] = useState(0);
  const { settings } = useContext(SettingsContext);

  const handleNext = () => {
    setCount((prev) => {
      if (prev === 2) {
        return 0;
      }
      return prev + 1;
    });
  };

  const handlePrevious = () => {
    setCount((prev) => {
      if (prev === 0) {
        return 2;
      }
      return prev - 1;
    });
  };

  const currencyFormat = (num) =>
    `${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

  return (
    <Space
      direction='vertical'
      className={classes.space}
      style={{ padding: 12 }}
    >
      <Typography.Title
        level={4}
        style={{ marginBottom: 0, lineHeight: 1.4 }}
        className={classes.previewText}
      >
        Upsell Offer Preview
        <Image
          style={{
            height: '1rem',
            width: '1rem',
            marginLeft: '5px',
          }}
          preview={false}
          src={arrowIcon}
          alt='preview'
        />
      </Typography.Title>
      <Divider style={{ margin: 0 }} />

      <Form.Item
        dependencies={[
          'heading_text',
          ['heading_text', 0],
          ['heading_text', 1],
          ['heading_text', 2],
          ['heading_text', 3],
          ['heading_text', 4],
          ['heading_text', 5],
          'sub_heading_text',
          'accept_offer_button_text',
          'decline_offer_button_text',
          'discount_type',
          'fixed_discount_value',
          'percentage_discount_value',
          'offer_no_of_products_to_show',
          'enableCampaignTimeout',
          'campaignTimeout',
        ]}
      >
        {({ getFieldValue }) => {
          const headings = getFieldValue('heading_text');

          const acceptText = getFieldValue('accept_offer_button_text');
          const declineText = getFieldValue('decline_offer_button_text');
          const discountType = getFieldValue('discount_type');
          const noOfProducts = getFieldValue('offer_no_of_products_to_show');

          const isShowTimeout = getFieldValue('enableCampaignTimeout');

          let timeout = isShowTimeout && getFieldValue('campaignTimeout');

          if (timeout) {
            timeout = moment(timeout.toString(), 'm').format('mm[m] ss[s]');
          } else {
            timeout = '';
          }

          const defaultPrice =
            discountType === offerDiscountOptions.fixed.value
              ? parseInt(
                  (getFieldValue('fixed_discount_value') * 2.5).toString(10),
                  10,
                )
              : 20;
          let price = defaultPrice;

          // price =
          //   discountType !== offerDiscountOptions.no.value
          //     ? discountType === offerDiscountOptions.fixed.value
          //       ? defaultPrice - getFieldValue('fixed_discount_value')
          //       : defaultPrice -
          //         (defaultPrice * getFieldValue('percentage_discount_value')) /
          //           100
          //     : defaultPrice;

          price =
            discountType === offerDiscountOptions.fixed.value
              ? defaultPrice - getFieldValue('fixed_discount_value')
              : defaultPrice -
                (defaultPrice * getFieldValue('percentage_discount_value')) /
                  100;

          const tax = 3;
          const shippingCharge = 0;

          const payAmount = `${shop.currency} ${currencyFormat(
            price > 0 ? price + tax + shippingCharge : tax + shippingCharge,
          )}`;

          function payNowText(text, amountTotal) {
            let newText = text;
            if (text.includes('{{total_amount}}')) {
              const newTotal = amountTotal;
              newText = text.replace(/{{total_amount}}/g, newTotal);
            }
            return newText;
          }

          return (
            <Space
              direction='vertical'
              wrap={false}
              align='center'
              size={6}
              class={classes.contentContainer}
            >
              {headings &&
                headings.map((heading) => (
                  <Typography.Text
                    className={classes.headings}
                    style={{
                      fontSize: 18,
                      lineHeight: 1.4,
                      fontWeight: 600,
                    }}
                  >
                    {heading}
                  </Typography.Text>
                ))}

              {isShowTimeout && timeout && (
                <PreviewTimerHeading
                  isShowTimeout={isShowTimeout}
                  timeout={timeout}
                  subHeadingText={getFieldValue('sub_heading_text')}
                />
              )}

              <Row>
                <Col span={8}>
                  <Image
                    preview={false}
                    src={
                      noOfProducts > 1 ? previewImages[count] : previewImage1
                    }
                    alt='preview'
                    // style={{ margin: 10 }}
                    // height={250}
                  />
                  {noOfProducts !== 1 && (
                    <Space
                      className={classes.swipeableBtn}
                      size={0}
                      direction='horizontal'
                      align='center'
                    >
                      <Button
                        onClick={handlePrevious}
                        size='small'
                        type='ghost'
                        icon={<ArrowLeftOutlined />}
                      />
                      <Button
                        onClick={handleNext}
                        size='small'
                        type='ghost'
                        icon={<ArrowRightOutlined />}
                      />
                    </Space>
                  )}
                </Col>
                <Col span={16} className={classes.rightSection}>
                  <Space direction='vertical' size={3}>
                    <Typography.Text style={{ fontSize: '1.3rem' }}>
                      Checked Shirt
                    </Typography.Text>
                    {/* {discountType !== offerDiscountOptions.no.value ? ( */}
                    {/*   <div> */}
                    {/*     <Typography.Text */}
                    {/*       delete */}
                    {/*       type='secondary' */}
                    {/*       style={{ marginRight: 10 }} */}
                    {/*     > */}
                    {/*       {`${shop.currency} ${currencyFormat(defaultPrice)}`} */}
                    {/*     </Typography.Text> */}
                    {/*     {!settings.isOnboardingCompleted && <br />} */}
                    {/*     <Typography.Text>{`${shop.currency} ${currencyFormat( */}
                    {/*       price >= 0 ? price : 0, */}
                    {/*     )}`}</Typography.Text> */}
                    {/*   </div> */}
                    {/* ) : ( */}
                    {/*   <Typography.Text>{`${shop.currency} ${currencyFormat( */}
                    {/*     defaultPrice, */}
                    {/*   )}`}</Typography.Text> */}
                    {/* )} */}
                    <div>
                      <Typography.Text
                        delete
                        type='secondary'
                        style={{ marginRight: 10 }}
                      >
                        {`${shop.currency} ${currencyFormat(defaultPrice)}`}
                      </Typography.Text>
                      {!settings.isOnboardingCompleted && <br />}
                      <Typography.Text>{`${shop.currency} ${currencyFormat(
                        price >= 0 ? price : 0,
                      )}`}</Typography.Text>{' '}
                      {discountType !== offerDiscountOptions.no?.value && (
                        <Typography.Text className={classes.discountBracket}>
                          ({' '}
                          {discountType === offerDiscountOptions.fixed.value
                            ? `${shop.currency} ${currencyFormat(
                                getFieldValue('fixed_discount_value'),
                              )}`
                            : `${getFieldValue(
                                'percentage_discount_value',
                              )}%`}{' '}
                          off )
                        </Typography.Text>
                      )}
                    </div>
                    <Space
                      direction='horizontal'
                      size={16}
                      wrap={false}
                      align='start'
                      className={classes.inputGroup}
                      style={{
                        marginTop: '0.6rem',
                        flexFlow: settings.isOnboardingCompleted
                          ? 'row'
                          : 'column',
                      }}
                    >
                      <Form.Item
                        label={
                          <Typography.Text type='secondary'>
                            Quantity
                          </Typography.Text>
                        }
                      >
                        <Input
                          type='number'
                          aria-placeholder='Quantity'
                          defaultValue={1}
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item
                        label={
                          <Typography.Text type='secondary'>
                            Design
                          </Typography.Text>
                        }
                      >
                        <Select defaultValue='classic'>
                          <Select.Option value='classic'>Classic</Select.Option>
                          <Select.Option value='premium'>Premium</Select.Option>
                        </Select>
                      </Form.Item>
                    </Space>
                    <Divider style={{ margin: 5 }} />
                    <div className={classes.infoBox}>
                      <Typography.Text type='secondary'>
                        Subtotal
                      </Typography.Text>
                      <Typography.Text>{`${shop.currency} ${currencyFormat(
                        price >= 0 ? price : 0,
                      )}`}</Typography.Text>
                    </div>
                    <div className={classes.infoBox}>
                      <Typography.Text type='secondary'>
                        Shipping
                      </Typography.Text>
                      <Typography.Text>Free</Typography.Text>
                    </div>
                    <div className={classes.infoBox}>
                      <Typography.Text type='secondary'>Taxes</Typography.Text>
                      <Typography.Text>{`${shop.currency} ${currencyFormat(
                        tax,
                      )}`}</Typography.Text>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <div className={`${classes.infoBox} ${classes.total}`}>
                      <Typography.Text>Total</Typography.Text>
                      <Typography.Text>{payAmount}</Typography.Text>
                    </div>
                    <div>
                      <Button block type='primary' className={classes.payBtn}>
                        {payNowText(acceptText, payAmount)}
                      </Button>
                      <Button type='link' className={classes.declineBtn}>
                        {declineText}
                      </Button>
                    </div>
                  </Space>
                </Col>
              </Row>
            </Space>
          );
        }}
      </Form.Item>
    </Space>
  );
};

export default PreviewCard;
