import { Avatar, List, Radio, Typography } from 'antd';
import classes from './index.module.less';

const RadioListItem = function RadioListItem({
  item,
  value,
  disabled,
  children,
}) {
  const title = <Typography.Text strong>{item && item.title}</Typography.Text>;

  return (
    <>
      <List.Item key={item && item.title} className={classes.listItem}>
        <List.Item.Meta
          avatar={
            <Avatar
              src={item && item.avatar}
              alt='icons'
              className={classes.avatar}
            />
          }
          title={title}
          description={item && item.description}
        />
        <Radio value={value} disabled={disabled} />
      </List.Item>
      {children}
    </>
  );
};

export default RadioListItem;
