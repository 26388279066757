/* eslint-disable import/no-cycle */
import { Card, Divider, Space } from 'antd';
import BlockRadioButtons from '../shared/BlockRadioButtons';
import PercentageDiscountSlider from './PercentageDiscountSlider';
import FixedDiscountInput from './FixedDiscountInput';
import CardTitle from '../shared/CardTitle';
import { offerDiscountOptions } from '../../../../utils/constants/discountTypes';

import classes from './PercentageDiscountSlider.module.less';

const OfferDiscountCard = function OfferDiscountCard() {
  const radioOptions = Object.values(offerDiscountOptions);
  const radioFormItemProps = {
    name: 'discount_type',
    style: { marginTop: 8 },
  };

  return (
    <Card>
      <Space
        className={`fullWidth ${classes.discountGroup}`}
        direction='vertical'
        size={16}
      >
        <CardTitle
          title='Step 3: Discount'
          // helpText='Help'
          description='Select the discount type and its corresponding value'
        />
        <Divider style={{ marginBlock: 0 }} />
        <BlockRadioButtons
          formItemProps={radioFormItemProps}
          options={radioOptions}
        />
        <div>
          <FixedDiscountInput />
          <PercentageDiscountSlider />
          {/* <NoDiscount /> */}
        </div>
      </Space>
    </Card>
  );
};

export default OfferDiscountCard;
