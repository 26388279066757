import { Space } from 'antd';
import { OfferProductNote } from '../../Campaigns/CreateCampaignModel/OfferProductCard';
import OfferList from '../../Campaigns/CreateCampaignModel/OfferProductCard/OfferList';
// import OfferType from '../../Campaigns/CreateCampaignModel/OfferProductCard/OfferType';

const Step2 = function Step2({
  selectedRadioButton,
  handleSaveProducts,
  handleSaveCollections,
  handleRadioSelect,
}) {
  return (
    <div>
      <Space className='fullWidth' direction='vertical'>
        <OfferList
          selectedRadioButton={selectedRadioButton}
          handleSaveProducts={handleSaveProducts}
          handleSaveCollections={handleSaveCollections}
          handleRadioSelect={handleRadioSelect}
        />
        <OfferProductNote />
      </Space>
      {/* <OfferType /> */}
    </div>
  );
};

export default Step2;
