import { Input, Form, Space } from 'antd';

function CreateCamapignForm() {
  return (
    <Space direction='vertical' size={16} style={{ width: '100%' }}>
      <Form.Item
        label='Campaign Name'
        name='name'
        rules={[
          {
            required: true,
            message: 'Campaign Name is required',
            whitespace: true,
          },
          {
            message: 'You cannot use more than 60 characters',
            max: 60,
          },
        ]}
      >
        <Input type='text' />
      </Form.Item>
      <Form.Item label='Description (optional)' name='description'>
        <Input.TextArea
          type='text'
          style={{ maxHeight: 200, minHeight: 100 }}
        />
      </Form.Item>
    </Space>
  );
}

export default CreateCamapignForm;
