/* eslint-disable no-alert */
import { Col, Radio, Row, Modal, Input, Button, Spin } from 'antd';
import { memo, useCallback, useState } from 'react';
import Products from './Products';
import Collections from './Collections';

import classes from './index.module.less';

import {
  collectionsRadio,
  productsRadio,
} from '../../utils/constants/selectProductRadios';
import {
  encodeSelectedKeys,
  removeArrayDuplicate,
  removeArrayOfObjectsDuplicate,
} from '../../utils/formatProductData/SelectedKeys';

const { Group } = Radio;

const SelectProduct = function SelectProduct({
  title,
  handleModalClose,
  handleRadioSelect,
  showRadioSelection,
  selectedRadioButton,
  storedSelectedRows,
  handleSaveProducts,
  handleSaveCollections,
}) {
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [offerType, setOfferType] = useState(selectedRadioButton); // Local radio button state
  const [rowRecord, setRowRecord] = useState({
    hasNextPage: false,
    dataSource: [],
    expandedKeys: [],
    after: null,
    allFetchedData: [],
  });
  const [tempKeys, setTempKeys] = useState(storedSelectedRows);

  // handle local radio buttons
  const handleOfferType = useCallback((newState) => {
    setOfferType(newState);
  }, []);

  // Handle datasource
  const handleRowRecord = (record) => {
    setRowRecord((prev) => {
      // To store unique data
      const allFetchedData = removeArrayOfObjectsDuplicate([
        ...prev.allFetchedData,
        ...record.dataSource,
      ]);

      return {
        hasNextPage: record.hasNextPage,
        allFetchedData: [...allFetchedData],
        dataSource: [...prev.dataSource, ...record.dataSource],
        expandedKeys: [...prev.expandedKeys, ...record.expandedKeys],
        after: record.after,
      };
    });
  };

  // Handle search input value
  const handleSearchInput = useCallback(
    (event) => {
      setSearchInput(event.target.value);
      if (searchQuery !== '' && event.target.value === '') {
        setRowRecord((prev) => ({
          hasNextPage: false,
          dataSource: [],
          expandedKeys: [],
          after: null,
          allFetchedData: [...prev.allFetchedData],
        }));
        setSearchQuery(null);
      }
    },
    [searchQuery],
  );

  // handle search button click
  const handleSearchSubmit = useCallback(() => {
    setRowRecord((prev) => ({
      hasNextPage: false,
      dataSource: [],
      expandedKeys: [],
      after: null,
      allFetchedData: [...prev.allFetchedData],
    }));
    setSearchQuery(searchInput || null);
  }, [searchInput]);

  // handle product/collection radio button
  const handleRadioButtons = useCallback(
    (newValue) => {
      handleOfferType(newValue);
      setTempKeys([]);
      setSelectedRowKeys([]);
      setRowRecord({
        hasNextPage: false,
        dataSource: [],
        expandedKeys: [],
        after: null,
        allFetchedData: [],
      });
    },
    [handleOfferType],
  );

  // Update record's selected keys
  const handleSaveData = useCallback(
    (keys, isRemove = false) => {
      // Products keys
      if (offerType === productsRadio) {
        const data = encodeSelectedKeys(rowRecord.allFetchedData, keys, true);

        // remove key based on id in keys array
        if (isRemove) {
          setTempKeys((prev) => {
            const products = [];
            [...prev].forEach((product) => {
              if (product.id === data[0].id) {
                product.variant_ids = product.variant_ids.filter(
                  (id) => ![...data[0].variant_ids].includes(id),
                );
              }
              if (product.variant_ids.length !== 0) {
                products.push(product);
              }
            });
            return products;
          });
        }
        // add keys from keys array to the tempKeys (local state)
        else {
          setTempKeys((prev) =>
            removeArrayOfObjectsDuplicate([...prev, ...data]),
          );
        }
      }
      // Collection keys
      else if (offerType === collectionsRadio) {
        const data = encodeSelectedKeys(
          rowRecord.allFetchedData,
          keys,
          false,
        ).map((elem) => elem.id);
        // remove keys
        if (isRemove) {
          setTempKeys((prev) =>
            [...prev].filter((collection) => !data.includes(collection)),
          );
        }
        // add keys from keys array to the tempKeys (local state)
        else {
          setTempKeys((prev) => removeArrayDuplicate([...prev, ...data]));
        }
      }
    },
    [rowRecord.allFetchedData, offerType],
  );

  const handleRowSelect = useCallback(
    (rows) => {
      setSelectedRowKeys((prev) => {
        handleSaveData([...prev, ...rows]);
        return [...prev, ...rows];
      });
    },
    [handleSaveData],
  );

  const handleRowDeselect = useCallback(
    (key) => {
      handleSaveData(key, true);
      setSelectedRowKeys((prev) => prev.filter((id) => !key.includes(id)));
    },
    [handleSaveData],
  );

  // save decoded keys from the api response
  const handleDecodedKeys = useCallback((rows) => {
    setSelectedRowKeys((prev) => [...prev, ...rows]);
  }, []);

  // Handle product / collection submit
  const handleModalSubmit = () => {
    if (offerType === productsRadio) {
      handleSaveProducts(tempKeys);
    } else if (offerType === collectionsRadio) {
      handleSaveCollections(tempKeys);
    }
    showRadioSelection && handleRadioSelect(offerType);
    handleModalClose();
  };

  const handleModalCancel = () => {
    handleModalClose();
  };

  const footer = [
    <Button
      key='cancel-button'
      type='ghost'
      className={`primaryButton ${classes.footer}`}
      disabled={loading}
      onClick={handleModalCancel}
    >
      Cancel
    </Button>,
    <Button
      disabled={loading}
      key='primary-button'
      className={`primaryButton ${classes.footer}`}
      type='primary'
      onClick={handleModalSubmit}
    >
      Add
    </Button>,
  ];

  return (
    <Modal
      visible
      title={title}
      okText='Add'
      className={classes.modal}
      onOk={handleModalSubmit}
      onCancel={handleModalCancel}
      maskClosable={false}
      maskTransitionName={null}
      width={650}
      destroyOnClose
      centered
      footer={footer}
    >
      <Spin spinning={loading}>
        <Row>
          {showRadioSelection && (
            <Col span={24} className={classes.radioButton}>
              {/* Radio Buttons Group */}
              <Group
                buttonStyle='outline'
                defaultValue={offerType}
                onChange={(e) => handleRadioButtons(e.target.value)}
                style={{ width: '100%' }}
                className={classes.radioGroup}
              >
                <Radio
                  value={productsRadio}
                  checked={offerType === productsRadio}
                >
                  Products
                </Radio>

                <Radio
                  value={collectionsRadio}
                  checked={offerType === collectionsRadio}
                >
                  Collections
                </Radio>
              </Group>
            </Col>
          )}
          <Col span={24} className={classes.searchField}>
            <Input.Search
              value={searchInput}
              className={classes.searchInput}
              onChange={handleSearchInput}
              onSearch={handleSearchSubmit}
              allowClear
              type='text'
              placeholder='Search Items'
            />
          </Col>
          <Col span={24} className={classes.products} id='scrollableDiv'>
            {/* All conditions is selected then display Audience Conditions */}
            {offerType === productsRadio && (
              <Products
                searchQuery={searchQuery}
                products={rowRecord}
                setProducts={handleRowRecord}
                selectedRowKeys={selectedRowKeys}
                storedSelectedRows={tempKeys}
                handleRowSelect={handleRowSelect}
                handleRowDeselect={handleRowDeselect}
                handleDecodedKeys={handleDecodedKeys}
                setLoading={setLoading}
              />
            )}

            {/* From templates is selected then display Audience Templates */}
            {offerType === collectionsRadio && (
              <Collections
                searchQuery={searchQuery}
                collections={rowRecord}
                setCollections={handleRowRecord}
                selectedRowKeys={selectedRowKeys}
                storedSelectedRows={tempKeys}
                handleRowSelect={handleRowSelect}
                handleRowDeselect={handleRowDeselect}
                handleDecodedKeys={handleDecodedKeys}
                setLoading={setLoading}
              />
            )}
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default memo(SelectProduct);
