import { Form, InputNumber, Typography } from 'antd';
import { useContext } from 'react';
import ShopContext from '../../../../context/ShopContext';
import { offerDiscountOptions } from '../../../../utils/constants/discountTypes';

const { Text } = Typography;

const FixedDiscountInput = function FixedDiscountInput() {
  const { shop } = useContext(ShopContext);
  return (
    <Form.Item dependencies={['discount_type', 'fixed_discount_value']} noStyle>
      {(form) =>
        form.getFieldValue('discount_type') ===
          offerDiscountOptions.fixed.value && (
          <Form.Item
            name='fixed_discount_value'
            rules={[
              { required: true, message: 'Enter fixed discount' },
              {
                transform: (value) => {
                  // transform value from String to Number before validations
                  // If empty string then return empty string only

                  if (value === null) {
                    return value;
                  }
                  return Number(value);
                },
                type: 'number',
                validator: (rule, value) => {
                  // If empty string then required=true validator will be called thus
                  // resolve this validation

                  if (value === null) {
                    return Promise.resolve();
                  }
                  // eslint-disable-next-line no-else-return
                  else if (value <= 0) {
                    // value is negative thus throw error
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'Value must be greater than 0.',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              type='number'
              autoFocus
              addonAfter={<Text>{shop.currency}</Text>}
              placeholder='Enter fixed discount'
            />
          </Form.Item>
        )
      }
    </Form.Item>
  );
};

export default FixedDiscountInput;
