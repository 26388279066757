import { Button, Col, Row, Typography, Space } from 'antd';
import { default as EditFilled } from '@ant-design/icons/lib/icons/EditFilled';
import moment from 'moment';
import classes from './index.module.less';

const AudienceDetail = function AudienceDetail({
  segmentData,
  onEditSegmentClicked,
  showEditIcon,
}) {
  // rules markup
  const ruleHtml = (rule) => {
    let val = rule.value;
    if (rule.type === 'select') {
      val = val.label;
    } else if (rule.type === 'multiselect') {
      const tempVal = [];
      for (const i of val) {
        tempVal.push(i.label);
      }
      val = tempVal.join(', ');
    } else if (
      (rule.type === 'numberbox' && rule.operator === 'between') ||
      rule.operator === 'notBetween'
    ) {
      val = [rule.value.start, rule.value.end].join(', ');
    } else if (rule.type === 'tagbox') {
      val = rule.value.join(', ');
    } else if (rule.type === 'datepicker') {
      if (
        rule.operator === 'dateBetween' ||
        rule.operator === 'dateNotBetween'
      ) {
        val = [
          moment(rule.value.start).format('MMM DD, YY'),
          moment(rule.value.end).format('MMM DD, YY'),
        ].join(', ');
      } else {
        val = moment(rule.value).format('MMM DD, YY');
      }
    } else if (rule.type === 'shopifyproductgql') {
      const tempVal = [];
      for (const i of val) {
        for (const j of i.variant_ids) {
          tempVal.push(j);
        }
      }
      val = tempVal.join(', ');
    }
    return (
      <Space size={4} align='start' className={classes.ruleText}>
        <Typography.Text strong>{rule.attr_label} :</Typography.Text>
        <Typography.Text type='secondary'>
          {rule.operator_label} :
        </Typography.Text>
        <Typography.Text>{val}</Typography.Text>
      </Space>
    );
  };

  return (
    <Row justify='space-between' className={classes.audienceDetail}>
      <Col xs={20} md={20}>
        <Typography.Text strong underline>
          Audience Details
        </Typography.Text>
      </Col>
      {showEditIcon && segmentData.created_by !== 'default' && (
        <Col>
          <Button
            shape='circle'
            icon={<EditFilled />}
            onClick={() => onEditSegmentClicked(segmentData)}
          />
        </Col>
      )}
      {segmentData.rules.map((rule) => (
        <Col
          span={24}
          key={`${rule.entity_label}-${rule.category_label}-${rule.attr_label}`}
        >
          {ruleHtml(rule)}
        </Col>
      ))}

      {/* <Col span={24}>
        <Typography.Text type='secondary'>Age - </Typography.Text>
        <Typography.Text>{age}</Typography.Text>
      </Col>
      <Col span={24}>
        <Typography.Text type='secondary'>People who match : </Typography.Text>
        <Typography.Text>{others}</Typography.Text>
      </Col> */}
    </Row>
  );
};

export default AudienceDetail;
