import { memo, useEffect, useRef, useState } from 'react';
import { Form, Typography } from 'antd';
import moment from 'moment';
import classes from './index.module.less';

const PreviewTimerHeading = ({ isShowTimeout, timeout, subHeadingText }) => {
  // eslint-disable-next-line no-console
  // console.log('timer render');

  const [timerValue, setTimerValue] = useState(timeout);
  const intervalRef = useRef(timeout);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (timerValue === '00m 00s' || intervalRef.current !== timeout) {
        setTimerValue(timeout);
        intervalRef.current = timeout;
      } else {
        const time = moment(timerValue, 'mm[m] ss[s]');
        const currentTime = moment(time)
          .subtract(1, 'seconds')
          .format('mm[m] ss[s]');

        setTimerValue(currentTime);
      }
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerValue]);

  const timer =
    isShowTimeout &&
    subHeadingText.replace(
      // eslint-disable-next-line prefer-regex-literals
      new RegExp('{{timer}}', 'g'),
      `<span style="word-break: break-word">${timerValue}</span>`,
    );
  return (
    <div>
      {isShowTimeout && (
        <Typography.Text className={classes.timer}>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: timer }} />
        </Typography.Text>
      )}
    </div>
  );
};

export default memo(PreviewTimerHeading);
