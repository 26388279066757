import { Button, Modal, Result, Typography } from 'antd';

const { Text, Title } = Typography;

const ValidationSuccessMessage = ({
  onValidationModal,
  validationStatus,
  isModalForEnable,
}) => (
  <Modal
    visible
    centered
    closable={false}
    footer={null}
    title={null}
    maskTransitionName={null}
    // onCancel={onCancel}
    maskClosable={false}
    maskStyle={{ backgroundColor: '#36363678' }}
  >
    <Result
      status={validationStatus !== isModalForEnable ? 'error' : 'success'}
      title={
        <Title level={3}>
          {validationStatus !== isModalForEnable
            ? 'Integration failed'
            : 'Success!'}
        </Title>
      }
      subTitle={
        <Text>
          {validationStatus !== isModalForEnable
            ? `App is ${
                isModalForEnable ? 'not' : ''
              } enabled. Try the steps again or contact our support.`
            : `Success! Offers365 app is ${
                !isModalForEnable ? 'disabled' : 'enabled'
              }.`}
        </Text>
      }
      extra={[
        <Button
          type='primary'
          key='primary'
          className='primaryButton'
          onClick={onValidationModal}
          style={{ minWidth: 100 }}
        >
          {validationStatus !== isModalForEnable ? 'Ok' : 'Go to dashboard'}
        </Button>,
      ]}
    />
  </Modal>
);
export default ValidationSuccessMessage;
