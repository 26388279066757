import { useContext, useEffect, useState, Suspense } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Table,
  Typography,
  Row,
  Col,
  Space,
  Button,
  message,
  Empty,
} from 'antd';
import axios from 'axios';
import { default as ArrowLeftOutlined } from '@ant-design/icons/lib/icons/ArrowLeftOutlined';
import moment from 'moment';
import { FilterSelect } from '../SideBar';
import classes from './index.module.less';
import CampaignsContext from '../../../context/CampaignsContext';
import Scrollable from '../../../components/Scrollable';
import ShopContext from '../../../context/ShopContext';
import FallbackLoader from '../../../components/FallbackLoader';
import useMounted from '../../../hooks/useMounted';

const leftArrow = <ArrowLeftOutlined className={classes.leftArrowIcon} />;

// rowSelection objects indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // eslint-disable-next-line no-console
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   'selectedRows: ',
    //   selectedRows,
    // );
  },
  onSelect: (record, selected, selectedRows) => {
    // eslint-disable-next-line no-console
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // eslint-disable-next-line no-console
    // console.log(selected, selectedRows, changeRows);
  },
};

const CampaignOrders = function CampaignOrders() {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { selectedFilter, onSelectedFilterChange } =
    useContext(CampaignsContext);

  const { shop } = useContext(ShopContext);
  const { state } = useLocation();
  const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [ordersData, setOrdersData] = useState([]);
  const [sortData, setSortData] = useState({
    field: 'orderCreatedAt',
    order: 'desc',
  });

  // if state data is empty redirect to campaigns dashboard
  useEffect(() => {
    if (!state) {
      navigate('/campaigns');
    }
  }, [navigate, state]);

  // Fetch order analytics
  useEffect(() => {
    const fn = async () => {
      let res;
      try {
        if (!state) {
          return;
        }
        if (state && state.id === 'all') {
          setIsAnalyticsLoading(true);
          res = await axios.get(`/analytics/summary`);
        } else {
          setIsAnalyticsLoading(true);
          res = await axios.get(`/analytics/campaign/${state && state.id}`);
        }
      } catch (err) {
        console.error(err);
      }
      if (!mounted.current) {
        return;
      }
      if (res) {
        setAnalyticsData(res.data);
      } else {
        message.error('Failed to fetch analytics');
      }
      setIsAnalyticsLoading(false);
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrders = async (id, sort, next = '', loadMoreClicked = false) => {
    setIsTableLoading(true);
    const { field, order } = sort;
    // analytics api endpoint
    const url =
      id === 'all'
        ? `/analytics/order?sortBy=${field}&sortOrder=${order}&next=${next}`
        : `/analytics/order/${id}?sortBy=${field}&sortOrder=${order}&next=${next}`;
    let res;
    try {
      res = await axios.get(url);
    } catch (err) {
      console.error(err);
    }
    if (!mounted.current) return;
    if (res) {
      const { results, ...rest } = res.data;
      if (loadMoreClicked) {
        setOrdersData((prev) => [...prev, ...results]);
      } else {
        setOrdersData(results);
      }
      setMetaData(rest);
    } else {
      message.error('Failed to fetch orders');
    }
    setIsTableLoading(false);
  };

  useEffect(() => {
    if (!state) {
      return;
    }
    const id = state && state.id;
    fetchOrders(id, sortData, '', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortData]);

  // handle load more button click
  const loadMoreClicked = () => {
    const id = state && state.id;
    if (metaData.hasNext) {
      fetchOrders(id, sortData, metaData.next, true);
    } else {
      message.warning('No more orders.');
    }
  };

  // handle table sort
  const handleSortDirection = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setOrdersData([]);
    setMetaData(null);
    const sortVal = {
      field: sorter.field,
      order: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setSortData(sortVal);
  };

  // handle view/edit button
  const handleViewButton = () => {
    /**
     * ?  Add { editMode:true } when redirecting for campaign editing.
     */
    navigate('/campaigns/details', {
      state: { ...state, editMode: true },
    });
  };

  // update currency format
  const currencyFormat = (num) =>
    `${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

  // Table Column
  const columns = [
    {
      title: 'Order',
      key: 'orderId',
      dataIndex: 'orderId',
      render: (orderId, record) => (
        <Typography.Link
          strong
          className={classes.campaignName}
          href={`https://${record && record.shopName}/admin/orders/${
            record && record.orderId
          }`}
          target='_blank'
        >
          {record && record.orderDetails && record.orderDetails.name}
        </Typography.Link>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Date',
      key: 'orderCreatedAt',
      dataIndex: 'orderCreatedAt',
      render: (text) => (
        <Typography.Text>
          {`${moment(text).format('MMM DD')} at ${moment(text).format(
            'h:mm A',
          )}`}
        </Typography.Text>
      ),
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
    },
    {
      title: 'Customer',
      key: 'customerId',
      dataIndex: 'customerId',
      render: (customerId, record) => (
        <Typography.Link
          className={classes.campaignName}
          target='_blank'
          href={`https://${record && record.shopName}/admin/customers/${
            record && record.customerId
          }`}
        >
          {`${
            record && record.orderDetails && record.orderDetails.billing_address
              ? record.orderDetails.billing_address.first_name
              : '-'
          } ${
            record && record.orderDetails && record.orderDetails.billing_address
              ? record.orderDetails.billing_address.last_name
              : ''
          }`}
        </Typography.Link>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Order Total',
      key: 'orderTotal',
      dataIndex: 'orderTotal',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text) => `${shop.currency} ${currencyFormat(text)}`,
    },
    {
      title: 'Offers365 Total',
      key: 'ppTotalValue',
      dataIndex: 'ppTotalValue',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text) => `${shop.currency} ${currencyFormat(text)}`,
    },
    {
      title: 'AOI%',
      key: 'avgIncreaseInOrderPercentage',
      dataIndex: 'avgIncreaseInOrderPercentage',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text) => (text ? `${text}%` : '-'),
    },
  ];

  const content = (
    <>
      <Row justify='start' gutter={[24]} className={classes.header}>
        <Col>
          <Link to='/campaigns'>{leftArrow}</Link>
        </Col>
        <Col>
          <Typography.Title level={3}>{state && state.name}</Typography.Title>
          {state && state.id !== 'all' && (
            <Typography.Link onClick={handleViewButton}>
              View & edit the campaign
            </Typography.Link>
          )}
        </Col>
      </Row>
      <div>
        <Row className='pcHidden'>
          <Col flex='auto'>
            <Typography.Title level={3}>Orders</Typography.Title>
          </Col>
          <Col>
            <FilterSelect
              selectedFilter={selectedFilter}
              onSelectedFilterChange={onSelectedFilterChange}
            />
          </Col>
        </Row>
        <Scrollable>
          <Space size={0} direction='vertical' className={classes.contentSpace}>
            {/* Content */}
            {!isAnalyticsLoading && (
              <Row className={classes.summarySection} align='middle'>
                <Col span={6} className={classes.firstColumn}>
                  <Space direction='vertical' size={0}>
                    <Typography.Text className={classes.totalRevenue}>
                      {`${shop.currency} ${
                        analyticsData && analyticsData.totalRevenue
                          ? currencyFormat(analyticsData.totalRevenue)
                          : currencyFormat(0)
                      }`}
                    </Typography.Text>
                    <Typography.Text className={classes.font13}>
                      Revenue Generated
                    </Typography.Text>
                  </Space>
                </Col>
                <Col span={7} className={classes.summaryDetails}>
                  <Row wrap={false} gutter={[8, 0]}>
                    <Col
                      span={18}
                      className={`${classes.rightAligned} ${classes.font13}`}
                    >
                      <Typography.Text>Impressions :</Typography.Text>
                    </Col>
                    <Col span={6}>
                      <Typography.Text>
                        {(analyticsData && analyticsData.noOfImpressions) || 0}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row wrap={false} gutter={[8, 0]}>
                    <Col
                      span={18}
                      className={`${classes.rightAligned} ${classes.font13}`}
                    >
                      <Typography.Text>Orders :</Typography.Text>
                    </Col>
                    <Col span={6}>
                      <Typography.Text>
                        {(analyticsData && analyticsData.noOfPurchases) || 0}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>

                <Col span={11} className={classes.summaryDetails}>
                  <Row wrap={false} gutter={[8, 0]}>
                    <Col
                      span={14}
                      className={`${classes.rightAligned} ${classes.font13}`}
                    >
                      <Typography.Text>Order value increase :</Typography.Text>
                    </Col>
                    <Col span={10}>
                      <Typography.Text>
                        {`${shop && shop.currency} ${
                          (analyticsData &&
                            analyticsData.avgIncreaseInOrderValue &&
                            currencyFormat(
                              analyticsData.avgIncreaseInOrderValue,
                            )) ||
                          currencyFormat(0)
                        } `}
                        {(analyticsData &&
                          analyticsData.avgIncreaseInOrderPercentage &&
                          `(${analyticsData.avgIncreaseInOrderPercentage}%)`) ||
                          '(0%)'}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row wrap={false} gutter={[8, 0]}>
                    <Col
                      span={14}
                      className={`${classes.rightAligned} ${classes.font13}`}
                    >
                      <Typography.Text>Conversion rate :</Typography.Text>
                    </Col>
                    <Col span={10}>
                      <Typography.Text>
                        {(analyticsData && analyticsData.conversionRate) || 0}%
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Table
              className={classes.campaignsTable}
              size='middle'
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='No orders found'
                  />
                ),
              }}
              columns={columns}
              dataSource={ordersData}
              loading={{ spinning: isTableLoading, tip: 'Loading Orders...' }}
              pagination={false}
              rowKey={(record) => record.orderId}
              tableLayout='fixed'
              rowSelection={{
                ...rowSelection,
                checkStrictly: false,
              }}
              onChange={handleSortDirection}
            />
          </Space>
        </Scrollable>
        {metaData && metaData.hasNext && (
          <div className={classes.loadMoreBtn}>
            <Button
              type='link'
              onClick={loadMoreClicked}
              loading={isTableLoading}
              disabled={isTableLoading}
            >
              Load More
            </Button>
          </div>
        )}
      </div>
    </>
  );

  return (
    <Suspense fallback={<FallbackLoader delay={0}>{content}</FallbackLoader>}>
      {content}
    </Suspense>
  );
};

export default CampaignOrders;
