import { Radio, Form } from 'antd';

import classes from './BlockRadioButtons.module.less';

const BlockRadioButtons = function BlockRadioButtons({
  options,
  formItemProps,
  radioGroupProps,
}) {
  return (
    <Form.Item {...formItemProps}>
      <Radio.Group
        className={classes.radioGroup}
        options={options}
        optionType='button'
        {...radioGroupProps}
      />
    </Form.Item>
  );
};

export default BlockRadioButtons;
