import { Spin } from 'antd';
import { default as LoadingOutlined } from '@ant-design/icons/lib/icons/LoadingOutlined';

import classes from './index.module.less';

const FallbackLoader = function FallbackLoader({ children, delay = 200 }) {
  const spinnerIndicator = (
    <LoadingOutlined className={classes.spinnerIcon} spin />
  );

  return (
    <Spin delay={delay} indicator={spinnerIndicator}>
      {children}
    </Spin>
  );
};

export default FallbackLoader;
