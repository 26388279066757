import {
  Card,
  Divider,
  Space,
  // , Typography, Collapse
} from 'antd';

// import BlockRadioButtons from '../shared/BlockRadioButtons';
import CardTitle from '../shared/CardTitle';
import CollapsePanelContent from './CollapsePanelContent';
// import RadioList from '../../../../components/RadioList';
// import RadioListItem from '../../../../components/RadioList/RadioListItem';
// import allCustomer from '../../../../assets/images/all_customers.svg';
// import audience from '../../../../assets/images/audience.png';

import classes from './OffersCreativeDesignCard.module.less';

// const { Text, Link } = Typography;
// const { Panel } = Collapse;

const OffersCreativeDesignCard = function OffersCreativeDesignCard() {
  // const panelHeader = (
  //   <Space direction='vertical' size={6} style={{ lineHeight: 1 }}>
  //     <Text>
  //       <Link>Edit text on the offers page</Link>
  //     </Text>
  //     <Text type='secondary'>
  //       Add up to 2 buttons. This will be used to create different ad versions.
  //     </Text>
  //   </Space>
  // );

  return (
    <Card className={classes.panel}>
      <Space className='fullWidth' direction='vertical' size={16}>
        <CardTitle
          title='Step 4: Schedule '
          description='Configure the campaign start and end timings '
          // helpText='Help'
        />
        <Divider style={{ marginBlock: 0 }} />
        {/* <BlockRadioButtons
          formItemProps={{
            name: 'offer_no_of_products_to_show',
            className: classes.firstFormItem,
          }}
          options={[
            {
              label: 'Multiple Offers',
              value: 2,
              disabled: true,
            },
            {
              label: 'Single Offer',
              value: 1,
            },
          ]}
          optionType='button'
        /> */}
        {/* <Form.Item dependencies={['offerCreative']} noStyle> */}
        {/*   {(form) => */}
        {/*     form.getFieldValue('offerCreative') && ( */}
        {/*       <Space style={{ fontSize: 12, marginTop: 6 }}> */}
        {/*         <BulbFilled className={classes.bulbIcon} /> */}
        {/*         <Text>Good choice best suited to sell something</Text> */}
        {/*       </Space> */}
        {/*     ) */}
        {/*   } */}
        {/* </Form.Item> */}
        {/* <RadioList name='offer_no_of_products_to_show'>
          <Badge.Ribbon text='Coming soon' placement='end'>
            <RadioListItem
              disabled
              item={{
                id: 2,
                title: 'Multiple Offers',
                description:
                  'Let facebook select the most relevant goal based on your settings.',
                avatar: allCustomer,
              }}
              value={2}
            />
          </Badge.Ribbon>
          <RadioListItem
            item={{
              id: 1,
              title: 'Single Offer',
              description:
                'Use a form to collect contact information from potential customers.',
              avatar: audience,
            }}
            value={1}
          />
        </RadioList> */}
        <CollapsePanelContent />
        {/* <Collapse className={classes.collapse} ghost defaultActiveKey={null}>
          <Panel
            showArrow
            key='edit'
            header={panelHeader}
            className={classes.panel}
          >
            
            {/* <Form.Item
              label='Heading 1'
              name='heading_text'
              rules={[
                {
                  required: true,
                  message: 'Enter heading text',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Heading 2'
              name='sub_heading_text'
              rules={[
                {
                  required: true,
                  message: 'Enter sub heading text',
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item>
              <Row gutter={[10]} wrap={false}>
                <Col>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label='Main Button'
                    name='accept_offer_button_text'
                    rules={[
                      {
                        required: true,
                        message: 'Enter accept offer button text',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    label='Secondary Button'
                    name='decline_offer_button_text'
                    rules={[
                      {
                        required: true,
                        message: 'Enter decline offer button text',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item> */}
        {/* </Panel> */}
        {/* </Collapse> */}
      </Space>
    </Card>
  );
};

export default OffersCreativeDesignCard;
