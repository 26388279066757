import {
  // Select,
  Space,
  Typography,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';

import classes from './index.module.less';

const FooterSection = function FooterSection() {
  // const languageOptions = [
  //   { label: 'English', value: 'en' },
  //   { label: 'Hindi', value: 'hi' },
  // ];
  // const contactOptions = [{ label: 'Contact', value: 'contact' }];

  return (
    <Footer className={classes.footer}>
      <Space align='start' direction='vertical'>
        <Typography.Text>
          &#169; Offers365 by{' '}
          <Typography.Link href='https://www.mlveda.com' target='_blank'>
            MLVeda
          </Typography.Link>
        </Typography.Text>
        {/* <Space>
          <Select
            defaultValue='en'
            options={languageOptions}
            className={classes.language}
          />
          <Select defaultValue='contact' options={contactOptions} />
        </Space> */}
      </Space>
    </Footer>
  );
};

export default FooterSection;
