// import classes from './CollapsePanelContent.module.less';
import { AdvancedSettingsCardUI } from '../AdvancedSettingsCard';

const CollapsePanelContent = function CollapsePanelContent() {
  // const { settings } = useContext(SettingsContext);

  // const handleClick = ({ validateFields, add }) => {
  //   // add();
  //   validateFields([
  //     ['heading_text', 0],
  //     ['heading_text', 1],
  //     ['heading_text', 2],
  //     ['heading_text', 3],
  //     ['heading_text', 4],
  //     ['heading_text', 5],
  //   ])
  //     .then(() => add())
  //     // eslint-disable-next-line no-console
  //     .catch((err) => console.error(err));
  // };

  return (
    // <Space direction='vertical' size={16}>
    <>
      {/* <Form.Item dependencies={['heading_text']}> */}
      {/*   {({ getFieldValue, validateFields }) => ( */}
      {/*     <Form.List name='heading_text'> */}
      {/*       {(fields, { add, remove }) => ( */}
      {/*         <> */}
      {/*           {fields.map(({ key, name, ...restField }, index) => ( */}
      {/*             <Form.Item */}
      {/*               {...restField} */}
      {/*               className={classes.headingInput} */}
      {/*               label={ */}
      {/*                 <div className={classes.headingLabel}> */}
      {/*                   <span>{`Heading ${index + 1}`}</span> */}
      {/*                   {settings.isOnboardingCompleted && ( */}
      {/*                     <Button */}
      {/*                       type='link' */}
      {/*                       onClick={() => remove(name)} */}
      {/*                       disabled={fields.length === 1} */}
      {/*                     > */}
      {/*                       Remove */}
      {/*                     </Button> */}
      {/*                   )} */}
      {/*                 </div> */}
      {/*               } */}
      {/*               name={name} */}
      {/*               style={{ */}
      {/*                 marginTop: index !== 0 ? '16px' : 0, */}
      {/*                 display: 'flex', */}
      {/*               }} */}
      {/*               rules={[ */}
      {/*                 { */}
      {/*                   required: true, */}
      {/*                   message: 'Please fill the required field', */}
      {/*                 }, */}
      {/*               ]} */}
      {/*             > */}
      {/*               <Input placeholder='Headings' autoComplete='off' /> */}
      {/*             </Form.Item> */}
      {/*           ))} */}
      {/*           {settings.isOnboardingCompleted && ( */}
      {/*             <Form.Item> */}
      {/*               <Button */}
      {/*                 type='dashed' */}
      {/*                 onClick={() => handleClick({ validateFields, add })} */}
      {/*                 disabled={getFieldValue('heading_text').length >= 5} */}
      {/*                 block */}
      {/*                 icon={<PlusOutlined />} */}
      {/*                 style={{ marginTop: '16px' }} */}
      {/*               > */}
      {/*                 Add one more section */}
      {/*               </Button> */}
      {/*             </Form.Item> */}
      {/*           )} */}
      {/*         </> */}
      {/*       )} */}
      {/*     </Form.List> */}
      {/*   )} */}
      {/* </Form.Item> */}

      {/* <Form.Item
        dependencies={[
          'heading_text',
          'heading_text2',
          'heading_text3',
          'heading_text4',
          'heading_text5',
        ]}
      >
        {({ validateFields }) => (
          <Button
            type='link'
            onClick={() => addHeadings(validateFields)}
            style={{ marginTop: '5px', padding: 0 }}
            // disabled={headings === 5}
          >
            + Add one more section
          </Button>
        )}
      </Form.Item> */}
      {/* <Form.Item
        label='Heading 1'
        name='heading_text'
        style={{ marginBottom: '20px' }}
        rules={[
          { required: true, message: 'Enter heading text', whitespace: true },
        ]}
      >
        <Input />
      </Form.Item> */}

      {/* <Form.Item dependencies={['enableCampaignTimeout']}>
        {({ getFieldValue }) => (
          <Form.Item
            label='Heading 2'
            name='sub_heading_text'
            extra={
              <Text
                type='secondary'
                style={{ fontSize: '0.875rem', lineHeight: 1.5715 }}
              >
                You can use{' '}
                <Text type='secondary' strong>
                  {'{{campaignTimeout}}'}
                </Text>{' '}
                to display the campaign timeout.
              </Text>
            }
            rules={[
              {
                required: true,
                message: 'Enter sub heading text',
                whitespace: true,
              },
              {
                message: 'Please add the {{campaignTimeout}} variable.',
                validator: (rule, value) => {
                  if (
                    value &&
                    getFieldValue('enableCampaignTimeout') &&
                    value.indexOf('{{campaignTimeout}}') === -1
                  ) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        )}
      </Form.Item> */}
      <AdvancedSettingsCardUI />
      {/* <Divider className={classes.divider} /> */}
      {/* <CampaignTimeout /> */}
      {/* <Form.Item>
        <Row gutter={[12, 12]} wrap={false}>
          <Col span={12}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label='Main Button'
              name='accept_offer_button_text'
              rules={[
                {
                  required: true,
                  message: 'Enter accept offer button text',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              style={{ marginBottom: 0 }}
              label='Secondary Button'
              name='decline_offer_button_text'
              rules={[
                {
                  required: true,
                  message: 'Enter decline offer button text',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item> */}
    </>
    // {/* </Space> */}
  );
};

export default CollapsePanelContent;
