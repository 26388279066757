import { Button, Select, Menu } from 'antd';
// import { default as PlusCircleOutlined } from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { default as ClockCircleOutlined } from '@ant-design/icons/lib/icons/ClockCircleOutlined';
import { default as PauseCircleOutlined } from '@ant-design/icons/lib/icons/PauseCircleOutlined';
import { default as SyncOutlined } from '@ant-design/icons/lib/icons/SyncOutlined';
import { default as CheckCircleOutlined } from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import { default as EditOutlined } from '@ant-design/icons/lib/icons/EditOutlined';
import { default as AppstoreOutlined } from '@ant-design/icons/lib/icons/AppstoreOutlined';
import ContainerSider from '../../../components/ContainerLayout/ContainerSider';

import classes from './index.module.less';
import {
  COMPLETED,
  LIVE,
  PAUSED,
  SCHEDULED,
} from '../../../utils/constants/campaignStatus';

export const filterItems = [
  {
    key: 'all',
    title: 'Campaigns',
    getIcon: () => <AppstoreOutlined />,
  },
  {
    key: LIVE,
    title: 'Running',
    getIcon: () => <SyncOutlined />,
  },
  {
    key: SCHEDULED,
    title: 'Scheduled',
    getIcon: () => <ClockCircleOutlined />,
  },
  {
    key: PAUSED,
    title: 'Paused',
    getIcon: () => <PauseCircleOutlined />,
  },
  {
    key: COMPLETED,
    title: 'Completed',
    getIcon: () => <CheckCircleOutlined />,
  },
];

// this use for small device
export const FilterSelect = function FilterSelect({
  selectedFilter,
  onSelectedFilterChange,
}) {
  return (
    <Select value={selectedFilter} onChange={onSelectedFilterChange}>
      {filterItems.map((item) => (
        <Select.Option value={item.key} key={item.key}>
          {item.title}
        </Select.Option>
      ))}
    </Select>
  );
};

const SideBar = function SideBar({
  selectedFilter,
  onSelectedFilterChange,
  onCampaignLaunchClick,
  onChangePriorityClick,
}) {
  // const plusIcon = <PlusCircleOutlined />;

  return (
    <ContainerSider
      items={filterItems}
      selectedFilter={selectedFilter}
      title='Campaigns'
      onSelectedFilterChange={onSelectedFilterChange}
    >
      {/* <Menu.Item
        key='launch campaign button'
        className={classes.buttonMenuItem}
      >
        <Button
          block
          type='primary'
          className='primaryButton'
          onClick={onCampaignLaunchClick}
          icon={plusIcon}
        >
          Create offer
        </Button>
      </Menu.Item> */}
      <Menu.Item
        key='change priority button'
        className={classes.buttonMenuItem}
      >
        <Button
          block
          type='default'
          onClick={onChangePriorityClick}
          className='secondaryButton'
        >
          <EditOutlined /> Edit Priority
        </Button>
      </Menu.Item>
    </ContainerSider>
  );
};

export default SideBar;
