/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import { Row, Col, Select } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import RuleValues from './RuleValues';
import classes from './index.module.less';

const TemplateConditions = function TemplateConditions({
  rulesData,
  setSelectedSegmentRules,
  selectedSegmentRules,
}) {
  const dateFormat = 'YYYY-MM-DD';

  const [rules, setRules] = useState(rulesData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const tempRules = [...rules];
    for (const t of tempRules) {
      for (const s of t.categories) {
        for (const f of s.attributes) {
          if (f.operators && f.operators.length > 0) {
            f.selectedOperator = f.operators[0].value;
          }
          if (f.type === 'multiselect') {
            f.inputValue = '';
            f.options = f.valueOptions;
          }
          if (f.type === 'tagbox') {
            f.inputVisible = false;
            f.inputValue = '';
          }
          f.selectedValue = '';
          f.selectedValue1 = '';
          if (
            f.type === 'multiselect' ||
            f.type === 'tagbox' ||
            f.type === 'shopifyproductgql'
          ) {
            f.selectedValue = [];
          }
          if (f.type === 'number_dropdown_box') {
            f.selectedExtraOption = '';
          }
        }
      }
    }
    setRules(tempRules);

    const tempAddedRules = [];
    for (const t of rules) {
      const mc = {
        label: t.label,
        entity: t.entity,
        categories: [],
      };
      const sc = [];
      for (const s of t.categories) {
        sc.push({
          category: s.category,
          label: s.label,
          attributes: [],
        });
      }
      mc.categories = sc;
      tempAddedRules.push(mc);
    }
    console.log('tempAddedRules', tempAddedRules);
    setSelectedSegmentRules(tempAddedRules);

    setIsLoading(false);
  }, []);

  const handleValueChange = (
    val,
    enityIndex,
    category,
    fieldName,
    fieldToUpdate,
  ) => {
    console.log('val', val);
    const items = [...rules];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        if (
          selectedField.type === 'datepicker' &&
          fieldToUpdate === 'selectedValue'
        ) {
          if (
            val &&
            (selectedField.selectedOperator === 'dateBetween' ||
              selectedField.selectedOperator === 'dateNotBetween')
          ) {
            selectedField[fieldToUpdate] = moment(val[0]).format(dateFormat);
            selectedField.selectedValue1 = moment(val[1]).format(dateFormat);
          } else if (val) {
            selectedField[fieldToUpdate] = moment(val).format(dateFormat);
          } else {
            selectedField[fieldToUpdate] = val;
          }
        } else {
          selectedField[fieldToUpdate] = val;
        }
        if (
          fieldToUpdate === 'selectedOperator' &&
          (selectedField.selectedOperator === 'dateBetween' ||
            selectedField.selectedOperator === 'dateNotBetween')
        ) {
          if (!selectedField.selectedValue1) {
            selectedField.selectedValue1 = moment(
              selectedField.selectedValue,
            ).format(dateFormat);
          }
        }
        console.log('items', items);
        setRules(items);
        if (fieldToUpdate === 'selectedValue') {
          updateAddedRules(category, enityIndex, fieldName, selectedField);
        }
      }
    }
  };

  const updateAddedRules = (category, enityIndex, fieldName, fieldData) => {
    const items = [...selectedSegmentRules];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        const index = selectedCategory.attributes.indexOf(selectedField);
        if (index > -1) {
          selectedCategory.attributes.splice(index, 1);
        }
      }
      if (
        (fieldData.type === 'tagbox' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0) ||
        (fieldData.type === 'select' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0) ||
        (fieldData.type === 'multiselect' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0) ||
        (fieldData.type === 'datepicker' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue !== undefined) ||
        (fieldData.type === 'numberbox' &&
          (fieldData.selectedValue || fieldData.selectedValue1)) ||
        (fieldData.type === 'shopifyproductgql' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0)
      ) {
        selectedCategory.attributes.push(fieldData);
      }
      console.log('AddedRulezz', items);
      setSelectedSegmentRules(items);
    }
  };

  const handleInputChange = (e, enityIndex, category, fieldName) => {
    const items = [...rules];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        if (fieldName === 'Phone Number') {
          if (e.target && e.target.value && e.target.value.match(/^[0-9+]+$/)) {
            selectedField.inputValue = e.target.value;
          }
        } else {
          selectedField.inputValue = e.target.value;
        }
        setRules(items);
        updateAddedRules(category, fieldName, selectedField);
      }
    }
  };

  const handleInputConfirm = (val, enityIndex, category, fieldName) => {
    const items = [...rules];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        const tags = selectedField.selectedValue;
        if (
          selectedField.inputValue &&
          tags.indexOf(selectedField.inputValue) === -1
        ) {
          selectedField.selectedValue = [...tags, selectedField.inputValue];
          selectedField.inputVisible = false;
          selectedField.inputValue = '';
          console.log('items', items);
          setRules(items);
          updateAddedRules(category, fieldName, selectedField);
          return;
        }
        selectedField.inputVisible = false;
        setRules(items);
      }
    }
  };

  const showInput = (val, enityIndex, category, fieldName, inputRef) => {
    const items = [...rules];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        selectedField.inputVisible = true;
        setTimeout(() => {
          inputRef.current?.focus();
        }, 50);
        console.log('items', items);
        setRules(items);
      }
    }
  };

  const handleTagClose = (val, entityIndex, category, fieldName) => {
    const items = [...rules];
    const selectedCategory = items[entityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        if (
          selectedField.selectedValue &&
          selectedField.selectedValue.length > 0
        ) {
          const index = selectedField.selectedValue.indexOf(val);
          if (index > -1) {
            selectedField.selectedValue.splice(index, 1);
          }
          setRules(items);
          updateAddedRules(category, fieldName, selectedField);
        }
      }
    }
  };

  return (
    <div>
      {!isLoading && (
        <div className={classes.segmentsCreationTabs}>
          {rules.map((rule, ruleIndex) =>
            rule.categories.map((cat) =>
              cat.attributes.map((field) => (
                <Row
                  className={classes.leafFieldsRow}
                  key={`${rule.entity}-${cat.category}-${field.attr}-field`}
                >
                  <Col span={8}>
                    {rule.label} · {cat.label} · {field.label}
                  </Col>
                  <Col span={8}>
                    {field.operators && field.operators.length > 0 && (
                      <Select
                        className={classes.width90}
                        value={field.selectedOperator}
                        onChange={(e) =>
                          handleValueChange(
                            e,
                            ruleIndex,
                            cat.category,
                            field.attr,
                            'selectedOperator',
                          )
                        }
                      >
                        {field.operators.map((operator) => (
                          <Select.Option
                            key={`${rule.entity}-${cat.category}-${field.attr}-operator`}
                            value={operator.value}
                          >
                            {operator.label}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Col>
                  <Col span={8}>
                    {field.selectedOperator !== 'is_empty' && (
                      <RuleValues
                        fieldData={field}
                        entityIndex={ruleIndex}
                        category={cat.category}
                        entity={rule.entity}
                        handleInputChange={handleInputChange}
                        handleInputConfirm={handleInputConfirm}
                        showInput={showInput}
                        handleTagClose={handleTagClose}
                        handleValueChange={handleValueChange}
                      />
                    )}
                  </Col>
                </Row>
              )),
            ),
          )}
          {/* {rules.map((rule) => (
            <Row
              className={classes.leafFieldsRow}
              key={`${rule.entity}-${rule.category}-${rule.attr}-field`}
            >
              <Col span={8}>
                {rule.entity_label} · {rule.category_label} · {rule.attr_label}
              </Col>
              <Col span={8}>
                {rule.operators && rule.operators.length > 0 && (
                  <Select
                    className={classes.width90}
                    value={rule.selectedOperator}
                    onChange={(e) =>
                      handleValueChange(e, rule.attr, 'selectedOperator')
                    }
                  >
                    {rule.operators.map((operator) => (
                      <Select.Option
                        key={`${rule.entity}-${rule.category}-${rule.attr}-operator`}
                        value={operator.value}
                      >
                        {operator.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Col>
              <Col span={8}>
                {rule.selectedOperator !== 'is_empty' && (
                  <RuleValues
                    fieldData={rule}
                    category={rule.category}
                    entity={rule.entity}
                    handleInputChange={handleInputChange}
                    handleInputConfirm={handleInputConfirm}
                    showInput={showInput}
                    handleTagClose={handleTagClose}
                    handleValueChange={handleValueChange}
                  />
                )}
              </Col>
            </Row>
          ))} */}
        </div>
      )}
    </div>
  );
};

export default TemplateConditions;
