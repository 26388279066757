/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { Table, Image, message } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import classes from './index.module.less';
import { decodeSelectedKeys } from '../../utils/formatProductData/SelectedKeys';

// Table columns
const columns = [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    width: '70px',
    align: 'center',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
];

// Fetch data from the graphql query
// converts graphql query response to the format as required
const getProducts = (body) => {
  const keys = [];

  // map the response as required in the table columns
  // objects keys should be same as columns dataIndex and key
  // children should have save key names as of columns
  const dataSource = body?.data?.products?.edges?.map((product) => {
    /**
     * ?    Children element -> Array [{}]
     * Object's key should be same as columns dataIndex/key which is going to be displayed
     */

    const children = product?.node?.variants?.edges
      ?.filter((variant) => variant?.node?.title !== 'Default Title')
      .map((variant) => ({
        key: variant?.node?.id,
        parentKey: product?.node?.id,
        title: variant?.node?.title,
        amount: variant?.node?.price,
        availableForSale: variant?.node?.availableForSale,
      }));

    /**
     * ?  Returns Product details
     * *  id
     * *  title: String
     * *  image: React Component
     * *  amount: String
     * *  children: Array
     */

    keys.push(product?.node?.id); // Keys for initially expanded rows
    return {
      key: product?.node?.id,
      title: product?.node?.title,
      firstVariantId: product?.node?.variants?.edges[0]?.node?.id,
      handle: product?.node?.handle,
      children,
      image: (
        <Image
          preview={false}
          src={product?.node?.images?.edges[0]?.node?.originalSrc}
          width={32}
          height={32}
        />
      ),
      amount: product?.node?.variants?.edges[0]?.node?.price,
    };
  });
  return [dataSource, keys];
};

const Products = function Products({
  searchQuery,
  products,
  storedSelectedRows,
  selectedRowKeys,
  handleRowSelect,
  handleRowDeselect,
  setProducts,
  handleDecodedKeys,
  setLoading,
}) {
  const table = useRef();
  // const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  //  call product's API based on filters and pagination
  const fetchProducts = useCallback(async () => {
    const response = await axios
      .post('/graphql', {
        query: `{
        products(first: 10, sortKey: TITLE, after: ${JSON.stringify(
          products.after,
        )}, query: "status:active ${searchQuery || ''}") {
            pageInfo {
                 hasNextPage
            }
            edges {
                node {
                    title
                    id
                    handle
                    images(first: 1, maxWidth: 400) {
                        edges {
                            node {
                                originalSrc
                                altText
                            }
                        }
                    }
                    variants(first: 90) {
                        edges {
                            node {
                                id
                                title
                                price
                                availableForSale
                            }
                        }
                    }
                }
                cursor
            }
        }
    }`,
      })
      .then((res) => Promise.resolve(res.data.body))
      .catch((error) => Promise.reject(error));

    return response;
  }, [searchQuery, products.after]);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);

    fetchProducts()
      .then((body) => {
        if (isMounted) {
          const [dataSource, keys] = getProducts(body);

          // Decode selected keys
          const decodedKeys = decodeSelectedKeys(
            storedSelectedRows,
            dataSource,
            true,
          );

          // save decoded keys
          handleDecodedKeys(decodedKeys);

          // save products data
          setProducts({
            dataSource: [...dataSource],
            expandedKeys: [...keys],
            hasNextPage: body.data.products.pageInfo.hasNextPage,
            after: body.data.products.edges.at(-1)
              ? body.data.products.edges.at(-1).cursor
              : null,
          });
        }
      })
      .catch(() => {
        if (isMounted) {
          message.error('Something went wrong');
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [page, searchQuery]);

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        handleRowSelect(selectedRows.map((row) => row.key));
      } else {
        const removeProduct = record.key.includes('gid://shopify/Product/');
        const removeVariant = record.key.includes(
          'gid://shopify/ProductVariant/',
        );

        // save all the variants keys to be removed
        let childrenArray = [];
        if (removeProduct) {
          childrenArray = record.children.map(
            (product) => product && product.key,
          );
          handleRowDeselect([record.key, ...childrenArray]);
        } else if (removeVariant) {
          childrenArray = [record.key];
          handleRowDeselect([record.parentKey, ...childrenArray]);
        }
      }
    },
  };

  return (
    <InfiniteScroll
      dataLength={products.dataSource.length}
      hasMore={products.hasNextPage}
      next={() => setPage((prev) => prev + 1)}
      height={397}
    >
      <Table
        ref={table}
        columns={columns}
        className={classes.tableTree}
        dataSource={products.dataSource}
        pagination={false}
        showHeader={false}
        scroll={{ x: 0, y: 'auto' }}
        // loading={{
        //   spinning: loading,
        //   indicator: loadingIcon,
        //   size: 'large',
        //   tip: 'Loading...',
        // }}
        rowSelection={{
          ...rowSelection,
          checkStrictly: false,
          selectedRowKeys,
          preserveSelectedRowKeys: true,
        }}
        expandable={{
          expandedRowKeys: products.expandedKeys,
          expandIcon: () => null,
        }}
      />
    </InfiniteScroll>
  );
};

export default Products;
