import { Space, Image, Typography, Button } from 'antd';
import { default as PlusCircleOutlined } from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import classes from './index.module.less';
// import totalSales from '../../assets/images/total-sales.png';

const BannerBar = function BannerBar({
  heading,
  subHeading,
  imageSrc,
  onLaunchClick,
  showButton,
  buttonText,
  showExtraButton,
  extraButtonText,
  onExtraButtonClick,
}) {
  const plusCircleIcon = <PlusCircleOutlined />;
  return (
    <div className={classes.launchCampaignBar}>
      <Space className={classes.subDiv} size={35} wrap>
        <Image preview={false} height={60} src={imageSrc} />
        <Space direction='vertical' size={6}>
          <Typography.Text className={classes.heading}>
            {heading}
          </Typography.Text>
          <Typography.Text>{subHeading}</Typography.Text>
        </Space>
        {showButton && (
          <Button
            icon={plusCircleIcon}
            onClick={onLaunchClick}
            type='primary'
            className='primaryButton'
          >
            {buttonText}
          </Button>
        )}
        {showExtraButton && (
          <Button
            className={`primaryButton ${classes.extraButton}`}
            icon={plusCircleIcon}
            onClick={onExtraButtonClick}
            type='primary'
          >
            {extraButtonText}
          </Button>
        )}
      </Space>
    </div>
  );
};

export default BannerBar;
