// @ts-check

import Alert from 'antd/lib/alert';
import Typography from 'antd/lib/typography';

const { Text } = Typography;

const AllProductsDisabledAlert = ({ isAllProductsDisabled }) => {
  // isAllProductsDisabled = false;
  if (!isAllProductsDisabled) {
    return null;
  }

  return (
    <Alert
      description={
        <Text>Password protected stores cannot choose this option.</Text>
      }
      type='warning'
      style={{ width: 'calc(100% + 1rem)' }}
    />
  );
};

export default AllProductsDisabledAlert;
