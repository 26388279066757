/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import {
  Tabs,
  Card,
  Row,
  Col,
  Select,
  Typography,
  Space,
  Badge,
  Spin,
  message,
} from 'antd';
import { useState, useEffect, Fragment } from 'react';
import { default as DownOutlined } from '@ant-design/icons/lib/icons/DownOutlined';
import axios from 'axios';
import moment from 'moment';
// import rules from './rules.json';
import classes from './index.module.less';
import RuleValues from './RuleValues';
import useMounted from '../../hooks/useMounted';

const dateFormat = 'YYYY-MM-DD';

const AllConditions = function AllConditions({
  setSelectedSegmentRules,
  selectedSegmentRules,
  currentEditedSegment,
}) {
  const mounted = useMounted();
  const [tabs, setTabs] = useState([]);
  const [selected, setSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { TabPane } = Tabs;
  const { Option } = Select;

  useEffect(
    () => {
      const fn = async () => {
        let data;
        try {
          data = await axios.get(`/rules`);
        } catch (err) {
          message.error('Something went wrong');
          console.error(err);
        }
        if (!mounted.current) {
          return;
        }

        if (data) {
          const { rules } = data.data;
          // console.log('rulesFromRes', rulesFromRes);
          // const tempTabs = [];
          for (const t of rules) {
            for (const s of t.categories) {
              s.isOpened = false;
              for (const f of s.attributes) {
                let attributeData;
                if (
                  currentEditedSegment &&
                  currentEditedSegment.rules &&
                  currentEditedSegment.rules.length > 0
                ) {
                  attributeData = currentEditedSegment.rules.find(
                    (i) => i.entity === t.entity && i.attr === f.attr,
                  );
                }
                if (f.operators && f.operators.length > 0) {
                  f.selectedOperator =
                    attributeData && attributeData.operator
                      ? attributeData.operator
                      : f.operators[0].value;
                }
                if (f.type === 'multiselect') {
                  f.inputValue = '';
                  f.options = f.valueOptions;
                }
                if (f.type === 'tagbox') {
                  f.inputVisible = false;
                  f.inputValue = '';
                }
                if (attributeData && attributeData.value) {
                  if (
                    attributeData.operator === 'between' ||
                    attributeData.operator === 'notBetween' ||
                    attributeData.operator === 'dateBetween' ||
                    attributeData.operator === 'dateNotBetween'
                  ) {
                    f.selectedValue = attributeData.value.start;
                    f.selectedValue1 = attributeData.value.end;
                  } else if (f.type === 'select') {
                    f.selectedValue = attributeData.value.value;
                  } else if (f.type === 'multiselect') {
                    const sv = [];
                    for (const m of attributeData.value) {
                      sv.push(m.value);
                    }
                    f.selectedValue = sv;
                  } else {
                    f.selectedValue = attributeData.value;
                  }
                } else {
                  f.selectedValue = '';
                  f.selectedValue1 = '';
                  if (
                    f.type === 'multiselect' ||
                    f.type === 'tagbox' ||
                    f.type === 'shopifyproductgql'
                  ) {
                    f.selectedValue = [];
                  }
                }
                if (f.type === 'number_dropdown_box') {
                  f.selectedExtraOption = '';
                }
              }
            }
          }
          if (
            rules.length > 0 &&
            rules[0].categories &&
            rules[0].categories.length > 0
          ) {
            rules[0].categories[0].isOpened = true;
          }
          setTabs(rules);

          const tempAddedRules = [];
          for (const t of rules) {
            const mc = {
              label: t.label,
              entity: t.entity,
              categories: [],
            };
            const sc = [];
            for (const s of t.categories) {
              let attributeDataInCategory;
              if (
                currentEditedSegment &&
                currentEditedSegment.rules &&
                currentEditedSegment.rules.length > 0
              ) {
                attributeDataInCategory = currentEditedSegment.rules.find(
                  (i) => i.entity === t.entity,
                );
              }
              if (attributeDataInCategory) {
                const attArr = [];
                for (const f of s.attributes) {
                  let attributeData;
                  if (
                    currentEditedSegment &&
                    currentEditedSegment.rules &&
                    currentEditedSegment.rules.length > 0
                  ) {
                    attributeData = currentEditedSegment.rules.find(
                      (i) => i.entity === t.entity && i.attr === f.attr,
                    );
                    if (attributeData) {
                      attArr.push(f);
                    }
                  }
                }
                sc.push({
                  category: s.category,
                  label: s.label,
                  attributes: attArr,
                });
              } else {
                sc.push({
                  category: s.category,
                  label: s.label,
                  attributes: [],
                });
              }
            }
            mc.categories = sc;
            tempAddedRules.push(mc);
          }
          console.log('tempAddedRules', tempAddedRules);
          setSelectedSegmentRules(tempAddedRules);
        } else {
          // Error might have occurred
        }
        setIsLoading(false);
      };
      fn();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleToggle = (val, category) => {
    const items = [...tabs];
    const selectedCategory = items[selected].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      selectedCategory.isOpened = val;
    }
    setTabs(items);
  };

  const handleValueChange = (
    val,
    enityIndex,
    category,
    fieldName,
    fieldToUpdate,
  ) => {
    console.log('val', val);
    const items = [...tabs];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        if (
          selectedField.type === 'datepicker' &&
          fieldToUpdate === 'selectedValue'
        ) {
          if (
            val &&
            (selectedField.selectedOperator === 'dateBetween' ||
              selectedField.selectedOperator === 'dateNotBetween')
          ) {
            selectedField[fieldToUpdate] = moment(val[0]).format(dateFormat);
            selectedField.selectedValue1 = moment(val[1]).format(dateFormat);
          } else if (val) {
            selectedField[fieldToUpdate] = moment(val).format(dateFormat);
          } else {
            selectedField[fieldToUpdate] = val;
          }
        } else {
          selectedField[fieldToUpdate] = val;
        }
        if (
          fieldToUpdate === 'selectedOperator' &&
          (selectedField.selectedOperator === 'dateBetween' ||
            selectedField.selectedOperator === 'dateNotBetween')
        ) {
          if (!selectedField.selectedValue1) {
            selectedField.selectedValue1 = moment(
              selectedField.selectedValue,
            ).format(dateFormat);
          }
        }
        console.log('items', items);
        setTabs(items);
        if (fieldToUpdate === 'selectedValue') {
          updateAddedRules(category, fieldName, selectedField);
        }
      }
    }
  };

  const updateAddedRules = (category, fieldName, fieldData) => {
    const items = [...selectedSegmentRules];
    const selectedCategory = items[selected].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        const index = selectedCategory.attributes.indexOf(selectedField);
        if (index > -1) {
          selectedCategory.attributes.splice(index, 1);
        }
      }
      if (
        (fieldData.type === 'tagbox' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0) ||
        (fieldData.type === 'select' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0) ||
        (fieldData.type === 'multiselect' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0) ||
        (fieldData.type === 'datepicker' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue !== undefined) ||
        (fieldData.type === 'numberbox' &&
          (fieldData.selectedValue || fieldData.selectedValue1)) ||
        (fieldData.type === 'shopifyproductgql' &&
          fieldData.selectedValue !== null &&
          fieldData.selectedValue.length > 0)
      ) {
        selectedCategory.attributes.push(fieldData);
      }
      console.log('AddedRulezz', items);
      setSelectedSegmentRules(items);
    }
  };

  const handleTagClose = (val, entityIndex, category, fieldName) => {
    const items = [...tabs];
    const selectedCategory = items[entityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        if (
          selectedField.selectedValue &&
          selectedField.selectedValue.length > 0
        ) {
          const index = selectedField.selectedValue.indexOf(val);
          if (index > -1) {
            selectedField.selectedValue.splice(index, 1);
          }
          setTabs(items);
          updateAddedRules(category, fieldName, selectedField);
        }
      }
    }
  };

  const showInput = (val, enityIndex, category, fieldName, inputRef) => {
    const items = [...tabs];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        selectedField.inputVisible = true;
        setTimeout(() => {
          console.log(inputRef.current);
          inputRef.current?.focus();
        }, 50);
        console.log('items', items);
        setTabs(items);
      }
    }
  };

  const handleInputConfirm = (val, enityIndex, category, fieldName) => {
    console.log('handleInputConfirm');
    const items = [...tabs];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        const tags = selectedField.selectedValue;
        if (
          selectedField.inputValue &&
          tags.indexOf(selectedField.inputValue) === -1
        ) {
          selectedField.selectedValue = [...tags, selectedField.inputValue];
          selectedField.inputVisible = false;
          selectedField.inputValue = '';
          console.log('items', items);
          setTabs(items);
          updateAddedRules(category, fieldName, selectedField);
          return;
        }
        selectedField.inputVisible = false;
        setTabs(items);
      }
    }
  };

  const handleInputChange = (e, enityIndex, category, fieldName) => {
    const items = [...tabs];
    const selectedCategory = items[enityIndex].categories.find(
      (i) => i.category === category,
    );
    if (selectedCategory) {
      const selectedField = selectedCategory.attributes.find(
        (i) => i.attr === fieldName,
      );
      if (selectedField) {
        if (fieldName === 'Phone Number') {
          if (e.target && e.target.value && e.target.value.match(/^[0-9+]+$/)) {
            selectedField.inputValue = e.target.value;
          }
        } else {
          selectedField.inputValue = e.target.value;
        }
        setTabs(items);
        updateAddedRules(category, fieldName, selectedField);
      }
    }
  };

  const selectedCountForATab = (rule) => {
    let count = 0;
    for (const cat of rule.categories) {
      count += cat.attributes.length;
    }
    return count;
  };

  const onTabChange = (e) => {
    setSelected(e);
    const items = [...tabs];
    if (items[e].categories && items[e].categories.length > 0) {
      items[e].categories[0].isOpened = true;
    }
    setTabs(items);
  };

  return (
    <div>
      {isLoading && (
        <div className={classes.spinner}>
          <Spin size='large' />
        </div>
      )}
      {!isLoading && (
        <Tabs
          className={classes.segmentsCreationTabs}
          onChange={(e) => {
            onTabChange(e);
          }}
        >
          {tabs.map((rule, index) => (
            <TabPane
              tab={
                <span>
                  <span className={classes.mr5}>{rule.label}</span>
                  <Badge
                    count={selectedCountForATab(selectedSegmentRules[index])}
                    showZero={false}
                  />
                </span>
              }
              key={index}
            >
              {rule.categories.map((cat, cIndex) => (
                <Fragment key={`${rule.entity}-subCategory-${cIndex}`}>
                  <Card
                    title={
                      <Space>
                        <Typography.Text
                          className={classes.subcategoryTitle}
                          strong
                        >
                          {cat.label}
                        </Typography.Text>
                        <Badge
                          count={
                            selectedSegmentRules[index].categories[cIndex]
                              .attributes.length
                          }
                          showZero={false}
                        />
                      </Space>
                    }
                    extra={<DownOutlined />}
                    className={classes.cursorPointer}
                    bordered={false}
                    bodyStyle={{ padding: '0px' }}
                    onClick={() => handleToggle(!cat.isOpened, cat.category)}
                  />
                  {cat.isOpened &&
                    cat.attributes.map((field, fIndex) => (
                      <Row
                        className={classes.leafFieldsRow}
                        key={`${rule.entity}-${cat.category}-field-${fIndex}`}
                      >
                        <Col span={5}>
                          {field.label}{' '}
                          {(selectedSegmentRules[index].categories[
                            cIndex
                          ].attributes.find((u) => u.attr === field.attr)
                            ?.selectedValue ||
                            selectedSegmentRules[index].categories[
                              cIndex
                            ].attributes.find((u) => u.attr === field.attr)
                              ?.selectedValue1) && (
                            <Badge offset={[3, 0]} color='#04cea4' />
                          )}
                        </Col>
                        <Col span={8}>
                          {field.operators && field.operators.length > 0 && (
                            <Select
                              className={classes.width90}
                              value={field.selectedOperator}
                              onChange={(e) =>
                                handleValueChange(
                                  e,
                                  selected,
                                  cat.category,
                                  field.attr,
                                  'selectedOperator',
                                )
                              }
                            >
                              {field.operators.map((operator, oindex) => (
                                <Option
                                  key={`${rule.entity}-${cat.category}-operator-${oindex}`}
                                  value={operator.value}
                                >
                                  {operator.label}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Col>
                        <Col span={11}>
                          {field.selectedOperator !== 'is_empty' && (
                            <RuleValues
                              fieldData={field}
                              entityIndex={selected}
                              category={cat.category}
                              entity={rule.entity}
                              handleInputChange={handleInputChange}
                              handleInputConfirm={handleInputConfirm}
                              showInput={showInput}
                              handleTagClose={handleTagClose}
                              handleValueChange={handleValueChange}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                </Fragment>
              ))}
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default AllConditions;
