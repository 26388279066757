/* eslint-disable react/no-array-index-key */
import {
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  InputNumber,
  Tag,
  Typography,
  Button,
} from 'antd';
import { default as PlusOutlined } from '@ant-design/icons/lib/icons/PlusOutlined';
import { useState, useRef } from 'react';
import moment from 'moment';
import { productSelectedCount } from '../../utils/formatProductData/SelectedKeys';
import SelectProduct from '../SelectProduct';
import { productsRadio } from '../../utils/constants/selectProductRadios';
import classes from './index.module.less';

const dateFormat = 'YYYY-MM-DD';

const ValueComponent = function ValueComponent({
  fieldData,
  handleInputChange,
  handleInputConfirm,
  category,
  showInput,
  handleTagClose,
  entityIndex,
}) {
  const inputRef = useRef();
  return (
    <div className={classes.tagbox}>
      {fieldData.selectedValue.map((tag, index) => (
        <Tag
          key={tag}
          closable
          onClose={(e) => {
            handleTagClose(tag, entityIndex, category, fieldData.attr);
          }}
        >
          {tag}
        </Tag>
      ))}
      {fieldData.inputVisible && (
        <Input
          ref={inputRef}
          type='text'
          size='small'
          className={classes.width40}
          value={fieldData.inputValue}
          onChange={(e) =>
            handleInputChange(e, entityIndex, category, fieldData.attr)
          }
          onBlur={(e) =>
            handleInputConfirm(e, entityIndex, category, fieldData.attr)
          }
          onPressEnter={(e) =>
            handleInputConfirm(e, entityIndex, category, fieldData.attr)
          }
        />
      )}
      {!fieldData.inputVisible && (
        <Tag
          onClick={(e) =>
            showInput(e, entityIndex, category, fieldData.attr, inputRef)
          }
        >
          <PlusOutlined /> Add {fieldData.label}
        </Tag>
      )}
    </div>
  );
};

const RuleValues = function RuleValues({
  fieldData,
  category,
  entity,
  entityIndex,
  handleInputChange,
  handleInputConfirm,
  showInput,
  handleTagClose,
  handleValueChange,
}) {
  const [isModalActive, setIsModalActive] = useState(false);

  const handleModalOpen = () => {
    setIsModalActive(true);
  };
  const handleModalClose = () => {
    setIsModalActive(false);
  };

  if (fieldData.type === 'tagbox') {
    return (
      <ValueComponent
        category={category}
        fieldData={fieldData}
        handleInputChange={handleInputChange}
        handleInputConfirm={handleInputConfirm}
        showInput={showInput}
        handleTagClose={handleTagClose}
        entityIndex={entityIndex}
      />
    );
  }
  if (fieldData.type === 'numberbox') {
    if (
      fieldData.selectedOperator === 'between' ||
      fieldData.selectedOperator === 'notBetween'
    ) {
      return (
        <Row>
          <Col span={12}>
            <InputNumber
              min={0}
              className={classes.width100}
              defaultValue={fieldData.selectedValue}
              onChange={(e) =>
                handleValueChange(
                  e,
                  entityIndex,
                  category,
                  fieldData.attr,
                  'selectedValue',
                )
              }
            />
          </Col>
          {/* <Col span={2} /> */}
          <Col span={12}>
            <InputNumber
              min={0}
              className={classes.width100}
              defaultValue={fieldData.selectedValue1}
              onChange={(e) =>
                handleValueChange(
                  e,
                  entityIndex,
                  category,
                  fieldData.attr,
                  'selectedValue1',
                )
              }
            />
          </Col>
        </Row>
      );
    }
    return (
      <InputNumber
        min={0}
        className={classes.width100}
        defaultValue={fieldData.selectedValue}
        onChange={(e) =>
          handleValueChange(
            e,
            entityIndex,
            category,
            fieldData.attr,
            'selectedValue',
          )
        }
      />
    );
  }
  if (fieldData.type === 'number_dropdown_box') {
    return (
      <Input.Group compact>
        <InputNumber
          min={0}
          className={classes.width60}
          defaultValue={fieldData.selectedValue}
          onChange={(e) =>
            handleValueChange(
              e,
              entityIndex,
              category,
              fieldData.attr,
              'selectedValue',
            )
          }
        />
        <Select
          allowClear
          className={classes.width40}
          onChange={(e) =>
            handleValueChange(
              e || '',
              entityIndex,
              category,
              fieldData.attr,
              'selectedExtraOption',
            )
          }
          value={fieldData.selectedExtraOption}
        >
          {fieldData.extraOptions.map((item, vindex) => (
            <Select.Option
              key={`${entity}-${category}-${fieldData.attr}-value-${vindex}`}
              value={item.value}
            >
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Input.Group>
    );
  }
  if (fieldData.type === 'select' || fieldData.type === 'boolean') {
    return (
      <Select
        showSearch
        allowClear
        className={classes.width100}
        onChange={(e) =>
          handleValueChange(
            e || '',
            entityIndex,
            category,
            fieldData.attr,
            'selectedValue',
          )
        }
        value={fieldData.selectedValue}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {fieldData.valueOptions.map((item, vindex) => (
          <Select.Option
            key={`${entity}-${category}-${fieldData.attr}-value-${vindex}`}
            value={item.value}
          >
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }
  if (fieldData.type === 'datepicker') {
    if (
      fieldData.selectedOperator === 'dateBetween' ||
      fieldData.selectedOperator === 'dateNotBetween'
    ) {
      return (
        <DatePicker.RangePicker
          className={classes.width100}
          defaultValue={
            fieldData.selectedValue && [
              moment(fieldData.selectedValue, dateFormat),
              moment(fieldData.selectedValue1, dateFormat),
            ]
          }
          onChange={(e) =>
            handleValueChange(
              e,
              entityIndex,
              category,
              fieldData.attr,
              'selectedValue',
            )
          }
        />
      );
    }
    return (
      <DatePicker
        className={classes.width100}
        defaultValue={
          fieldData.selectedValue && moment(fieldData.selectedValue, dateFormat)
        }
        onChange={(e) =>
          handleValueChange(
            e?.toDate(),
            entityIndex,
            category,
            fieldData.attr,
            'selectedValue',
          )
        }
      />
    );
  }
  if (fieldData.type === 'shopifyproductgql') {
    return (
      <div className={classes.displayTable}>
        <Button
          className={`${classes.editBtn} ${classes.child}`}
          onClick={handleModalOpen}
        >
          Edit
        </Button>
        {fieldData.selectedValue && fieldData.selectedValue.length > 0 && (
          <Typography.Text className={classes.child}>
            {fieldData.selectedValue.length} products (
            {productSelectedCount(fieldData.selectedValue)} variants) selected
          </Typography.Text>
        )}
        {isModalActive && (
          <SelectProduct
            handleModalClose={handleModalClose}
            showRadioSelection={false}
            selectedRadioButton={productsRadio}
            storedSelectedRows={fieldData.selectedValue}
            updateSelectedRowKeys={() => {}}
            handleSaveProducts={(e) =>
              handleValueChange(
                e,
                entityIndex,
                category,
                fieldData.attr,
                'selectedValue',
              )
            }
            title='Choose Products'
          />
        )}
      </div>
    );
  }
  if (fieldData.type === 'multiselect') {
    return (
      <Select
        mode='multiple'
        maxTagCount={3}
        className={classes.width100}
        placeholder='Please select'
        value={fieldData.selectedValue}
        onChange={(e) =>
          handleValueChange(
            e,
            entityIndex,
            category,
            fieldData.attr,
            'selectedValue',
          )
        }
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0 ||
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {fieldData.valueOptions.map((item, vindex) => (
          <Select.Option
            key={`${entity}-${category}-${fieldData.attr}-value-${vindex}`}
            value={item.value}
          >
            {item.label}
          </Select.Option>
        ))}
      </Select>
    );
  }
  return '';
};

export default RuleValues;
