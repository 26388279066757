/* eslint-disable no-nested-ternary */
import { Button, Col, Form, Row, Typography } from 'antd';
import { productsRadio } from '../../../../utils/constants/selectProductRadios';
import { productSelectedCount } from '../../../../utils/formatProductData/SelectedKeys';

import classes from './OfferProductCard.module.less';

const { Text } = Typography;

const SelectProductMarkup = function SelectProductMarkup({
  selectedRadioButton,
  handleModalOpen,
}) {
  return (
    <div style={{ marginLeft: 64 }}>
      <Form.Item
        dependencies={['offerProduct', 'products', 'collections']}
        noStyle
      >
        {({ getFieldValue }) => {
          // Boolean value if product is selected
          // then true else false
          const product = selectedRadioButton === productsRadio;

          //  get number of selected products/collections
          const count = product
            ? productSelectedCount(getFieldValue('products'))
            : getFieldValue('collections').length;

          const text = product
            ? `${
                getFieldValue('products').length
              } products (${count} variants) selected`
            : `${count} collections selected`;

          return (
            getFieldValue('offerProduct') === 2 && (
              <Form.Item
                name={product ? ['products'] : ['collections']}
                rules={[
                  {
                    required: true,
                    message: 'Select at least 1 product.',
                  },
                ]}
              >
                <Row align='middle'>
                  {count !== 0 && (
                    <Col flex='auto'>
                      <Text>{text}</Text>
                    </Col>
                  )}
                  <Col>
                    <Button
                      className={classes.editBtn}
                      onClick={handleModalOpen}
                    >
                      {product
                        ? getFieldValue('products').length > 0
                          ? 'Edit'
                          : 'Select'
                        : getFieldValue('collections').length > 0
                        ? 'Edit'
                        : 'Select'}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    </div>
  );
};

export default SelectProductMarkup;
