import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import FooterSection from '../FooterSection';

import classes from './index.module.less';

const ContainerLayout = function ContainerLayout({
  topBar,
  sideBar,
  bannerBar,
  children,
}) {
  return (
    <Layout className={classes.layout}>
      {topBar}
      {bannerBar}
      <Content className={classes.mainContentContainerContent}>
        <Layout className={classes.contentLayout}>
          {sideBar}
          <Content className={classes.mainContent}>{children}</Content>
        </Layout>
      </Content>
      <FooterSection />
    </Layout>
  );
};

export default ContainerLayout;
