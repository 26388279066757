/* eslint-disable no-console */
import { useState } from 'react';
import { Modal, Typography, Button, message } from 'antd';
import moment from 'moment';
import {
  COMPLETED,
  LIVE,
  PAUSED,
  SCHEDULED,
} from '../../../utils/constants/campaignStatus';

const ChangeStatusModal = function ChangeStatusModal({
  record,
  newStatus,
  handleClose,
  handleCampaignStatus,
}) {
  const [loading, setLoading] = useState(false);

  // get new status of the campaign
  // based on start and end date
  const getStatus = () => {
    const startDate = moment(record && record.start_date);

    const endDate = moment(record && record.end_date);

    if (newStatus === LIVE) {
      if (record && record.end_date) {
        // when end date is less than current time
        if (endDate.toISOString() <= moment(new Date()).toISOString()) {
          return COMPLETED;
        }
      }

      // when start date is greater than current time
      if (startDate.toISOString() > moment(new Date()).toISOString()) {
        return SCHEDULED;
      }
      // when start date is less than current time or end date is greater than current time
      if (
        startDate.toISOString() <= moment(new Date()).toISOString() ||
        endDate.toISOString() >= moment(new Date()).toISOString()
      ) {
        return LIVE;
      }
    }
    return newStatus;
  };

  // handle modal submit (Yes button)
  const handleOk = () => {
    const status = getStatus();

    setLoading(true);
    handleCampaignStatus({
      id: record.id,
      status,
      start_date:
        status === COMPLETED &&
        moment(record && record.start_date).toISOString() >
          moment(new Date()).toISOString()
          ? moment(new Date()).toISOString()
          : record.start_date,
      end_date:
        status === COMPLETED
          ? moment(new Date()).toISOString()
          : record.end_date,
    }).then(() => {
      message.success('Campaign updated successfully');
      handleClose();
    });
  };

  // Modal title based on new status
  const Title = () => {
    switch (newStatus) {
      case LIVE: {
        return `Are you sure want to live ${record.name}.`;
      }
      case PAUSED: {
        return `Are you sure want to pause ${record.name}.`;
      }
      case COMPLETED: {
        return `Are you sure want to end ${record.name}.`;
      }
      default: {
        return `Are you sure want update ${record.name}.`;
      }
    }
  };

  const footer = [
    <Button
      key='cancel-button'
      type='ghost'
      className='primaryButton'
      onClick={handleClose}
      disabled={loading}
    >
      Cancel
    </Button>,
    <Button
      key='primary-button'
      className='primaryButton'
      type='primary'
      onClick={handleOk}
      disabled={loading}
    >
      Yes
    </Button>,
  ];

  return (
    <Modal
      title='Are you sure?'
      visible
      mask
      maskTransitionName={null}
      closable={!loading}
      maskClosable={false}
      onCancel={handleClose}
      onOk={handleOk}
      okText='Yes'
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading, disabled: loading }}
      zIndex={2000}
      destroyOnClose
      footer={footer}
    >
      <Typography.Text>
        <Title />
      </Typography.Text>
    </Modal>
  );
};

export default ChangeStatusModal;
