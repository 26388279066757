import { useRef, useEffect, useState } from 'react';
import classes from './index.module.less';

const Scrollable = function Scrollable({ children }) {
  const scrollableDivRef = useRef(null);
  const scrollableRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  const handleScroll = () => {
    // update the classname based on scroll position

    // if scroll width is less than browser width
    // there is no scrollbar
    if (
      scrollableRef.current.scrollWidth <= scrollableRef.current.clientWidth
    ) {
      scrollableDivRef.current.className = classes.container;
      return;
    }

    // If there is a scrollbar
    // if scroll position is at left most
    // show box shadow on right side
    if (scrollableRef.current.scrollLeft === 0) {
      scrollableDivRef.current.className = classes.leftSide;
    }
    // if scroll position is at right most
    // show box shadow at left side
    else if (
      scrollableRef.current.scrollLeft ===
      scrollableRef.current.scrollWidth - scrollableRef.current.clientWidth
    ) {
      scrollableDivRef.current.className = classes.rightSide;
    }
    // if scroll position is betweeen left and right most
    // show box shadow on both side i.e. left and right side
    else {
      scrollableDivRef.current.className = classes.centered;
    }
  };

  useEffect(() => {
    if (isMounted) {
      handleScroll();
    }
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);
    // window resize handler
    window.addEventListener('resize', handleScroll);

    // remove resize handler on unmount
    return () => window.removeEventListener('resize', handleScroll);
  }, []);

  return (
    <div>
      <div ref={scrollableDivRef} className={classes.container}>
        <div
          ref={scrollableRef}
          onScroll={handleScroll}
          className={classes.scrollable}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Scrollable;
