import { useContext, useEffect, useState } from 'react';
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Space,
  Avatar,
  Drawer,
  Popover,
  Typography,
} from 'antd';
import { default as BarsOutlined } from '@ant-design/icons/lib/icons/BarsOutlined';
import { NavLink, useLocation } from 'react-router-dom';
import { default as CaretDownOutlined } from '@ant-design/icons/CaretDownOutlined';
import Logo from './Logo';

import classes from './index.module.less';
import ShopContext from '../../context/ShopContext';
import CheckoutStatusRibbon from '../CheckoutStatus/CheckoutStatusRibbon';

const { Header } = Layout;
const { Link, Text } = Typography;

const TopBarMenu = function TopBarMenu({ mode, className }) {
  const { pathname } = useLocation();
  const [selectedKey, setSelectedKey] = useState([]);

  // set the selected menu item
  useEffect(() => {
    if (pathname.startsWith('/campaigns')) {
      setSelectedKey(['/campaigns']);
    } else if (pathname.startsWith('/segments')) {
      setSelectedKey(['/segments']);
    } else if (pathname.startsWith('/offer-packs')) {
      setSelectedKey(['/offer-packs']);
    } else if (pathname.startsWith('/faqs')) {
      setSelectedKey(['/faqs']);
    } else {
      setSelectedKey([]);
    }
  }, [pathname]);

  return (
    <Menu mode={mode} selectedKeys={selectedKey} className={className}>
      <Menu.Item key='/campaigns'>
        <NavLink to='/campaigns'>Campaigns</NavLink>
      </Menu.Item>
      <Menu.Item key='/segments'>
        <NavLink to='/segments'>Customers</NavLink>
      </Menu.Item>
      {/* <Menu.Item key='/offer-packs'>
        <NavLink to='/offer-packs'>Offer Packs</NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key='/faqs'>
        <NavLink to='/faqs'>FAQs</NavLink>
      </Menu.Item> */}
    </Menu>
  );
};

const TopBar = function TopBar({ children }) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { shop } = useContext(ShopContext);

  const rowGutter = [10];
  const barsIcon = <BarsOutlined />;

  const popoverTitle = (
    <Space
      direction='vertical'
      size={0}
      className={classes.userPopoverTitle}
      style={{ marginTop: 5 }}
    >
      <Text strong>{shop?.name}</Text>
      <Text type='secondary'>{shop?.myshopify_domain}</Text>
    </Space>
  );
  const popoverContent = (
    <div className={classes.userPopoverContent}>
      Need support?{' '}
      <Link href='mailto:support@mlveda.com' target='_blank'>
        support@mlveda.com
      </Link>
    </div>
  );

  return [
    <Header key='header' className={classes.header}>
      <Row
        gutter={rowGutter}
        justify='center'
        align='middle'
        className={classes.logoContainer}
        wrap={false}
      >
        <Col className='pcHidden'>
          <Button
            type='primary'
            icon={barsIcon}
            onClick={() => setDrawerVisible(true)}
          />
        </Col>
        <Col className={classes.logoCol}>
          <Logo />
        </Col>
      </Row>

      {/* Desktop menu */}
      <TopBarMenu
        className={`${classes.menuWrapper} mobileHidden`}
        mode='horizontal'
      />

      <div className='pcHidden' style={{ flexGrow: 1 }} />
      <Space className={classes.rightSectionSpace}>
        {children}
        <CheckoutStatusRibbon />
        <Popover
          title={popoverTitle}
          content={popoverContent}
          placement='bottomRight'
          trigger='click'
          overlayStyle={{ textAlign: 'right' }}
          overlayInnerStyle={{ minWidth: 250 }}
          destroyTooltipOnHide
          openClassName={classes.userPopover}
          overlayClassName={classes.userPopover}
        >
          <div style={{ cursor: 'pointer' }}>
            <Avatar className={`${classes.avatar} mobileHidden`}>
              {/* shop owner initial */}
              {shop && shop.shop_owner[0]}
            </Avatar>
            <CaretDownOutlined
              style={{ marginLeft: 5, fontSize: '0.7rem', color: 'grey' }}
            />
          </div>
        </Popover>
      </Space>
    </Header>,
    // Side drawer for navigation section
    <Drawer
      key='drawer'
      placement='left'
      closable={false}
      visible={drawerVisible}
      className={classes.drawer}
      onClose={() => setDrawerVisible(false)}
    >
      {/* Mobile menu */}
      <TopBarMenu mode='vertical' />
    </Drawer>,
  ];
};

export default TopBar;
