import { Routes, Route, Navigate } from 'react-router-dom';
import {
  Suspense,
  lazy,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  Row,
  Skeleton,
  message,
  Spin,
  Space,
  Image,
  Typography,
  Button,
} from 'antd';
import axios from 'axios';
import './utils/axios';
import { default as LoadingOutlined } from '@ant-design/icons/lib/icons/LoadingOutlined';
import FallbackLoader from './components/FallbackLoader';
import avtar from './assets/images/side pic1.jpg';

import classes from './App.module.less';
import CampaignOrders from './containers/Campaigns/CampaignOrders';
import CampaignsDashboard from './containers/Campaigns/Dashboard';
import OfferPackPage from './containers/OfferPacks';
import ShopContext from './context/ShopContext';
import CreateCampaignModel from './containers/Campaigns/CreateCampaignModel';
import Welcome from './containers/Onboarding/Welcome';
import SettingsContext from './context/SettingContext';
import ProtectedRoute from './components/ProtectedRoute';
import SessionExpiredPage from './components/SessionExpiredPage/index';
import NotFoundPage from './components/NotFoundPage';
import IsEnableCheckoutExtensionModalShownContext from './context/IsEnableCheckoutExtensionModalShownContext';

const SegmentsPage = lazy(() => import('./containers/Segments'));
const CampaignsPage = lazy(() => import('./containers/Campaigns'));
const FaqsPageContainer = lazy(() => import('./containers/FaqsPage'));

// Default Spin indicator
Spin.setDefaultIndicator(<LoadingOutlined spin />);

const App = function App() {
  // state denotes if modal was shown prev or not mack it true if user click on later btn
  const [
    isEnableCheckoutExtensionModalShown,
    setIsEnableCheckoutExtensionModalShown,
  ] = useState(false);

  const [isSessionValid, setIsSessionValid] = useState(true);
  const [shop, setShop] = useState(null);
  const [settings, setSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPopOver, setShowPopOver] = useState(false);

  // Get value from url by params name
  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;

    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) return null;

    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  useEffect(() => {
    // Check Session Token
    const token = getParameterByName('token');

    if (token != null) {
      sessionStorage.setItem('token', token);
    } else if (
      process.env.REACT_APP_ENVIORNMENT !== 'local' &&
      !sessionStorage.getItem('token')
    ) {
      setIsSessionValid(false);
      // eslint-disable-next-line no-useless-return
      return;
    }

    // handle axios response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) =>
        new Promise((resolve, reject) => {
          if (error.response && error.response.status === 401) {
            // Set session false when token expires
            setIsSessionValid(false);
          }

          reject(error);
        }),
    );

    // Fetch all apis
    Promise.all([axios.get('/shops'), axios.get('/settings')])
      .then(([shopResponse, settingsResponse]) => {
        setShop(shopResponse.data);
        setSettings(settingsResponse.data);

        // Live chat support integration (CRISP)
        const shopName = shopResponse?.data?.myshopify_domain;

        // script for chat icons

        window.$crisp = [];

        window.CRISP_WEBSITE_ID = '9a70b14e-b8db-48b2-8773-95eb66b91cee';

        // eslint-disable-next-line func-names
        (function () {
          const d = document;

          const s = d.createElement('script');

          s.src = 'https://client.crisp.chat/l.js';

          s.async = 1;

          d.getElementsByTagName('head')[0].appendChild(s);

          window.$crisp.push([
            'set',
            'session:segments',
            [['checkout360-upsell']],
          ]);

          window.$crisp.push(['set', 'session:data', ['storename', shopName]]);
        })();
      })
      .catch(() => {
        message.error('Something went wrong');
        // Live chat support "Crisp" Error handling
        window.$crisp = [];

        window.CRISP_WEBSITE_ID = '9a70b14e-b8db-48b2-8773-95eb66b91cee';

        // eslint-disable-next-line func-names
        (function () {
          const d = document;

          const s = d.createElement('script');

          s.src = 'https://client.crisp.chat/l.js';

          s.async = 1;

          d.getElementsByTagName('head')[0].appendChild(s);

          window.$crisp.push([
            'set',
            'session:segments',
            [['checkout360-upsell']],
          ]);
        })();
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setShowPopOver(true);
        }, 1000);
      });
  }, []);

  // Handle Settings data
  const handleSettingsData = useCallback((data) => {
    setSettings(data);
  }, []);

  // context values
  const shopValues = useMemo(
    () => ({
      shop,
    }),
    [shop],
  );

  const settingsValues = useMemo(
    () => ({
      settings,
      handleSettingsData,
    }),
    [handleSettingsData, settings],
  );
  const checkoutExtensionContextValue = useMemo(
    () => ({
      isEnableCheckoutExtensionModalShown,
      setIsEnableCheckoutExtensionModalShown,
    }),
    [
      isEnableCheckoutExtensionModalShown,
      setIsEnableCheckoutExtensionModalShown,
    ],
  );

  // eslint-disable-next-line no-nested-ternary
  return loading ? (
    <Skeleton active />
  ) : isSessionValid ? (
    <IsEnableCheckoutExtensionModalShownContext.Provider
      value={checkoutExtensionContextValue}
    >
      <Suspense
        fallback={
          <Row
            justify='center'
            align='middle'
            className={classes.spinnerContainer}
          >
            <FallbackLoader />
          </Row>
        }
      >
        <ShopContext.Provider value={shopValues}>
          <SettingsContext.Provider value={settingsValues}>
            {/* App routings */}
            <Routes>
              <Route
                path='/'
                element={
                  <Navigate
                    replace
                    to={
                      settings?.isOnboardingCompleted
                        ? '/campaigns'
                        : '/welcome'
                    }
                  />
                }
              />
              <Route
                path='/welcome'
                element={
                  settings?.isOnboardingCompleted ? (
                    <Navigate replace to='/campaigns' />
                  ) : (
                    <Welcome />
                  )
                }
              />
              <Route
                path='/segments'
                element={
                  <ProtectedRoute settings={settings}>
                    <SegmentsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/campaigns'
                element={
                  <ProtectedRoute settings={settings}>
                    <CampaignsPage />
                  </ProtectedRoute>
                }
              >
                <Route index element={<CampaignsDashboard />} />
                <Route path=':id' element={<CampaignOrders />} />
              </Route>
              <Route
                path='/campaigns/details'
                element={
                  <ProtectedRoute settings={settings}>
                    <CreateCampaignModel />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/offer-packs'
                element={
                  <ProtectedRoute settings={settings}>
                    <OfferPackPage />
                  </ProtectedRoute>
                }
              />
              <Route path='/faqs' element={<FaqsPageContainer />} />
              <Route path='*' element={<NotFoundPage />} />
            </Routes>

            {settings?.isOnboardingCompleted && showPopOver && (
              <div className={classes.bottomPopover}>
                <div className={classes.closeIcon}>
                  <svg
                    onClick={() => setShowPopOver(false)}
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8.39172 9.24916L1.74922 15.8917L0.891724 15.0342L7.53422 8.39166L0.891724 1.74916L1.74922 0.891663L8.39172 7.53416L15.0342 0.891663L15.8917 1.74916L9.24922 8.39166L15.8917 15.0342L15.0342 15.8917L8.39172 9.24916Z'
                      fill='#666666'
                    />
                  </svg>
                </div>
                <Space direction='vertical' align='center' size={8}>
                  <Image src={avtar} preview={false} height={150} />
                  <Typography.Text strong className={classes.font20}>
                    Let our team help you setup campaigns on your store. Its
                    100% free.
                  </Typography.Text>
                  <Button
                    className={`primaryButton ${classes.actionBtn}`}
                    onClick={() =>
                      window.open('https://calendly.com/mlveda-team', '_blank')
                    }
                    type='primary'
                  >
                    Schedule a call with experts
                  </Button>
                </Space>
              </div>
            )}
          </SettingsContext.Provider>
        </ShopContext.Provider>
      </Suspense>
    </IsEnableCheckoutExtensionModalShownContext.Provider>
  ) : (
    <SessionExpiredPage shop={shop} />
  );
};

export default App;
