import { Button, Col, Row } from 'antd';
import { default as CloseOutlined } from '@ant-design/icons/lib/icons/CloseOutlined';

import classes from './index.module.less';

/**
 *
 * @warning Pass `closable={false}` to antd Modal component
 */
const ModalTitle = function Title({ onClose, children, closeBtnClass }) {
  const closeIcon = <CloseOutlined />;

  return (
    <Row align='middle'>
      <Col flex='auto'>{children}</Col>
      <Col>
        <Button
          type='text'
          className={`${closeBtnClass ?? ''} ${classes.closeBtn}`}
          icon={closeIcon}
          onClick={onClose}
        />
      </Col>
    </Row>
  );
};

export default ModalTitle;
