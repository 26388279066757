import { Col, Row, Button, message } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Template from '../Template';
import classes from './index.module.less';
import useMounted from '../../hooks/useMounted';

const FromTemplates = function FromTemplates({ setTemplateSelected }) {
  const mounted = useMounted();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);

  const fetchTemplates = async (cursor, loadMoreClicked) => {
    let templateData;
    try {
      templateData = await axios.get(`/segment_templates?next=${cursor || ''}`);
    } catch (err) {
      console.error(err);
    }
    if (!mounted.current) return;
    if (templateData) {
      if (loadMoreClicked) {
        setTemplates((prev) => [...prev, ...templateData.data.results]);
      } else {
        setTemplates(templateData.data.results);
      }
      const { results, ...rest } = templateData.data;
      setMetaData(rest);
    } else {
      message.error('Something went wrong');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTemplates(null, false);
  }, []);

  const loadMoreClicked = () => {
    if (metaData.hasNext) {
      fetchTemplates(metaData.next, true);
    } else {
      fetchTemplates(null, true);
    }
  };

  return (
    <div>
      {!isLoading && (
        <>
          <Row gutter={[12, 24]}>
            {/* <Col span={24} className={classes.selectMenu}>
              <Select defaultValue='recent'>
                <Option value='recent'>Recent</Option>
                <Option value='top'>Top Used</Option>
              </Select>
            </Col> */}

            {templates.map((template) => (
              <Col xs={24} sm={12} key={template.id}>
                <Template
                  template={template}
                  setTemplateSelected={setTemplateSelected}
                />
              </Col>
            ))}
          </Row>
          {metaData?.hasNext && (
            <div className={classes.loadMoreBtn}>
              <Button type='link' onClick={() => loadMoreClicked()}>
                Load More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FromTemplates;
