import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import Spin from 'antd/lib/spin';
import message from 'antd/lib/message';
import Typography from 'antd/lib/typography';
import Space from 'antd/lib/space';
import Image from 'antd/lib/image';
import { useMemo, useReducer, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import enableCheckoutExtensionImg from '../../assets/images/checkout-extension.png';
import disableCheckoutExtensionImg from '../../assets/images/disable-checkout-extension.png';

import classes from './EnableCheckoutStatusModal.module.less';
import ValidationSuccessMessage from './ValidationSuccessMessage';

function ModalTitle({ isModalForEnable }) {
  return (
    <div>
      <div>
        <Title
          level={5}
          style={{ fontSize: 18, marginBottom: 0, lineHeight: 1.4 }}
        >
          {isModalForEnable ? 'Enable' : 'Disable'} the Offers365 app on your
          store
        </Title>
      </div>
      <Text
        style={{ whiteSpace: 'break-spaces', fontSize: 15, color: '#4d5055' }}
      >
        Just follow the below three steps
      </Text>
    </div>
  );
}

export default function EnableCheckoutStatusModal({
  onCancel,
  shopName,
  setIsModalOpen,
  isModalForEnable,
  setDispatchForEnable,
}) {
  const [loading, setLoading] = useState(false);

  const adminCheckoutPageURL = useMemo(
    () => `https://${shopName}/admin/settings/checkout`,
    [shopName],
  );
  const buttonText = useMemo(
    () => (isModalForEnable ? 'Enable' : 'Disable'),
    [isModalForEnable],
  );

  const handleOk = async () => {
    setLoading(true);
    try {
      const {
        data: { status },
      } = await axios.get('/settings/status');

      if (status === isModalForEnable) {
        setIsModalOpen(false);
      }
      setDispatchForEnable(status);
    } catch (err) {
      console.error(err);
      message.error('Something went wrong');
    }
    setLoading(false);
  };

  return (
    <Modal
      visible
      centered
      closable={false}
      maskTransitionName={null}
      maskClosable={false}
      maskStyle={{ backgroundColor: '#36363678' }}
      title={<ModalTitle isModalForEnable={isModalForEnable} />}
      onOk={handleOk}
      onCancel={onCancel}
      className={classes.modal}
      okText={buttonText}
      okButtonProps={{
        disabled: loading,
        className: 'primaryButton',
      }}
      cancelText={<Typography.Link>Later</Typography.Link>}
      cancelButtonProps={{ className: classes.cancelBtn, disabled: loading }}
    >
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
        <Space direction='vertical' size={12} className={classes.container}>
          <ol className={classes.list}>
            <li>
              Click{' '}
              <Typography.Link
                underline
                target='_blank'
                href={adminCheckoutPageURL}
              >
                this link
              </Typography.Link>{' '}
              (Checkout settings on your store)
            </li>
            <li>
              Scroll down to <b>Post-Purchase</b> section.
            </li>
            <li>
              Select {isModalForEnable ? 'Offers365' : 'none'} and click on save
              button as shown below.
            </li>
            <Image
              style={{ marginBlock: 5, width: 450, maxWidth: '100%' }}
              src={
                isModalForEnable
                  ? enableCheckoutExtensionImg
                  : disableCheckoutExtensionImg
              }
              preview={false}
            />
            <li>
              After saving, click on the <b>&#8220;{buttonText}&#8221;</b>{' '}
              button below.
            </li>
          </ol>
        </Space>
      </Spin>
    </Modal>
  );
}
