import { useCallback, useState, useEffect } from 'react';
import ChooseAudience from '../../../components/ChooseAudience';
import AudienceList from '../../Campaigns/CreateCampaignModel/AudienceCard/AudienceList';

const Step1 = function Step1({
  segments,
  handleSaveSegments,
  handleRemoveSegment,
  allCustomersId,
}) {
  const [isChooseAudienceModalOpen, setIsChooseAudienceModalOpen] =
    useState(false);

  // Locally save the selected segments in modal
  const [segmentsSelected, setSegmentsSelected] = useState(segments);

  useEffect(() => {
    setSegmentsSelected(segments);
  }, [segments, isChooseAudienceModalOpen]);

  // handle segment select
  // save segment as object containing value, label and key
  const handleSegmentSelect = useCallback((segment) => {
    setSegmentsSelected((prev) => [
      ...prev,
      {
        value: segment?.id,
        label: segment?.name,
        key: segment?.id,
      },
    ]);
  }, []);

  // handle segment de-select
  // remove selected segment based on its value(getting as in prop "id")
  const handleSegmentDeselect = useCallback((id) => {
    setSegmentsSelected((prev) =>
      prev.filter((segment) => segment.value !== id),
    );
  }, []);

  const handleChooseModalClose = useCallback(() => {
    setIsChooseAudienceModalOpen(false);
  }, []);
  const handleChooseModalOpen = useCallback(() => {
    setIsChooseAudienceModalOpen(true);
  }, []);
  return (
    <>
      <AudienceList
        handleChooseModalOpen={handleChooseModalOpen}
        handleRemoveSegment={handleRemoveSegment}
      />
      {isChooseAudienceModalOpen && (
        <ChooseAudience
          segmentsSelected={segmentsSelected}
          handleModalClose={handleChooseModalClose}
          handleSegmentSelect={handleSegmentSelect}
          handleSegmentDeselect={handleSegmentDeselect}
          handleSaveSegments={handleSaveSegments}
          allCustomersId={allCustomersId}
        />
      )}
    </>
  );
};

export default Step1;
