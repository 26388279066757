/* eslint-disable no-nested-ternary */
import moment from 'moment';
import {
  COMPLETED,
  LIVE,
  PAUSED,
  SCHEDULED,
} from '../utils/constants/campaignStatus';
import { offerDiscountOptions } from '../utils/constants/discountTypes';
import {
  collectionsRadio,
  productsRadio,
} from '../utils/constants/selectProductRadios';

function getCampaignData(campaign, state = {}) {
  // To find campaign's status
  const getStatus = () => {
    const startDate = moment(
      `${campaign && campaign.start_date} ${campaign && campaign.start_time}`,
    );

    const endDate =
      campaign.end_date &&
      moment(
        `${campaign && campaign.end_date} ${campaign && campaign.end_time}`,
      );

    if (state && state.editMode) {
      if (campaign && campaign.isCampaignLive) {
        if (campaign && campaign.setEndDate) {
          // when end date is less than current time
          if (endDate.toISOString() <= moment(new Date()).toISOString()) {
            return COMPLETED;
          }
        }

        // when start date is greater than current time
        if (startDate.toISOString() > moment(new Date()).toISOString()) {
          return SCHEDULED;
        }
        // when start date is less than current time or end date is greater than current time
        if (
          startDate.toISOString() <= moment(new Date()).toISOString() ||
          (endDate && endDate.toISOString() >= moment(new Date()).toISOString())
        ) {
          return LIVE;
        }
      }
      if (state.status === COMPLETED) {
        return COMPLETED;
      }

      return PAUSED;
    }
    // For create new campaign
    if (startDate.toISOString() <= moment(new Date()).toISOString()) {
      return LIVE;
    }

    return SCHEDULED;
  };

  // Campaign's data
  const data = {
    status: getStatus(),
    name: campaign && campaign.name,
    description: (campaign && campaign.description) || '',
    segment_ids:
      campaign.audienceRadio === 1
        ? campaign && campaign.selected_segments.map((segment) => segment.value)
        : [campaign?.defaultAudience],
    start_date: moment(
      `${campaign && campaign.start_date} ${campaign && campaign.start_time}`,
    ).toISOString(),
    end_date: campaign.setEndDate
      ? moment(
          `${campaign && campaign.end_date} ${campaign && campaign.end_time}`,
        ).toISOString()
      : null,
    // free_shipping_to_offers: campaign && campaign.free_shipping_to_offers,
    heading_text: campaign && campaign.heading_text,
    sub_heading_text: campaign && campaign.sub_heading_text,
    accept_offer_button_text: campaign && campaign.accept_offer_button_text,
    decline_offer_button_text: campaign && campaign.decline_offer_button_text,
    enable_timer: campaign?.enableCampaignTimeout,
    timer_time: campaign?.campaignTimeout,
    offers: [
      {
        discount_type: campaign && campaign.discount_type,
        // discount_value:
        //   campaign && campaign.discount_type !== offerDiscountOptions.no.value
        //     ? campaign.discount_type === offerDiscountOptions.percent.value
        //       ? campaign.percentage_discount_value
        //       : Number(campaign.fixed_discount_value)
        //     : 0,
        discount_value:
          campaign.discount_type === offerDiscountOptions.percent.value
            ? campaign.percentage_discount_value
            : Number(campaign.fixed_discount_value),
        offer_no_of_products_to_show:
          campaign && campaign.offer_no_of_products_to_show,
        all_products: campaign.offerProduct === 1,
        products:
          campaign &&
          campaign.offerProduct === 2 &&
          campaign.selectedRadioButton === productsRadio
            ? campaign.products
            : [],
        collection_ids:
          campaign &&
          campaign.offerProduct === 2 &&
          campaign.selectedRadioButton === collectionsRadio
            ? campaign.collections
            : [],
      },
    ],
  };
  return data;
}

export default getCampaignData;
