import {
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useState,
} from 'react';
import Switch from 'antd/lib/switch';
import SettingsContext from '../../context/SettingContext';
import EnableCheckoutStatusModal from './EnableCheckoutStatusModal';
import IsEnableCheckoutExtensionModalShownContext from '../../context/IsEnableCheckoutExtensionModalShownContext';
import CheckoutStatusRibbonUI from './CheckoutStatusRibbonUI';
import ValidationSuccessMessage from './ValidationSuccessMessage';

const validateModalReducerActions = {
  SET_MODAL_FOR_ENABLE: 'SET_MODAL_FOR_ENABLE',
  SET_MODAL_FOR_DISABLE: 'SET_MODAL_FOR_DISABLE',
};

function validationModalStateReducer(state, action) {
  switch (action.type) {
    case validateModalReducerActions.SET_MODAL_FOR_ENABLE:
      return action.payload;
    case validateModalReducerActions.SET_MODAL_FOR_DISABLE:
      return null;
    default:
      return state;
  }
}

export default function CheckoutStatusRibbon() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { settings, handleSettingsData } = useContext(SettingsContext);
  const [validationModalState, dispatch] = useReducer(
    validationModalStateReducer,
    null,
  );
  const {
    isEnableCheckoutExtensionModalShown,
    setIsEnableCheckoutExtensionModalShown,
  } = useContext(IsEnableCheckoutExtensionModalShownContext);

  const isModalForEnable = !settings?.appStatusFromStore;

  useEffect(() => {
    // settings.appStatusFromStore === true if app is enabled on store
    if (settings.appStatusFromStore) {
      setIsEnableCheckoutExtensionModalShown(true);
    }
  }, [setIsEnableCheckoutExtensionModalShown, settings.appStatusFromStore]);

  useLayoutEffect(() => {
    if (!isEnableCheckoutExtensionModalShown && !settings?.appStatusFromStore) {
      setIsModalOpen(true);
    }
  }, [isEnableCheckoutExtensionModalShown, settings?.appStatusFromStore]);

  // const isModalOpen =
  //   !isEnableCheckoutExtensionModalShown && !settings?.appStatusFromStore;
  // if isModalOpen === true so we can't show ribbon
  const isShowRibbon = !isModalOpen && !settings?.appStatusFromStore;

  const onModalCancel = () => {
    setIsEnableCheckoutExtensionModalShown(true);
    setIsModalOpen(false);
  };

  const onModalConfirm = () => {
    setIsEnableCheckoutExtensionModalShown(true);
    handleSettingsData({
      ...settings,
      appStatusFromStore: !settings?.appStatusFromStore,
    });
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsEnableCheckoutExtensionModalShown(false);
  };

  const setDispatchForEnable = (status) => {
    dispatch({
      type: validateModalReducerActions.SET_MODAL_FOR_ENABLE,
      payload: status,
    });
  };

  const onValidationModal = () => {
    dispatch({
      type: validateModalReducerActions.SET_MODAL_FOR_DISABLE,
    });
    if (validationModalState === isModalForEnable) {
      onModalConfirm();
    }
  };

  return [
    <Switch
      key='switch'
      onClick={() => setIsModalOpen(true)}
      checked={settings?.appStatusFromStore}
    />,
    // if validationModalState === null so modal success or filed modal can't show if the value of validationModalState are true of false so modal show
    validationModalState != null && (
      <ValidationSuccessMessage
        key='ValidationSuccessMessage'
        onValidationModal={onValidationModal}
        validationStatus={validationModalState}
        isModalForEnable={isModalForEnable}
      />
    ),
    isModalOpen && (
      <EnableCheckoutStatusModal
        key='modal'
        // its use for render text conditionally
        isModalForEnable={isModalForEnable}
        setIsModalOpen={setIsModalOpen}
        onCancel={onModalCancel}
        setDispatchForEnable={setDispatchForEnable}
        // onConfirm={onModalConfirm}
        shopName={settings?.shopName}
      />
    ),
    isShowRibbon && (
      <CheckoutStatusRibbonUI key='ribbon' openModal={openModal} />
    ),
  ];
}
