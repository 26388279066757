/* eslint-disable no-nested-ternary */
import { Card, Divider, Space, Typography } from 'antd';
// import { useState } from 'react';
// import SelectProduct from '../../../../components/SelectProduct';
import CardTitle from '../shared/CardTitle';
// import { productsRadio } from '../../../../utils/constants/selectProductRadios';

import OfferList from './OfferList';
// import OfferType from './OfferType';

const { Text } = Typography;

export const OfferProductNote = () => (
  <div style={{ marginTop: '12px' }}>
    <Text strong>Note:</Text>{' '}
    <ol>
      <li>
        One product will be shown to your customer from the above selected
        products
      </li>
      <li>Free shipping will be enabled by default</li>
    </ol>
  </div>
);

const OfferProductCard = function OfferProductCard({
  selectedRadioButton,
  handleSaveProducts,
  handleSaveCollections,
  handleRadioSelect,
}) {
  // const [isModalActive, setIsModalActive] = useState(false);

  // const handleModalOpen = () => {
  //   setIsModalActive(true);
  // };
  // const handleModalClose = () => {
  //   setIsModalActive(false);
  // };

  // const radioChildren = [
  //   <Radio.Button key='1' value={1} disabled={shop.password_enabled}>
  //     All products
  //   </Radio.Button>,
  //   <Radio.Button key='2' value={2}>
  //     Choose your own
  //   </Radio.Button>,
  // ];

  return [
    <Card key='offer-product-card'>
      <Space className='fullWidth' direction='vertical' size={16}>
        <CardTitle
          title='Step 2: Products'
          description='Select the products that you want to upsell'
          // helpText='Info'
        />
        <Divider style={{ marginBlock: 0 }} />
        {/* <BlockRadioButtons
          radioGroupProps={{
            children: radioChildren,
          }}
          formItemProps={{
            name: 'offerProduct',
            style: { marginTop: 8 },
          }}
        /> */}
        {/* <RadioList name='offerProduct'>
          <RadioListItem
            item={{
              id: 1,
              title: 'All products',
              description:
                'Let facebook select the most relevant goal based on your settings.',
              avatar: allCustomer,
            }}
            disabled={shop.password_enabled}
            value={1}
          />

          <Form.Item dependencies={['audienceRadio']}>
            {({ getFieldValue }) => (
              <RadioListItem
                item={{
                  id: 2,
                  title: 'Choose your own',
                  description:
                    'Use a form to collect contact information from potential customers.',
                  avatar: audience,
                }}
                value={2}
              >
                {' '}
                {getFieldValue('audienceRadio') === 1 && <div />}
              </RadioListItem>
            )}
          </Form.Item>
        </RadioList> */}

        {/* <Form.Item
          dependencies={['offerProduct', 'products', 'collections']}
          noStyle
        >
          {({ getFieldValue }) => {
            const product = selectedRadioButton === productsRadio;

            const count = product
              ? productSelectedCount(getFieldValue('products'))
              : getFieldValue('collections').length;

            const text = product
              ? `${
                  getFieldValue('products').length
                } products (${count} variants) selected`
              : `${count} collections selected`;

            return (
              getFieldValue('offerProduct') === 2 && (
                <Form.Item
                  name={product ? ['products'] : ['collections']}
                  rules={[
                    {
                      required: true,
                      message: 'Select at least 1 product.',
                    },
                  ]}
                >
                  <Row align='middle'>
                    <Col flex='auto'>
                      <Text>{text}</Text>
                    </Col>
                    <Col>
                      <Button
                        className={classes.editBtn}
                        onClick={handleModalOpen}
                      >
                        {product
                          ? getFieldValue('products').length > 0
                            ? 'Edit'
                            : 'Add'
                          : getFieldValue('collections').length > 0
                          ? 'Edit'
                          : 'Add'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              )
            );
          }}
        </Form.Item> */}
        <OfferList
          selectedRadioButton={selectedRadioButton}
          handleSaveProducts={handleSaveProducts}
          handleSaveCollections={handleSaveCollections}
          handleRadioSelect={handleRadioSelect}
        />
        <OfferProductNote />
        {/* <Form.Item name='free_shipping_to_offers' valuePropName='checked'>
          <Checkbox>Enable free shipping</Checkbox>
        </Form.Item> */}
      </Space>
      {/* <OfferType /> */}
    </Card>,
  ];
};

export default OfferProductCard;
