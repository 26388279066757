import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

import classes from './NotFound.module.less';

const NotFoundPage = function NotFoundPage() {
  const navigate = useNavigate();
  const navigateHome = () => navigate('/');
  const navigateHomeBtn = (
    <Button onClick={navigateHome} type='primary'>
      Back Home
    </Button>
  );

  return (
    <div className={classes.container}>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={navigateHomeBtn}
      />
    </div>
  );
};

export default NotFoundPage;
