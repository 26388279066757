/* eslint-disable react/jsx-no-constructed-context-values */
import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import {
  Modal,
  Space,
  Typography,
  Form,
  Button,
  Row,
  Col,
  Image,
  message,
  Switch,
  Collapse,
  Skeleton,
  Card,
} from 'antd';
import { default as QuestionCircleOutlined } from '@ant-design/icons/lib/icons/QuestionCircleOutlined';

import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import useModal from 'antd/lib/modal/useModal';
import ModalTitle from '../../../components/ModalTitle';
import AudienceCard from './AudienceCard/index';
import OffersCreativeDesignCard from './OffersCreativeDesignCard';
import OfferDiscountCard from './OfferDiscountCard';
import OfferProductCard from './OfferProductCard';
import ConfirmCampaignModal from './ConfirmCampaignModal';
import classes from './index.module.less';
import PreviewCard from './PreviewCard';
import logo from '../../../assets/images/logo2.png';
import logoClasses from '../../../components/TopBar/Logo.module.less';
import {
  collectionsRadio,
  productsRadio,
} from '../../../utils/constants/selectProductRadios';
import { decodeSegments } from '../../../utils/formatSelectedSegments/segments';
import { offerDiscountOptions } from '../../../utils/constants/discountTypes';
import {
  COMPLETED,
  LIVE,
  SCHEDULED,
} from '../../../utils/constants/campaignStatus';
import getCampaignData from '../../../hooks/getCampaignData';
import CreateCamapignForm from './ConfirmCampaignModal/CreateCamapignForm';
import ShopContext from '../../../context/ShopContext';

const { Panel } = Collapse;

const CreateCampaignModel = function CreateCampaignModel() {
  const { shop } = useContext(ShopContext);
  const [isCreateAudienceModalOpen, setIsCreateAudienceModalOpen] =
    useState(false);
  const [selectedRadioButton, setSelectedRadioButton] = useState(productsRadio);
  const [isCampaignLive, setIsCampaignLive] = useState(false);
  const [defaultAudience, setDefaultAudience] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const isFormChangedRef = useRef(false);
  const [confirmModalControl, confirmModalContent] = useModal();

  const currentTime = new Date();

  // handle product/collection radio selected
  const handleRadioSelect = useCallback((newValue) => {
    setSelectedRadioButton(newValue);
  }, []);

  // handle campaign status
  const handleCampaignStatus = useCallback(() => {
    isFormChangedRef.current = true;
    setIsCampaignLive((prev) => !prev);
  }, []);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    // Fetch all customer api
    axios
      .get('/segments/all_customers')
      .then((res) => {
        if (isMounted) {
          const segments = decodeSegments([res.data]);
          setDefaultAudience(segments);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          message.error('Something went wrong');
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set campaign status
    state &&
      state.editMode &&
      setIsCampaignLive(
        (state && state.status === LIVE) ||
          (state && state.status === SCHEDULED),
      );

    setSelectedRadioButton(
      state &&
        state.offers &&
        state.offers[0] &&
        state.offers[0].collection_ids &&
        state.offers[0].collection_ids.length !== 0
        ? collectionsRadio
        : productsRadio,
    );
  }, [state]);

  // Form instance iniitial value
  const initialValues = {
    name: state && state.editMode ? state.name : '',
    description: state && state.editMode ? state.description : '',
    // eslint-disable-next-line no-nested-ternary
    audienceRadio: state?.editMode
      ? state?.segment_ids?.length === 1 &&
        state?.segment_ids[0] === defaultAudience[0]?.value
        ? 0
        : 1
      : 0,
    id: state && state.id ? state.id : null,
    selected_segments:
      state && state.editMode && state.segments
        ? decodeSegments(state.segments)
        : [defaultAudience[0]?.value], // Audience Card
    start_date:
      state && state.editMode && state.start_date
        ? moment(state.start_date).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD'),
    start_time:
      state && state.editMode && state.start_date
        ? moment(state.start_date).format('HH:mm')
        : moment(new Date()).format('HH:mm'),
    end_date:
      state && state.editMode && state.end_date
        ? moment(state.end_date).format('YYYY-MM-DD')
        : null,
    end_time:
      state && state.editMode && state.end_date
        ? moment(state.end_date).format('HH:mm')
        : null,
    setEndDate: state && state.editMode && state.end_date,
    // free_shipping_to_offers:
    //   state && state.editMode ? state.free_shipping_to_offers : false,

    heading_text:
      state && state.editMode && state.heading_text
        ? state.heading_text
        : ['Before you go!'],

    sub_heading_text:
      state && state.editMode && state.sub_heading_text
        ? state.sub_heading_text
        : "Here's a special one-time offer. Add this to your order within {{timer}}",
    accept_offer_button_text:
      state && state.editMode && state.accept_offer_button_text
        ? state.accept_offer_button_text
        : 'Pay now • {{total_amount}}',
    decline_offer_button_text:
      state && state.editMode && state.decline_offer_button_text
        ? state.decline_offer_button_text
        : 'Decline upsell offer',
    offerProduct:
      state && state.editMode
        ? state.offers[0] && state.offers[0].all_products
          ? 1
          : 2
        : shop?.password_enabled
        ? 2
        : 1,
    products:
      state && state.editMode && state.offers[0] && state.offers[0].products
        ? state.offers[0].products
        : [], // select product modal : selected products
    collections:
      state &&
      state.editMode &&
      state.offers[0] &&
      state.offers[0].collection_ids
        ? state.offers[0].collection_ids
        : [], // select product modal : selected collections
    discount_type:
      state &&
      state.editMode &&
      state.offers[0] &&
      state.offers[0].discount_type
        ? state.offers[0].discount_type
        : offerDiscountOptions.percent.value,
    fixed_discount_value:
      state &&
      state.editMode &&
      state.offers[0] &&
      state.offers[0].discount_value &&
      state.offers[0].discount_type === offerDiscountOptions.fixed.value
        ? state.offers[0].discount_value
        : 1,
    percentage_discount_value:
      state &&
      state.editMode &&
      state.offers[0] &&
      state.offers[0].discount_value &&
      state.offers[0].discount_type === offerDiscountOptions.percent.value
        ? state.offers[0].discount_value
        : 25,
    offer_no_of_products_to_show:
      state &&
      state.editMode &&
      state.offers[0] &&
      state.offers[0].offer_no_of_products_to_show
        ? state.offers[0].offer_no_of_products_to_show
        : 1,
    campaignTimeout: state?.editMode ? state?.timer_time : 5,
    enableCampaignTimeout: state?.editMode ? state?.enable_timer : true,
  };

  // handle select segments
  const handleSaveSegments = useCallback(
    (segments) => {
      form.setFieldsValue({
        selected_segments: [...segments],
      });
    },
    [form],
  );

  // handle de-select segments
  const handleRemoveSegment = useCallback(
    (id) => {
      form.setFieldsValue({
        selected_segments: [
          ...form
            .getFieldValue('selected_segments')
            .filter((segment) => segment.value !== id),
        ],
      });
    },
    [form],
  );

  // handle save products
  const handleSaveProducts = useCallback(
    (products) => {
      form.setFieldsValue({
        products,
      });
    },
    [form],
  );

  // handle save collections
  const handleSaveCollections = useCallback(
    (collections) => {
      form.setFieldsValue({
        collections,
      });
    },
    [form],
  );

  const closeCreateAudienceModal = () => {
    setIsCreateAudienceModalOpen(false);
    // message.success('Campaign created successfully');
  };

  // handle form submit
  const onFinish = () => {
    if (
      state &&
      state.status === COMPLETED &&
      (!form.getFieldValue('setEndDate') ||
        moment(
          `${form.getFieldValue('start_date')} ${form.getFieldValue(
            'start_time',
          )}`,
        ).toISOString() >= moment(currentTime).toISOString() ||
        (form.getFieldValue('end_date') &&
          moment(
            `${form.getFieldValue('end_date')} ${form.getFieldValue(
              'end_time',
            )}`,
          ).toISOString() > moment(currentTime).toISOString()))
    ) {
      setIsCampaignLive(true);
    }
    if (state?.editMode) {
      setIsLoading(true);
      const data = getCampaignData(
        {
          ...form.getFieldsValue(true),
          selectedRadioButton,
          isCampaignLive,
          defaultAudience: defaultAudience[0]?.value,
        },
        state,
      );
      // eslint-disable-next-line no-console
      console.log(data);
      axios
        .put(`/campaigns/${state.id}`, data)
        .then(() => {
          message.success('Campaign updated successfully');
          form.resetFields();
        })
        .then(() => {
          setIsLoading(false);
          navigate('/campaigns');
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(
            error?.response?.data?.message ?? 'Something went wrong',
          );
          console.error(error);
        });
    } else {
      setIsCreateAudienceModalOpen(true);
    }
  };

  // on form validation fail
  const onFinishFailed = () => {
    message.error('Please fill required fields');
  };

  const onClose = () => {
    navigate('/campaigns');
  };

  const modalTitle = (
    <ModalTitle
      closeBtnClass={classes.closeBtn}
      onClose={() => {
        if (state?.editMode && isFormChangedRef.current) {
          confirmModalControl.confirm({
            title: 'There are unsaved changes. Do you want to continue?',
            okText: 'Yes',
            cancelText: 'No',
            onCancel: () => {
              onClose();
            },
          });
        } else {
          onClose();
        }
      }}
    >
      <Row wrap={false} justify='space-between' align='middle'>
        <Col>
          <Space>
            <Image
              onClick={() => onClose()}
              preview={false}
              src={logo}
              className={logoClasses.logo}
            />
            {state?.editMode ? (
              <Typography.Text strong>{state.name}</Typography.Text>
            ) : (
              <Typography.Text strong>
                Create a post purchase offer now
              </Typography.Text>
            )}
          </Space>
        </Col>
        {/* If status is completed then dont show the switch */}
        {state && state.editMode && state.status !== COMPLETED && (
          <Col style={{ marginRight: 16 }}>
            <Form layout='horizontal'>
              <Form.Item label='Change status'>
                <Switch
                  checked={isCampaignLive}
                  onChange={handleCampaignStatus}
                />
              </Form.Item>
            </Form>
          </Col>
        )}
      </Row>
    </ModalTitle>
  );

  const questionIcon = (
    <QuestionCircleOutlined className={classes.questionIcon} />
  );

  const panelHeader = (
    <Typography.Text style={{ lineHeight: 1 }}>
      <Typography.Link>Edit Campaign details </Typography.Link>
    </Typography.Text>
  );

  return [
    confirmModalContent,
    isLoading ? (
      <Skeleton key='create-campaign-skeleton' active />
    ) : (
      <Modal
        visible
        key='create-campaign-modal'
        wrapClassName={classes.modalWrapper}
        className={classes.modal}
        footer={null}
        closable={false}
        title={modalTitle}
        mask={false}
        width='100vw'
      >
        <div className={classes.container}>
          <Form
            layout='vertical'
            initialValues={initialValues}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={(changedValues) => {
              if (state?.editMode) {
                isFormChangedRef.current =
                  form.isFieldsTouched() || isFormChangedRef.current;
              }
              form.validateFields(['sub_heading_text']);
              if (
                changedValues?.enableCampaignTimeout === true &&
                !form.getFieldValue('sub_heading_text').includes('{{timer}}')
              ) {
                const subHeading = `${form.getFieldValue(
                  'sub_heading_text',
                )} {{timer}}`;
                form.setFieldsValue({
                  sub_heading_text: subHeading,
                });
              }
              if (changedValues.audienceRadio) {
                if (changedValues?.audienceRadio === 0)
                  form.setFieldsValue({
                    selected_segments: [defaultAudience.value],
                  });
                else {
                  form.setFieldsValue({
                    selected_segments: [],
                  });
                }
              }
            }}
            scrollToFirstError
          >
            <Space direction='vertical' className='fullWidth' size={0}>
              {/* <Typography.Title level={3}>
                {state?.editMode
                  ? 'Edit a post purchase offer now'
                  : 'Create a post purchase offer now'}
              </Typography.Title> */}
              <Row gutter={[0, 40]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Space className='fullWidth' direction='vertical' size={15}>
                    <Form.Item dependencies={['selected_segments']}>
                      {() => {
                        const segments =
                          form.getFieldValue('selected_segments');
                        return (
                          <AudienceCard
                            segments={segments}
                            handleSaveSegments={handleSaveSegments}
                            handleRemoveSegment={handleRemoveSegment}
                            allCustomersId={defaultAudience[0]?.value}
                          />
                        );
                      }}
                    </Form.Item>
                    <OfferProductCard
                      selectedRadioButton={selectedRadioButton}
                      handleSaveProducts={handleSaveProducts}
                      handleSaveCollections={handleSaveCollections}
                      handleRadioSelect={handleRadioSelect}
                    />
                    <OfferDiscountCard />
                    <OffersCreativeDesignCard />
                    {state?.editMode && (
                      <Collapse
                        ghost
                        // defaultActiveKey={['0']}
                        className={classes.collapse}
                      >
                        <Panel showArrow key='0' header={panelHeader}>
                          <Space
                            direction='vertical'
                            size={16}
                            style={{ width: '100%' }}
                          >
                            <Card>
                              <CreateCamapignForm />
                            </Card>
                          </Space>
                        </Panel>
                      </Collapse>
                    )}
                  </Space>
                </Col>
                <Col
                  order={3}
                  xs={24}
                  sm={24}
                  md={{ span: 12, order: 2 }}
                  lg={{ span: 12, order: 2 }}
                  xl={{ span: 12, order: 2 }}
                  xxl={{ span: 12, order: 2 }}
                >
                  <Space
                    className={`fullWidth ${classes.footerSpace}`}
                    direction='horizontal'
                    wrap={false}
                  >
                    <Form.Item
                      noStyle
                      dependencies={['start_date', 'start_time']}
                    >
                      {() => {
                        const startDate = form.getFieldValue('start_date');
                        const startTime = form.getFieldValue('start_time');
                        const isInPast =
                          startTime && startDate
                            ? moment(`${startDate} ${startTime}`).isBefore(
                                moment(),
                              )
                            : false;

                        return (
                          <Button
                            block
                            type='primary'
                            className='primaryButton'
                            htmlType='submit'
                          >
                            {state && state.editMode
                              ? 'Update Offer'
                              : isInPast
                              ? 'Start Campaign'
                              : 'Schedule Campaign'}
                          </Button>
                        );
                      }}
                    </Form.Item>
                    <Button
                      block
                      icon={questionIcon}
                      className='primaryButton'
                      type='text'
                      htmlType='button'
                    >
                      <Typography.Text
                        className={classes.primaryText}
                        onClick={() => {
                          window.$crisp.push(['do', 'chat:open']);
                        }}
                      >
                        Need help?
                      </Typography.Text>
                    </Button>
                  </Space>
                </Col>
                <Col
                  flex='auto'
                  xs={24}
                  sm={24}
                  md={{ span: 11, offset: 1 }}
                  lg={{ span: 11, offset: 1 }}
                  xl={{ span: 11, offset: 1 }}
                  xxl={{ span: 11, offset: 1 }}
                >
                  {/* <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}> */}
                  <PreviewCard />
                </Col>
              </Row>
            </Space>
            <div id='create-audience-modal'>
              {isCreateAudienceModalOpen && (
                <ConfirmCampaignModal
                  handleModalClose={closeCreateAudienceModal}
                  handleModalOpen={closeCreateAudienceModal}
                  handleCampaignModalClose={onClose}
                  form={form}
                  campaignData={{
                    ...form.getFieldsValue(true),
                    selectedRadioButton,
                    isCampaignLive,
                    defaultAudience: defaultAudience[0]?.value,
                  }}
                />
              )}
            </div>
          </Form>
        </div>
      </Modal>
    ),
  ];
};

export default CreateCampaignModel;
