import {
  Table,
  Typography,
  Space,
  Row,
  Col,
  Button,
  message,
  Empty,
  Tooltip,
  Switch,
} from 'antd';
import { default as TeamOutlined } from '@ant-design/icons/lib/icons/TeamOutlined';
import { default as ShoppingCartOutlined } from '@ant-design/icons/lib/icons/ShoppingCartOutlined';
import { default as InfoCircleOutlined } from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { default as ArrowRightOutlined } from '@ant-design/icons/lib/icons/ArrowRightOutlined';
import { default as QuestionCircleOutlined } from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import { default as CalendarOutlined } from '@ant-design/icons/lib/icons/CalendarOutlined';
import { default as EyeOutlined } from '@ant-design/icons/lib/icons/EyeOutlined';

import {
  useState,
  Suspense,
  useEffect,
  useContext,
  memo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { FilterSelect } from '../SideBar';
import classes from './index.module.less';
import FallbackLoader from '../../../components/FallbackLoader';
import CampaignsContext from '../../../context/CampaignsContext';
import Scrollable from '../../../components/Scrollable';
import ShopContext from '../../../context/ShopContext';
import PriorityModalContext from '../../../context/PriorityModalContext';
import MoreActions from '../MoreActions';
import ChangeStatusModal from '../ChangeStatusModal';

import useMounted from '../../../hooks/useMounted';
import {
  LIVE,
  SCHEDULED,
  PAUSED,
  COMPLETED,
} from '../../../utils/constants/campaignStatus';

const CampaignsDashboard = function CampaignsDashboard() {
  const mounted = useMounted();
  const navigate = useNavigate();
  const { isPriorityModal } = useContext(PriorityModalContext);
  const { shop } = useContext(ShopContext);
  const { selectedFilter, onSelectedFilterChange } =
    useContext(CampaignsContext);
  const [metaData, setMetaData] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const [sortData, setSortData] = useState({
    field: 'priority',
    order: 'asc',
  });
  const [summaryData, setSummaryData] = useState(null);
  const [isTableDataLoading, setIsTableDataLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [key, setKey] = useState(true);

  useEffect(() => {
    if (selectedFilter === COMPLETED) {
      setSortData({
        field: 'name',
        order: 'asc',
      });
      setKey(false);
    } else {
      setKey(true);
    }
  }, [selectedFilter]);

  useEffect(() => {
    const fn = async () => {
      let summary;
      try {
        summary = await axios.get(`/analytics/summary`);
      } catch (err) {
        console.error(err);
      }
      if (!mounted.current) return;
      if (summary) {
        setSummaryData(summary.data);
        setIsLoading(false);
      } else {
        message.error('Something went wrong');
      }
    };
    fn();
  }, [mounted]);

  const fetchCampaigns = async (filter, cursor, sort, loadMoreClicked) => {
    setIsTableDataLoading(true);
    let campaigns;
    try {
      campaigns = await axios.get(
        `/analytics/campaign?status=${filter !== 'all' ? filter : ''}&next=${
          cursor || ''
        }&sortOrder=${sort.order}&sortBy=${sort.field}&limit=10`,
      );
    } catch (err) {
      console.error(err);
    }
    if (!mounted.current) return;
    if (campaigns) {
      if (loadMoreClicked) {
        setCampaignData((prev) => [...prev, ...campaigns.data.results]);
      } else {
        setCampaignData(campaigns.data.results);
      }
      const { results, ...rest } = campaigns.data;
      setMetaData(rest);
    } else {
      message.error('Something went wrong');
    }
    setIsTableDataLoading(false);
  };

  useEffect(
    () => {
      if (!isPriorityModal) {
        fetchCampaigns(
          selectedFilter,
          null,
          selectedFilter === COMPLETED
            ? {
                field: 'name',
                order: 'asc',
              }
            : {
                field: 'priority',
                order: 'asc',
              },
          false,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFilter, isPriorityModal],
  );

  const loadMoreClicked = () => {
    if (metaData.hasNext) {
      fetchCampaigns(selectedFilter, metaData.next, sortData, true);
    } else {
      fetchCampaigns(selectedFilter, null, sortData, true);
    }
  };

  const handleViewOrders = (record) => {
    navigate(`/campaigns/${record.id}`, {
      state: { ...record, editMode: true },
    });
  };

  const handleOrderAnalysis = () => {
    navigate(`/campaigns/all`, {
      state: {
        ...summaryData,
        id: 'all',
        name: 'Order Analysis',
      },
    });
  };

  const handleEditCampaign = (record) => {
    /**
     * ?  For Edit Mode provide => { editMode : true } with record data
     */
    navigate('/campaigns/details', { state: { ...record, editMode: true } });
  };

  // To find campaign's status
  const getStatus = (record) => {
    const startDate = moment(record.start_date);

    if (startDate.toISOString() <= moment(new Date()).toISOString()) {
      return LIVE;
    }

    return SCHEDULED;
  };

  const onSwitchChange = (record) => {
    setCurrentRecord(record);
    setIsStatusModal(true);
  };

  const handleStatusChange = async () => {
    try {
      await axios.put(`/campaigns/${currentRecord.id}`, {
        status:
          currentRecord.status === LIVE || currentRecord.status === SCHEDULED
            ? PAUSED
            : getStatus(currentRecord),
      });
      fetchCampaigns(selectedFilter, null, sortData, false);
    } catch (err) {
      message.error('Something went wrong');
      console.error(err);
    }
  };

  const closeStatusModal = useCallback(() => {
    setIsStatusModal(false);
  }, []);

  const handleCampaignStatus = async (data) => {
    try {
      await axios.put(`/campaigns/${data.id}`, {
        status: data.status,
        start_date: data.start_date,
        end_date: data.end_date,
      });
      if (!mounted.current) return;
      await fetchCampaigns(
        selectedFilter,
        null,
        {
          field: 'priority',
          order: 'asc',
        },
        false,
      );
    } catch (err) {
      message.error('Something went wrong');
      console.error(err);
    }
  };

  const currencyFormat = (num) =>
    `${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;

  const segmentNames = (segments) => {
    const segmnentnames = [];
    for (const item of segments) {
      segmnentnames.push(item.name);
    }
    return segmnentnames.join(', ');
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortVal = {
      field: sorter.field,
      order: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setSortData(sortVal);
    fetchCampaigns(selectedFilter, null, sortVal, false);
  };
  // eslint-disable-next-line no-console
  // console.log('selectedFilter', selectedFilter);
  const columns = [
    {
      title: (
        <Space size={0} direction='vertical'>
          <Typography.Text>CAMPAIGN NAME</Typography.Text>
          {/* <Typography.Text className={classes.fontWeight400} type='secondary'>
            Target Segment(s)
          </Typography.Text> */}
        </Space>
      ),
      key: 'name',
      dataIndex: 'name',
      width: '44%',
      sorter: true,
      defaultSortOrder: selectedFilter === COMPLETED ? 'ascend' : null,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => (
        <Space direction='vertical' size={2} style={{ display: 'flex' }}>
          <Space>
            <Typography.Paragraph
              strong
              className={classes.campaignName}
              onClick={() => handleViewOrders(record)}
            >
              {name}
            </Typography.Paragraph>
            {record?.description && (
              <Tooltip placement='bottom' title={record.description}>
                <InfoCircleOutlined className={classes.infoIcon} />
              </Tooltip>
            )}
          </Space>
          <div className={classes.segementsName}>
            <Typography.Text type='secondary'>
              <TeamOutlined />
            </Typography.Text>
            <Typography.Paragraph
              type='secondary'
              className={classes.segmentsTooltip}
              ellipsis={{
                rows: 1,
                expandable: true,
                symbol: 'more',
                tooltip: true,
              }}
            >
              {segmentNames(record.segments)}
            </Typography.Paragraph>
          </div>
          <div>
            <Typography.Text type='secondary'>
              <CalendarOutlined />
            </Typography.Text>
            <Typography.Text type='secondary' className={classes.dateText}>
              {`${moment(record.start_date).format('MMM DD, YY')} ${
                record.end_date
                  ? `- ${moment(record.end_date).format('MMM DD, YY')}`
                  : 'onwards'
              }`}{' '}
              <Tooltip
                title={
                  <Row>
                    <Col span={24}>
                      <Typography.Text className={classes.dateTimeTip} strong>
                        From:{' '}
                      </Typography.Text>
                      <Space direction='vertical' size={0}>
                        <Typography.Text className={classes.dateTimeTip}>
                          {moment(record.start_date).format(
                            'MMM DD, YY (HH:mm A)',
                          )}
                        </Typography.Text>
                        {/* <Typography.Text className={classes.dateTimeTip}>
                        {moment(startDate).format('HH:MM:SS')}
                      </Typography.Text> */}
                      </Space>
                    </Col>
                    <Col span={24}>
                      <Typography.Text strong className={classes.dateTimeTip}>
                        To:{' '}
                      </Typography.Text>
                      {record.end_date ? (
                        <Space direction='vertical' size={0}>
                          <Typography.Text className={classes.dateTimeTip}>
                            {moment(record.end_date).format(
                              'MMM DD, YY (HH:mm A)',
                            )}
                          </Typography.Text>
                        </Space>
                      ) : (
                        <Typography.Text className={classes.dateTimeTip}>
                          Not Set
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Typography.Text>
          </div>
        </Space>
      ),
    },
    {
      title: (
        <Space size={0} direction='vertical'>
          <Typography.Text>STATUS</Typography.Text>
          {/* <Typography.Text type='secondary' className={classes.fontWeight400}>
            Status
          </Typography.Text> */}
        </Space>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '13%',
      align: 'center',
      render: (status, record) => (
        <Space direction='vertical' size={8}>
          {status === COMPLETED ? (
            <Typography.Text>Completed</Typography.Text>
          ) : (
            <>
              <Switch
                checked={status !== 'paused'}
                onChange={() => {
                  onSwitchChange(record);
                }}
              />
              {status === 'scheduled' && (
                <Typography.Text type='secondary'>Scheduled</Typography.Text>
              )}
            </>
          )}
        </Space>
      ),
    },
    {
      title: 'PRIORITY',
      dataIndex: 'priority',
      key: 'priority',
      width: '13%',
      align: 'right',
      sorter: true,
      defaultSortOrder: selectedFilter !== COMPLETED ? 'ascend' : null,
      // defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'REVENUE',
      key: 'totalRevenue',
      dataIndex: 'totalRevenue',
      width: '26%',
      align: 'right',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (totalRevenue, record) => (
        <Space direction='vertical' size={0}>
          <Typography.Text
            strong
            className={`${classes.font18} ${classes.colorBlue}`}
          >
            {`${shop.currency} ${currencyFormat(totalRevenue)}`}
          </Typography.Text>
          <Space size={8}>
            <Typography.Text>
              <ShoppingCartOutlined /> {record.noOfPurchases}{' '}
              {record.noOfPurchases === 1 ? 'order' : 'orders'}
            </Typography.Text>
            <Typography.Text>
              <EyeOutlined /> {record.noOfImpressions}{' '}
              {record.noOfImpressions === 1 ? 'view' : 'views'}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
    {
      title: '',
      key: 'action',
      width: '4%',
      render: (text, record) => (
        <MoreActions
          key={record.id}
          record={record}
          handleViewOrders={handleViewOrders}
          handleEditCampaign={handleEditCampaign}
          handleCampaignStatus={handleCampaignStatus}
        />
      ),
    },
  ];

  const content = (
    <div>
      <Row className='pcHidden'>
        <Col flex='auto'>
          <Typography.Title level={3}>Campaigns</Typography.Title>
        </Col>
        <Col>
          <FilterSelect
            selectedFilter={selectedFilter}
            onSelectedFilterChange={onSelectedFilterChange}
          />
        </Col>
      </Row>

      <Scrollable>
        <Space size={0} direction='vertical' className={classes.contentSpace}>
          {/* Content */}
          {!isLoading && (
            <Row className={classes.summarySection} align='middle'>
              <Col span={8} className={classes.firstColumn}>
                <Space direction='vertical' size={0}>
                  <Typography.Text className={classes.totalRevenue}>
                    {shop.currency} {currencyFormat(summaryData.totalRevenue)}
                  </Typography.Text>
                  <Typography.Text className={classes.font13}>
                    Total Revenue Generated
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={4}>
                <Space direction='vertical' size={0}>
                  <Typography.Text className={classes.totalRevenue}>
                    {summaryData.noOfPurchases}
                  </Typography.Text>
                  <Typography.Text className={classes.font13}>
                    No. Of Orders
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={4}>
                <Space direction='vertical' size={0}>
                  <Typography.Text className={classes.totalRevenue}>
                    {summaryData.noOfImpressions}
                  </Typography.Text>
                  <Typography.Text className={classes.font13}>
                    No. Of Impressions
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={8} className={classes.lastColumn}>
                <Space direction='vertical' size={0} align='end'>
                  {/* <Typography.Text>See the orders we generated</Typography.Text> */}
                  <Button
                    type='default'
                    className={`${classes.colorGreen} secondaryButton ${classes.orderAnalysisButton}`}
                    onClick={handleOrderAnalysis}
                    style={{
                      padding: '0.5rem 1rem',
                      borderColor: '#04cea4 !important',
                    }}
                  >
                    Order Analysis <ArrowRightOutlined />
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
          <Table
            key={String(key)}
            className={classes.campaignsTable}
            size='middle'
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description='No campaigns found'
                />
              ),
            }}
            columns={columns}
            dataSource={campaignData}
            pagination={false}
            tableLayout='fixed'
            loading={isTableDataLoading}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
          />
        </Space>
      </Scrollable>
      {metaData?.hasNext && (
        <div className={classes.loadMoreBtn}>
          <Button type='link' onClick={() => loadMoreClicked()}>
            Load More
          </Button>
        </div>
      )}
      {isStatusModal && (
        <ChangeStatusModal
          newStatus={
            // eslint-disable-next-line no-nested-ternary
            currentRecord && currentRecord.status === PAUSED ? LIVE : PAUSED
          }
          record={currentRecord}
          handleClose={closeStatusModal}
          handleCampaignStatus={handleStatusChange}
        />
      )}
    </div>
  );

  return (
    <Suspense fallback={<FallbackLoader delay={0}>{content}</FallbackLoader>}>
      {content}
    </Suspense>
  );
};

export default memo(CampaignsDashboard);
