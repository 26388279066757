import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // If the header does not contain the token and the url not public, redirect to login
    // eslint-disable-next-line no-console
    // console.log('request ');
    config.baseURL = process.env.REACT_APP_API_HOST;

    if (
      process.env.REACT_APP_ENVIORNMENT === 'production' ||
      process.env.REACT_APP_ENVIORNMENT === 'development'
    ) {
      const accessToken = sessionStorage.getItem('token');
      // if token is found add it to the header
      if (accessToken) {
        if (config.method !== 'OPTIONS') {
          config.headers.authorization = `Bearer ${accessToken}`;
        }
      } else {
        return Promise.reject();
      }
      return config;
    }

    // Local Enviornment
    const accessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaG9wTmFtZSI6ImFjcy10ZXN0aW5nLm15c2hvcGlmeS5jb20ifQ.5Xu9_suZ53aI_MeTggQVz7Z3C50E83__Xugd__I4wiQ';
    // if token is found add it to the header
    if (accessToken) {
      if (config.method !== 'OPTIONS') {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    } else {
      return Promise.reject();
    }
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

export default axios;
