import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

import classes from './Logo.module.less';

const Logo = function LogoImage() {
  const navigate = useNavigate();
  return (
    <Image
      preview={false}
      src={logo}
      className={classes.logo}
      onClick={() => navigate('/campaigns')}
    />
  );
};

export default Logo;
