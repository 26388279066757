/* eslint-disable no-nested-ternary */
import { Typography, Modal, Space, Image, message, Spin, Button } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../../../../assets/images/logo2.png';
import classes from './index.module.less';
import CreateCamapignForm from './CreateCamapignForm';
import getCampaignData from '../../../../hooks/getCampaignData';

const ConfirmCampaignModal = function ConfirmCampaignModal({
  handleModalClose,
  handleCampaignModalClose,
  campaignData,
  form,
}) {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  // Create Campaign => Post Request
  const handleCreateCampaign = async (campaign, resetFields) => {
    setLoading(true);

    const data = getCampaignData(campaign, state);

    await axios
      .post('/campaigns', data)
      .then(() => {
        message.success('Campaign created successfully');
        resetFields();
      })
      .then(() => {
        setLoading(false);
        handleModalClose();
        handleCampaignModalClose();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.message ?? 'Something went wrong');
        console.error(error);
      });
  };

  const footer = [
    <Button
      key='cancel-button'
      type='ghost'
      className='primaryButton'
      onClick={handleModalClose}
    >
      Cancel
    </Button>,
    <Button
      key='primary-button'
      className='primaryButton'
      type='primary'
      onClick={() => {
        form
          .validateFields()
          .then((values) => {
            handleCreateCampaign(
              {
                ...campaignData,
                ...values,
              },
              form.resetFields,
            );
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info);
          });
      }}
    >
      Save your campaign
    </Button>,
  ];

  return (
    <Modal
      visible
      title={state && state.editMode ? 'Update Campaign' : 'Create Campaign'}
      okText='Save Campaign'
      className={classes.modal}
      width={650}
      onCancel={handleModalClose}
      okButtonProps={{ htmlType: 'submit', disabled: loading }}
      maskClosable={false}
      maskTransitionName={null}
      footer={footer}
      destroyOnClose
      centered
      getContainer={() => document.getElementById('create-audience-modal')}
    >
      <Spin
        spinning={loading}
        tip={
          state && state.editMode ? 'Updating Campaign' : 'Creating Campaign'
        }
      >
        <Space direction='vertical' className='fullWidth' align='center'>
          <Space direction='vertical'>
            <Image preview={false} width={100} src={logo} />
          </Space>
          <Typography.Text className={classes.centerText}>
            Keep your subscriber engaged by sharing your latest news, promoting
            a line of products, or announcing an event.
          </Typography.Text>
        </Space>
        {/* <Form
          layout='vertical'
          initialValues={initialValues}
          form={form}
          className={classes.form}
        > */}
        <CreateCamapignForm />
        {/* </Form> */}
      </Spin>
    </Modal>
  );
};

export default ConfirmCampaignModal;
