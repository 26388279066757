import { memo, useCallback, useState } from 'react';
import { Popover, Typography } from 'antd';
import { default as MoreOutlined } from '@ant-design/icons/lib/icons/MoreOutlined';
import classes from './index.module.less';
import ChangeStatusModal from '../ChangeStatusModal';
import {
  COMPLETED,
  LIVE,
  PAUSED,
  SCHEDULED,
} from '../../../utils/constants/campaignStatus';

const MoreActions = function MoreActions({
  record,
  handleViewOrders,
  handleEditCampaign,
  handleCampaignStatus,
}) {
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [endCampaignModal, setEndCampaignModal] = useState(false);
  const [visible, setVisible] = useState(false);

  // handle popover visiblity
  const handleVisible = (newState) => {
    setVisible(newState);
  };

  // hide popover
  const hidePopover = () => {
    setVisible(false);
  };

  // open status change modal (live/schedule/paused)
  const openStatusModal = useCallback(() => {
    hidePopover();
    setIsStatusModal(true);
  }, []);

  const closeStatusModal = useCallback(() => {
    setIsStatusModal(false);
  }, []);

  // open modal to end specific campaign
  const openEndCampaignModal = useCallback(() => {
    hidePopover();
    setEndCampaignModal(true);
  }, []);

  const closeEndCampaignModal = useCallback(() => {
    setEndCampaignModal(false);
  }, []);

  return (
    <>
      <Popover
        destroyTooltipOnHide
        visible={visible}
        onVisibleChange={handleVisible}
        content={
          <div className={classes.moreActions}>
            <div>
              <Typography.Link
                onClick={() => {
                  hidePopover();
                  handleViewOrders(record);
                }}
              >
                View Results
              </Typography.Link>
            </div>
            <div>
              <Typography.Link
                onClick={() => {
                  hidePopover();
                  handleEditCampaign(record);
                }}
              >
                Edit Campaign
              </Typography.Link>
            </div>
            {record && record.status !== COMPLETED && (
              <div>
                <Typography.Link onClick={openStatusModal}>
                  {record &&
                  (record.status === LIVE || record.status === SCHEDULED)
                    ? 'Pause Campaign'
                    : 'Live Campaign'}
                </Typography.Link>
              </div>
            )}
            {record && record.status !== COMPLETED && (
              <div>
                <Typography.Link onClick={openEndCampaignModal}>
                  End Campaign
                </Typography.Link>
              </div>
            )}
          </div>
        }
        trigger='click'
        placement='bottom'
      >
        <MoreOutlined id='action-popover' />
      </Popover>
      {isStatusModal && (
        <ChangeStatusModal
          newStatus={
            // eslint-disable-next-line no-nested-ternary
            record && record.status === PAUSED ? LIVE : PAUSED
          }
          record={record}
          handleClose={closeStatusModal}
          handleCampaignStatus={handleCampaignStatus}
        />
      )}
      {endCampaignModal && (
        <ChangeStatusModal
          newStatus={COMPLETED}
          record={record}
          handleClose={closeEndCampaignModal}
          handleCampaignStatus={handleCampaignStatus}
        />
      )}
    </>
  );
};

export default memo(MoreActions);
