import { Space } from 'antd';
import { offerDiscountOptions } from '../../../utils/constants/discountTypes';
import FixedDiscountInput from '../../Campaigns/CreateCampaignModel/OfferDiscountCard/FixedDiscountInput';
import PercentageDiscountSlider from '../../Campaigns/CreateCampaignModel/OfferDiscountCard/PercentageDiscountSlider';
import BlockRadioButtons from '../../Campaigns/CreateCampaignModel/shared/BlockRadioButtons';

import classes from '../../Campaigns/CreateCampaignModel/OfferDiscountCard/PercentageDiscountSlider.module.less';

const Step3 = function Step3() {
  const radioOptions = Object.values(offerDiscountOptions);
  const radioFormItemProps = {
    name: 'discount_type',
    style: { marginTop: 8 },
  };
  return (
    <Space
      className={`fullWidth ${classes.discountGroup}`}
      direction='vertical'
    >
      <BlockRadioButtons
        formItemProps={radioFormItemProps}
        options={radioOptions}
      />

      <div style={{ marginTop: 12 }}>
        <FixedDiscountInput />
        <PercentageDiscountSlider />
        {/* <NoDiscount /> */}
      </div>
    </Space>
  );
};

export default Step3;
