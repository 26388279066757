import { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Form, message, Spin } from 'antd';
import moment from 'moment';
import axios from 'axios';
import AudienceCard from '../../Campaigns/CreateCampaignModel/AudienceCard';
import { productsRadio } from '../../../utils/constants/selectProductRadios';
import OfferProductCard from '../../Campaigns/CreateCampaignModel/OfferProductCard';
import OfferDiscountCard from '../../Campaigns/CreateCampaignModel/OfferDiscountCard';
import AdvancedSettingsCard from '../../Campaigns/CreateCampaignModel/AdvancedSettingsCard';
import classes from './index.module.less';
import ConfirmCampaignModal from '../../Campaigns/CreateCampaignModel/ConfirmCampaignModal';
import { offerDiscountOptions } from '../../../utils/constants/discountTypes';
import { decodeSegments } from '../../../utils/formatSelectedSegments/segments';

const ModalGroup = function ModalGroup({ title, offerPack, handleModalClose }) {
  const [state, setState] = useState({
    stepNo: 0, // mark the current step
    steps: [], // stores all the steps to be shown
  });
  const [selectedRadioButton, setSelectedRadioButton] = useState(productsRadio);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [defaultAudience, setDefaultAudience] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    axios
      .get('/segments/all_customers')
      .then((res) => {
        if (isMounted) {
          const segments = decodeSegments([res.data]);
          setDefaultAudience(segments);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          message.error('Something went wrong');
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!offerPack) {
      return;
    }

    if (offerPack.segment_ids && offerPack.segment_ids.length === 0) {
      setState((prev) => ({
        ...prev,
        steps: [...prev.steps, 0],
      }));
    }
    if (
      !(offerPack.offers[0] && offerPack.offers[0].all_products) &&
      offerPack.offers[0] &&
      offerPack.offers[0].products &&
      offerPack.offers[0].products.length === 0 &&
      offerPack.offers[0].collection_ids &&
      offerPack.offers[0].collection_ids.length === 0
    ) {
      setState((prev) => ({
        ...prev,
        steps: [...prev.steps, 1],
      }));
    }

    // if (
    //   offerPack.offers[0] &&
    //   offerPack.offers[0].discount_type === offerDiscountOptions.no.value
    // ) {
    //   setState((prev) => ({
    //     ...prev,
    //     steps: [...prev.steps, 2],
    //   }));
    // }

    if (!offerPack.start_date) {
      setState((prev) => ({
        ...prev,
        steps: [...prev.steps, 3],
      }));
    }

    // Set stepno as 1st element of steps
    setState((prev) => ({
      ...prev,
      stepNo: prev.steps[0],
    }));
  }, [offerPack]);

  // Form instance initial values
  const initialValues = {
    audienceRadio: 0,
    id: offerPack.id || null,
    selected_segments: offerPack.segment_ids || [], // Audience Card
    start_date: moment(offerPack.start_date).format('YYYY-MM-DD') || null,
    start_time: moment(offerPack.start_date).format('HH:mm') || null,
    end_date: moment(offerPack.end_date).format('YYYY-MM-DD') || null,
    end_time: moment(offerPack.end_date).format('HH:mm') || null,
    setEndDate: offerPack.end_date || false,
    // free_shipping_to_offers: offerPack.free_shipping_to_offers || false,
    heading_text: ['Before you go!'],

    sub_heading_text:
      "Here's a special one-time offer. Add this to your order within {{timer}}",
    accept_offer_button_text: 'Pay now • {{total_amount}}',
    decline_offer_button_text: 'Decline upsell offer',
    offerProduct:
      offerPack && offerPack.offers[0] && offerPack.offers[0].all_products
        ? 1
        : 2,
    products:
      offerPack && offerPack.offers[0] && offerPack.offers[0].products
        ? offerPack.offers[0].products
        : [], // select product modal : selected products
    collections:
      offerPack && offerPack.offers[0] && offerPack.offers[0].collection_ids
        ? offerPack.offers[0].collection_ids
        : [], // select product modal : selected collections
    discount_type:
      offerPack && offerPack.offers[0] && offerPack.offers[0].discount_type
        ? offerPack.offers[0].discount_type
        : offerDiscountOptions.percent.value,
    fixed_discount_value:
      offerPack &&
      offerPack.offers[0] &&
      offerPack.offers[0].discount_value &&
      offerPack.offers[0].discount_type === offerDiscountOptions.fixed.value
        ? offerPack.offers[0].discount_value
        : 1,
    percentage_discount_value:
      offerPack &&
      offerPack.offers[0] &&
      offerPack.offers[0].discount_value &&
      offerPack.offers[0].discount_type === offerDiscountOptions.percent.value
        ? offerPack.offers[0].discount_value
        : 25,
    offer_no_of_products_to_show:
      offerPack &&
      offerPack.offers[0] &&
      offerPack.offers[0].offer_no_of_products_to_show
        ? offerPack.offers[0].offer_no_of_products_to_show
        : 2,
    campaignTimeout: offerPack.timer_time ?? 5,
    enableCampaignTimeout: offerPack.enable_timer ?? true,
  };

  // handle products/collections radio select
  const handleRadioSelect = useCallback((newValue) => {
    setSelectedRadioButton(newValue);
  }, []);

  // handle segment select
  const handleSaveSegments = useCallback(
    (segments) => {
      form.setFieldsValue({
        selected_segments: [...segments],
      });
    },
    [form],
  );

  // handle segment remove
  const handleRemoveSegment = useCallback(
    (id) => {
      form.setFieldsValue({
        selected_segments: [
          ...form
            .getFieldValue('selected_segments')
            .filter((segment) => segment.value !== id),
        ],
      });
    },
    [form],
  );

  const closeCreateAudienceModal = () => {
    setIsConfirmModalOpen(false);
    // message.success('Campaign created successfully');
  };
  const openCreateAudienceModal = () => {
    setIsConfirmModalOpen(true);
  };

  // On form instance submit
  const onFinish = () => {
    state.stepNo === state.steps.at(-1)
      ? openCreateAudienceModal()
      : setState((prev) => {
          for (const i of prev.steps) {
            if (i === prev.stepNo && i !== prev.steps.at(-1)) {
              const index = prev.steps.indexOf(i);
              return { ...prev, stepNo: index + 1 };
            }
          }
          return prev;
        });
  };

  // form validation failed
  const onFinishFailed = () => {
    message.error('Please fill required fields');
  };

  const handleBack = () => {
    setState((prev) => {
      for (const i of prev.steps) {
        if (i === prev.stepNo && i !== prev.steps.at(0)) {
          const index = prev.steps.indexOf(i);
          return { ...prev, stepNo: index - 1 };
        }
      }
      return prev;
    });
  };

  // handle save products from SelectProduct modal
  const handleSaveProducts = useCallback(
    (products) => {
      form.setFieldsValue({
        products,
      });
    },
    [form],
  );

  // handle save collections from SelectProduct modal
  const handleSaveCollections = useCallback(
    (collections) => {
      form.setFieldsValue({
        collections,
      });
    },
    [form],
  );

  const footer = [
    <Button
      key='back-button'
      type='ghost'
      className={`primaryButton ${classes.footer}`}
      onClick={
        state.stepNo === state.steps.at(0) ? handleModalClose : handleBack
      }
    >
      {state.stepNo === state.steps.at(0) ? 'Close' : 'Back'}
    </Button>,
    <Button
      key='primary-submit-button'
      type='primary'
      className={`primaryButton ${classes.footer}`}
      htmlType='submit'
    >
      {state.stepNo === state.steps.at(-1) ? 'Submit' : 'Next'}
    </Button>,
  ];

  return [
    <Form
      key='offer-pack-form'
      layout='vertical'
      initialValues={initialValues}
      form={form}
      scrollToFirstError
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={(changedValues) => {
        form.validateFields(['sub_heading_text']);
        if (
          changedValues?.enableCampaignTimeout === true &&
          !form.getFieldValue('sub_heading_text').includes('{{timer}}')
        ) {
          const subHeading = `${form.getFieldValue(
            'sub_heading_text',
          )} {{timer}}`;
          form.setFieldsValue({
            sub_heading_text: subHeading,
          });
        }
        if (changedValues.audienceRadio) {
          if (changedValues?.audienceRadio === 0)
            form.setFieldsValue({
              selected_segments: [defaultAudience.value],
            });
          else {
            form.setFieldsValue({
              selected_segments: [],
            });
          }
        }
      }}
      id='offers-pack-modal'
      className={classes.modal}
    >
      <Modal
        visible
        title={title}
        centered
        width={600}
        maskTransitionName={null}
        maskClosable={false}
        onCancel={handleModalClose}
        footer={footer}
        getContainer={() => document.getElementById('offers-pack-modal')}
      >
        <Spin spinning={isLoading}>
          {state.stepNo === 0 && (
            <Form.Item dependencies={['selected_segments']}>
              {() => {
                const segments = form.getFieldValue('selected_segments');
                return (
                  <AudienceCard
                    segments={segments}
                    handleSaveSegments={handleSaveSegments}
                    handleRemoveSegment={handleRemoveSegment}
                    allCustomersId={defaultAudience.value}
                  />
                );
              }}
            </Form.Item>
          )}
          {state.stepNo === 1 && (
            <OfferProductCard
              selectedRadioButton={selectedRadioButton}
              handleSaveProducts={handleSaveProducts}
              handleSaveCollections={handleSaveCollections}
              handleRadioSelect={handleRadioSelect}
            />
          )}
          {state.stepNo === 2 && <OfferDiscountCard />}
          {state.stepNo === 3 && <AdvancedSettingsCard />}
          <div id='create-audience-modal'>
            {isConfirmModalOpen && (
              <ConfirmCampaignModal
                handleModalClose={closeCreateAudienceModal}
                handleModalOpen={closeCreateAudienceModal}
                handleCampaignModalClose={handleModalClose}
                form={form}
                campaignData={{
                  ...form.getFieldsValue(true),
                  selectedRadioButton,
                  defaultAudience: defaultAudience[0]?.value,
                }}
              />
            )}
          </div>
        </Spin>
      </Modal>
    </Form>,
  ];
};

export default ModalGroup;
