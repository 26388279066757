import Form from 'antd/lib/form';
import Space from 'antd/lib/space';
import { useContext, useState } from 'react';
import RadioList from '../../../../components/RadioList';
import RadioListItem from '../../../../components/RadioList/RadioListItem';
import allCustomer from '../../../../assets/images/all_customers.svg';
import audience from '../../../../assets/images/audience.png';
import ShopContext from '../../../../context/ShopContext';
import SelectProductMarkup from './SelectProductMarkup';
import SelectProduct from '../../../../components/SelectProduct';
import { productsRadio } from '../../../../utils/constants/selectProductRadios';
import AllProductsDisabledAlert from './AllProductsDisabledAlert';

const OfferList = function OfferList({
  selectedRadioButton,
  handleSaveProducts,
  handleSaveCollections,
  handleRadioSelect,
}) {
  const { shop } = useContext(ShopContext);
  const [isModalActive, setIsModalActive] = useState(false);

  const handleModalOpen = () => {
    setIsModalActive(true);
  };
  const handleModalClose = () => {
    setIsModalActive(false);
  };
  return (
    <div>
      <RadioList name='offerProduct'>
        <RadioListItem
          item={{
            id: 1,
            title: 'All products',
            description: (
              <Space direction='vertical'>
                <span>
                  Randomly upsell some products from all the products in the
                  store
                </span>
                <AllProductsDisabledAlert
                  isAllProductsDisabled={shop.password_enabled}
                />
              </Space>
            ),
            avatar: allCustomer,
          }}
          disabled={shop.password_enabled}
          value={1}
        />

        <Form.Item dependencies={['offerProduct']}>
          {({ getFieldValue }) => (
            <RadioListItem
              item={{
                id: 2,
                title: 'Specific products',
                description:
                  'Randomly upsell some products from the below selected products ',
                avatar: audience,
              }}
              value={2}
            >
              {' '}
              {/* Show/hide products/collection section */}
              {getFieldValue('offerProduct') === 2 && (
                <SelectProductMarkup
                  selectedRadioButton={selectedRadioButton}
                  handleModalOpen={handleModalOpen}
                />
              )}
            </RadioListItem>
          )}
        </Form.Item>
      </RadioList>
      {isModalActive && (
        <Form.Item
          dependencies={['products', 'collections']}
          key='select-product-modal'
          style={{ display: 'none' }}
        >
          {({ getFieldValue }) => {
            const products = getFieldValue('products');
            const collections = getFieldValue('collections');
            // setSelectedRowKeys(storedSelectedRows);
            const storedSelectedRows =
              selectedRadioButton === productsRadio ? products : collections;
            return (
              <SelectProduct
                handleModalClose={handleModalClose}
                handleRadioSelect={handleRadioSelect}
                showRadioSelection
                selectedRadioButton={selectedRadioButton}
                storedSelectedRows={storedSelectedRows}
                handleSaveProducts={handleSaveProducts}
                handleSaveCollections={handleSaveCollections}
                title='Choose Products'
              />
            );
          }}
        </Form.Item>
      )}
    </div>
  );
};

export default OfferList;
