import { Col, Row } from 'antd';
import CollapsePanelContent from '../../Campaigns/CreateCampaignModel/OffersCreativeDesignCard/CollapsePanelContent';
import PreviewCard from '../../Campaigns/CreateCampaignModel/PreviewCard';

import classes from './index.module.less';

const Step4 = function Step4() {
  // eslint-disable-next-line no-console

  return (
    <Row className={classes.root} gutter={[24, 0]}>
      <Col
        xs={24}
        sm={24}
        md={10}
        lg={10}
        xl={10}
        xxl={10}
        className={classes.leftSection}
      >
        <CollapsePanelContent />
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
        <PreviewCard />
      </Col>
    </Row>
  );
};

export default Step4;
