import { Layout, Menu } from 'antd';

import classes from './ContainerSider.module.less';

const { Sider } = Layout;

const ContainerSider = function ContainerSider({
  selectedFilter,
  onSelectedFilterChange,
  items,
  title,
  children,
}) {
  return (
    <Sider className={`${classes.sider} mobileHidden`} width={180}>
      <Menu
        selectedKeys={[selectedFilter]}
        mode='inline'
        className={classes.siderMenu}
      >
        {/* <Menu.Item key='title' className={classes.siderMenuTitle}>
          <Typography.Title level={3}>{title}</Typography.Title>
        </Menu.Item> */}
        {items.map((item) => (
          <Menu.Item
            onClick={() => onSelectedFilterChange(item.key)}
            key={item.key}
            icon={item.getIcon()}
          >
            {item.title}
          </Menu.Item>
        ))}
        {children}
      </Menu>
    </Sider>
  );
};

export default ContainerSider;
