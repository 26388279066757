/* eslint-disable no-console */
import { useState } from 'react';
import { Col, Card, Row, Image, Space, Typography, Tag, Button } from 'antd';
import ModalGroup from '../Modals';

const OfferCard = function OfferCard({ offerPack }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSetupButton = () => {
    setIsModalOpen(true);
  };

  return (
    <Col span={24}>
      <Card>
        <Row justify='start' align='middle' wrap gutter={[12, 12]}>
          <Col style={{ minWidth: 40 }}>
            <Image preview={false} src={offerPack.icon} height={40} />
          </Col>
          <Col style={{ flex: 1, minWidth: '50%' }}>
            <Space direction='vertical' size={0}>
              <Typography.Text className='font16' strong>
                {offerPack.name}
              </Typography.Text>
              <Typography.Text className='font14'>
                {offerPack.description}
              </Typography.Text>
            </Space>
          </Col>
          <Col>
            <Tag icon={offerPack.tagIcon} color={offerPack.tagColor}>
              {offerPack.tag}
            </Tag>
          </Col>
          <Col>
            <Button
              type='primary'
              className='primaryButton'
              onClick={handleSetupButton}
              disabled
            >
              Coming soon
            </Button>
          </Col>
        </Row>
      </Card>
      {isModalOpen && (
        <ModalGroup
          title={offerPack.name}
          offerPack={offerPack}
          handleModalClose={handleModalClose}
        />
      )}
    </Col>
  );
};

export default OfferCard;
