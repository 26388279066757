import { Col, Radio, Row, Modal, Button, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { default as LeftOutlined } from '@ant-design/icons/lib/icons/LeftOutlined';
import FromTemplates from './FromTemplates';
import AllConditions from './AllConditions';
import TemplateConditions from './TemplateConditions';

import classes from './index.module.less';

const { Group } = Radio;

const AudienceConditions = function AudienceConditions({
  handleModalOk,
  handleModalClose,
  setSelectedSegmentRules,
  selectedSegmentRules,
  currentEditedSegment,
  formType,
}) {
  const [selected, setSelected] = useState(1);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleSelected = useCallback((e) => {
    setSelectedSegmentRules([]);
    setSelected(e.target.value);
  }, []);

  const checkSelectedRules = () => {
    let isOneRuleAdded = false;
    for (const rule of selectedSegmentRules) {
      for (const cat of rule.categories) {
        if (cat.attributes.length > 0) {
          isOneRuleAdded = true;
          break;
        }
      }
      if (isOneRuleAdded) {
        break;
      }
    }
    return isOneRuleAdded;
  };

  const footer = [
    <Button
      key='cancel-button'
      type='ghost'
      className={`primaryButton ${classes.footer}`}
      onClick={handleModalClose}
    >
      Cancel
    </Button>,
    <Button
      key='primary-button'
      className={`primaryButton ${classes.footer}`}
      type='primary'
      onClick={handleModalOk}
      disabled={!checkSelectedRules()}
    >
      {formType === 'ADD' ? 'Add' : 'Update'}
    </Button>,
  ];

  const setTemplateSelected = (template) => {
    setSelectedTemplate(template);
  };

  const handleBackArrowClick = () => {
    setSelectedSegmentRules([]);
    setSelectedTemplate(null);
  };

  return (
    <Modal
      visible
      title={
        formType === 'ADD' ? 'Create customer group' : 'Edit customer group'
      }
      okText='Save Audience'
      centered
      className={classes.modal}
      width={650}
      onOk={handleModalOk}
      onCancel={handleModalClose}
      maskClosable={false}
      bodyStyle={{
        maxHeight: '70vh',
        overflow: 'auto',
      }}
      maskTransitionName={null}
      footer={footer}
    >
      {selectedTemplate && (
        <Row className={classes.root}>
          <Col className={classes.templateHeading}>
            <LeftOutlined onClick={() => handleBackArrowClick()} />
            <Typography.Text strong>{selectedTemplate.name}</Typography.Text>
          </Col>
          <Col span={24}>
            <TemplateConditions
              rulesData={selectedTemplate.rules}
              setSelectedSegmentRules={setSelectedSegmentRules}
              selectedSegmentRules={selectedSegmentRules}
            />
          </Col>
        </Row>
      )}
      {!selectedTemplate && (
        <Row justify='center' className={classes.root}>
          {/* <Col span={24} className={classes.radioButton}> */}
          {/*   <Group */}
          {/*     size='large' */}
          {/*     buttonStyle='outline' */}
          {/*     defaultValue={selected} */}
          {/*     onChange={handleSelected} */}
          {/*   > */}
          {/*     <Radio value={1} checked={selected === 1}> */}
          {/*       Manually */}
          {/*     </Radio> */}
          {/*     {formType === 'ADD' && ( */}
          {/*       <Radio value={2} checked={selected === 2}> */}
          {/*         From templates */}
          {/*       </Radio> */}
          {/*     )} */}
          {/*   </Group> */}
          {/* </Col> */}

          {/* All conditions is selected then display Audience Conditions */}
          {selected === 1 && (
            <Col span={24}>
              <AllConditions
                setSelectedSegmentRules={setSelectedSegmentRules}
                selectedSegmentRules={selectedSegmentRules}
                currentEditedSegment={currentEditedSegment}
              />
            </Col>
          )}

          {/* From templates is selected then display Audience Templates */}
          {selected === 2 && (
            <Col span={24} className={classes.templateRoot}>
              <FromTemplates setTemplateSelected={setTemplateSelected} />
            </Col>
          )}
        </Row>
      )}
    </Modal>
  );
};

export default AudienceConditions;
