/* eslint-disable no-console */
import { Button, Col, Form, Row, Select, Tag } from 'antd';
import classes from './AudienceCard.module.less';

const SelectAudience = function SelectAudience({
  handleChooseModalOpen,
  handleSegmentDeselect,
}) {
  // tags to be render in select
  const tagRender = (props) => {
    const { label, closable, value } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={() => handleSegmentDeselect(value)}
        className={classes.tag}
      >
        {label}
      </Tag>
    );
  };
  return (
    <Form.Item dependencies={['selected_segments']}>
      {(form) => (
        <Form.Item
          rules={[
            {
              type: 'array',
              min: 1,
              message: 'Select atleast one customer group',
            },
          ]}
          name='selected_segments'
          style={{ marginLeft: 64 }}
        >
          <Row wrap={false} gutter={[10]}>
            {form.getFieldValue('selected_segments').length !== 0 && (
              <Col flex='auto'>
                <Form.Item name='selected_segments'>
                  <Select
                    tagRender={tagRender}
                    mode='multiple'
                    showAction={['click']}
                    defaultActiveFirstOption={false}
                    open={false}
                    showSearch={false}
                    showArrow={false}
                    labelInValue
                    className={`${classes.select} fullWidth`}
                  />
                </Form.Item>
              </Col>
            )}
            <Col>
              <Button
                className={classes.addNewBtn}
                onClick={handleChooseModalOpen}
              >
                Choose customers
              </Button>
            </Col>
          </Row>
        </Form.Item>
      )}
    </Form.Item>
  );
};

export default SelectAudience;
