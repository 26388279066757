import { Typography, Row, Col } from 'antd';
import { useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ContainerLayout from '../../components/ContainerLayout';
import TopBar from '../../components/TopBar';
import BannerBar from '../../components/BannerBar';
import SideBar, { filterItems, FilterSelect } from './SideBar';
import Dashboard from './Dashboard';
import ShopContext from '../../context/ShopContext';
import salesImage from '../../assets/images/sales-growth.png';

const OfferPackPage = function OfferPackPage() {
  const { shop } = useContext(ShopContext);
  const [selectedFilter, setSelectedFilter] = useState(
    () => filterItems[0].key,
  );
  const navigate = useNavigate();

  const onSelectedFilterChange = useCallback((newFilter) => {
    setSelectedFilter(newFilter);
  }, []);

  const openCreateCampaignModel = () => {
    navigate('/campaigns/details', { state: { editMode: false } });
  };

  return (
    <ContainerLayout
      topBar={<TopBar />}
      bannerBar={
        <BannerBar
          onLaunchClick={openCreateCampaignModel}
          heading='Offers pack coming soon'
          imageSrc={salesImage}
          showButton={false}
          buttonText=''
          subHeading={`Hi ${
            shop && shop.shop_owner ? shop.shop_owner.split(' ')[0] : ''
          }, create an upsell offer in your post purchase page now.`}
          showExtraButton={false}
          extraButtonText=''
          onExtraButtonClick={() => {}}
        />
      }
      sideBar={
        <SideBar
          selectedFilter={selectedFilter}
          onSelectedFilterChange={onSelectedFilterChange}
          onCampaignLaunchClick={openCreateCampaignModel}
        />
      }
    >
      <div>
        <Row className='pcHidden'>
          <Col flex='auto'>
            <Typography.Title level={4}>Segments</Typography.Title>
          </Col>
          <Col>
            <FilterSelect
              selectedFilter={selectedFilter}
              onSelectedFilterChange={onSelectedFilterChange}
            />
          </Col>
        </Row>
        <Dashboard />
      </div>
    </ContainerLayout>
  );
};

export default OfferPackPage;
