import { Col, Row, Card, Tag, Typography, Button, Image } from 'antd';
import classes from './index.module.less';

const Template = function Template({ template, setTemplateSelected }) {
  return (
    <Card hoverable className={classes.templateCard}>
      <Row>
        {template.tags && template.tags.length > 0 && (
          <Col span={24}>
            {template.tags.map((elem) => (
              <Tag key={elem.id} color={elem.color} className={classes.tags}>
                {elem.name}
              </Tag>
            ))}
          </Col>
        )}
        <Col className={classes.coverImage} span={24}>
          <Image preview={false} src={template.icon} alt='cover' height={120} />
        </Col>
        <Col span={24}>
          <Typography.Title level={5}>{template.name}</Typography.Title>
          <Typography.Paragraph
            className={classes.description}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: 'more',
              tooltip: true,
            }}
          >
            {template.description}
          </Typography.Paragraph>
        </Col>
        <Col>
          <Button
            type='link'
            className={classes.button}
            onClick={() => {
              setTemplateSelected(template);
            }}
          >
            Get Started
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Template;
