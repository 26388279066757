/* eslint-disable consistent-return */
import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  Input,
  Modal,
  Row,
  Col,
  Card,
  Space,
  Typography,
  message,
  Spin,
} from 'antd';
import { default as SearchOutlined } from '@ant-design/icons/lib/icons/SearchOutlined';
import { default as PlusCircleOutlined } from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import debounce from 'lodash.debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import SegmentDetails from './SegmentDetails';

import classes from './index.module.less';
import AudienceConditions from '../AudienceConditions';
import CreateAudience from '../CreateAudience';
import useMounted from '../../hooks/useMounted';
import SettingsContext from '../../context/SettingContext';

const ChooseAudience = function ChooseAudience({
  segmentsSelected,
  handleModalClose,
  handleSegmentSelect,
  handleSegmentDeselect,
  handleSaveSegments,
  allCustomersId,
}) {
  const { settings } = useContext(SettingsContext);
  const mounted = useMounted();
  const [segments, setSegments] = useState([]);
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  const [isCreateSegmentModalOpen, setIsCreateSegmentModalOpen] =
    useState(false);
  const [selectedSegmentRules, setSelectedSegmentRules] = useState([]);
  const [segmentName, setSegmentName] = useState('');
  const [segmentDescription, setSegmentDescription] = useState('');
  const [segmentApiCalled, setSegmentApiCalled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [formType, setFormType] = useState('ADD');
  const [isLoading, setIsLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const [serachText, setSearchText] = useState('');
  const audienceRef = useRef();

  // Fetch segments based on filters
  const fetchSegments = useCallback(
    async (next = '') => {
      let res;
      try {
        res = await axios.get(`/segments?next=${next}&q=${serachText}`);
      } catch (err) {
        console.error(err);
      }
      if (!mounted.current) return;
      if (res) {
        if (next) {
          setSegments((prev) =>
            [...prev, ...res.data.results].filter(
              (audience) => audience.id !== allCustomersId,
            ),
          );
        } else {
          setSegments(
            [...res.data.results].filter(
              (audience) => audience.id !== allCustomersId,
            ),
          );
        }
        const { results, ...rest } = res.data;
        setMetaData(rest);
      } else message.error('Something went wrong');
    },
    [allCustomersId, mounted, serachText],
  );

  useEffect(() => {
    setIsLoading(true);
    fetchSegments().then(() => setIsLoading(false));
  }, [fetchSegments]);

  // open rules modal
  const onCreateNewClick = useCallback(() => {
    setIsRulesModalOpen(true);
  }, []);

  // close rules modal
  const closeRuleModal = () => {
    setIsRulesModalOpen(false);
  };

  // open audience confirmation modal (create audience)
  const openSegmentModal = () => {
    setIsCreateSegmentModalOpen(true);
  };

  // close audience confirmation modal (create audience)
  const closeSegmentModal = () => {
    setIsCreateSegmentModalOpen(false);
  };

  // handle save the selected audience
  const saveSegment = async () => {
    setSegmentApiCalled(true);

    // all the rules for the post req
    const rules = [];
    for (const item of selectedSegmentRules) {
      for (const cat of item.categories) {
        for (const attr of cat.attributes) {
          if (
            (attr.type === 'numberbox' || attr.type === 'datepicker') &&
            (attr.selectedOperator === 'between' ||
              attr.selectedOperator === 'notBetween' ||
              attr.selectedOperator === 'dateBetween' ||
              attr.selectedOperator === 'dateNotBetween')
          ) {
            attr.selectedValue = {
              start: attr.selectedValue
                ? attr.selectedValue
                : attr.selectedValue1,
              end: attr.selectedValue1
                ? attr.selectedValue1
                : attr.selectedValue,
            };
          }
          if (attr.type === 'datepicker') {
            if (
              attr.selectedOperator === 'between' ||
              attr.selectedOperator === 'notBetween' ||
              attr.selectedOperator === 'dateBetween' ||
              attr.selectedOperator === 'dateNotBetween'
            ) {
              if (!attr.selectedValue.start.includes('T00:00:00')) {
                attr.selectedValue.start = `${attr.selectedValue.start}T00:00:00`;
              }
              if (!attr.selectedValue.end.includes('T23:59:59')) {
                attr.selectedValue.end = `${attr.selectedValue.end}T23:59:59`;
              }
            } else if (!attr.selectedValue.includes('T00:00:00')) {
              attr.selectedValue = `${attr.selectedValue}T00:00:00`;
            }
          }
          rules.push({
            entity: item.entity,
            attr: attr.attr,
            operator: attr.selectedOperator,
            value: attr.selectedValue,
            category: cat.category,
          });
        }
      }
    }

    // post request data
    const postData = {
      name: segmentName,
      description: segmentDescription || null,
      rules,
      created_by: 'self',
    };

    try {
      let segment;
      setIsLoading(true);

      // Create new segment
      if (formType === 'ADD') {
        // eslint-disable-next-line no-unused-vars
        segment = await axios
          .post(`/segments`, postData)
          .then((res) => {
            // select the segment after creating it
            handleSegmentSelect(res.data);
            setSegmentName('');
            setSegmentDescription('');
            message.success('Audience created successfully');
          })
          .catch((error) =>
            message.error(
              error?.response?.data?.message ?? 'Something went wrong',
            ),
          )
          .finally(() => setIsLoading(false));
      }
      closeRuleModal();
      closeSegmentModal();
      // if (formType === 'ADD') {
      //   const items = [...segments];
      //   items.push(segment);
      //   setSegments(items);
      // }
      setSegmentApiCalled(false);
      // re-fetch all the segments after creating new
      fetchSegments();
    } catch (err) {
      message.error('Something went wrong');
      setSegmentApiCalled(false);
    }
  };

  // handle modal submit after selecting the audience
  const handleModalSubmit = () => {
    handleSaveSegments(segmentsSelected);
    handleModalClose();
  };

  const changeHandler = (event) => {
    setSearchText(event.target.value);
  };

  //  call api after 300ms based on input change (search field)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);

  // Audience modal search field
  const searchField = (
    <div className={classes.searchField}>
      <Input
        prefix={<SearchOutlined />}
        size='large'
        type='text'
        placeholder='Search'
        onChange={debouncedChangeHandler}
        className={classes.searchInput}
      />
    </div>
  );

  const footer = (
    <Space align='center' className={classes.footer}>
      <Typography.Text className={classes.segmentsSelected}>
        {`${segmentsSelected?.length} user segments selected`}
      </Typography.Text>
      <Button
        key='footer-button'
        className={`primaryButton ${classes.footerBtn}`}
        type='primary'
        onClick={handleModalSubmit}
      >
        Done
      </Button>
    </Space>
  );

  return (
    <>
      <Modal
        visible
        title='Choose customer groups'
        wrapClassName={classes.modalWrapper}
        className={classes.modal}
        onCancel={handleModalClose}
        maskClosable={false}
        maskTransitionName={null}
        width={650}
        footer={footer}
        centered
        // closable={false}
      >
        <Spin spinning={isLoading} tip='Loading...'>
          {settings?.isOnboardingCompleted && (
            <Row>
              <Col span={24} className={classes.searchContainer}>
                {searchField}
              </Col>
            </Row>
          )}

          {/* fetch audience when reaching the last element of the list with infinite scroll */}
          <InfiniteScroll
            dataLength={segments && segments.length}
            hasMore={metaData && metaData.hasNext}
            next={() => fetchSegments(metaData && metaData.next)}
            loader={
              <Spin spinning tip='Loading...'>
                <span style={{ display: 'none' }} />
              </Spin>
            }
            height={
              window.innerHeight <= 800
                ? `calc(${100}vh - ${55 + 61 + 56 + 16}px)`
                : 554
            }
          >
            <Row
              className={classes.infiniteScrollRow}
              gutter={[0, 8]}
              ref={audienceRef}
            >
              <Col span={24}>
                <Card
                  className={classes.createNewCard}
                  onClick={onCreateNewClick}
                >
                  <Space size={20}>
                    <PlusCircleOutlined className={classes.plusIcon} />
                    <Space
                      direction='vertical'
                      size={0}
                      className={classes.createNewTypo}
                    >
                      <Typography.Text
                        strong
                        className={`font14 ${classes.primaryText}`}
                      >
                        Create New Customer Group
                      </Typography.Text>
                      <Typography.Text className={classes.font12}>
                        Create a customer group based on 60+ attributes like
                        their country, devices etc.
                      </Typography.Text>
                    </Space>
                  </Space>
                </Card>
              </Col>

              {/* Audience Details */}
              {segments.map((segment) => (
                <SegmentDetails
                  key={segment.id}
                  segment={segment}
                  segmentsSelected={segmentsSelected}
                  handleSegmentSelect={handleSegmentSelect}
                  handleSegmentDeselect={handleSegmentDeselect}
                />
              ))}
            </Row>
          </InfiniteScroll>
        </Spin>
      </Modal>
      {/* Create new audience modal */}
      {isRulesModalOpen && (
        <AudienceConditions
          handleModalClose={closeRuleModal}
          handleModalOk={openSegmentModal}
          setSelectedSegmentRules={setSelectedSegmentRules}
          selectedSegmentRules={selectedSegmentRules}
          formType={formType}
        />
      )}

      {/* audience create confirm modal */}
      {isCreateSegmentModalOpen && (
        <CreateAudience
          handleModalOk={saveSegment}
          handleModalClose={closeSegmentModal}
          segmentName={segmentName}
          setSegmentName={setSegmentName}
          segmentDescription={segmentDescription}
          setSegmentDescription={setSegmentDescription}
          segmentApiCalled={segmentApiCalled}
          formType={formType}
        />
      )}
    </>
  );
};

export default ChooseAudience;
