import { Input, Form, Typography, Modal, Button } from 'antd';

import classes from './index.module.less';

const CreateAudience = function CreateAudience({
  handleModalOk,
  handleModalClose,
  segmentName,
  setSegmentName,
  segmentDescription,
  setSegmentDescription,
  segmentApiCalled,
  formType,
}) {
  const [form] = Form.useForm();

  const initialValues = { segmentName, segmentDescription };

  const footer = [
    <Button
      key='cancel-button'
      type='ghost'
      className={`primaryButton ${classes.footer}`}
      onClick={handleModalClose}
    >
      Cancel
    </Button>,
    <Button
      key='primary-button'
      className={`primaryButton ${classes.footer}`}
      type='primary'
      loading={segmentApiCalled}
      // handle form submit
      onClick={() => {
        form
          .validateFields()
          .then(() => {
            handleModalOk();
            form.resetFields();
          })
          .catch((info) => {
            // eslint-disable-next-line no-console
            console.log('Validate Failed:', info);
          });
      }}
    >
      {formType === 'ADD' ? 'Save' : 'Update'}
    </Button>,
  ];

  return (
    <Modal
      visible
      title={
        formType === 'ADD' ? 'Create customer group' : 'Edit customer group'
      }
      className={classes.modal}
      width={650}
      // onOk={handleModalOk}
      onCancel={handleModalClose}
      maskClosable={false}
      maskTransitionName={null}
      destroyOnClose
      centered
      footer={footer}
    >
      <Typography.Title level={2}>Save your customer group</Typography.Title>
      <Typography.Text>
        Give a meaningful name to this new customer group so that you can reuse
        them easily in the future
      </Typography.Text>
      <Form
        layout='vertical'
        initialValues={initialValues}
        form={form}
        className={classes.form}
      >
        <Form.Item
          label='Customer group name'
          name='segmentName'
          rules={[
            {
              required: true,
              message: 'Customer group name is required',
              whitespace: true,
            },
          ]}
        >
          <Input
            name='segmentName'
            type='text'
            onChange={(e) => setSegmentName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label='Description (optional)' name='segmentDescription'>
          <Input.TextArea
            name='segmentDescription'
            type='text'
            style={{ maxHeight: 200, minHeight: 100 }}
            onChange={(e) => setSegmentDescription(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAudience;
