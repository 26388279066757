/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { Image, message, Table } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
// import { default as LoadingOutlined } from '@ant-design/icons/lib/icons/LoadingOutlined';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import classes from './index.module.less';
import { decodeSelectedKeys } from '../../utils/formatProductData/SelectedKeys';

// const loadingIcon = <LoadingOutlined />;

// Table columns
const columns = [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    width: '70px',
    align: 'center',
  },
  {
    title: 'Collection',
    dataIndex: 'title',
    key: 'title',
  },
];

// Fetch data from the graphql query
// converts graphql query response to the format as required
const getCollections = (body) => {
  const keys = [];

  // map the response as required in the table columns
  // objects keys should be same as columns dataIndex and key
  const dataSource = body?.data?.collections?.edges?.map(
    (collection) =>
      body.data.collections && /**
       * ?  Returns collection details
       * *  title: String
       * *  id: String
       */ {
        key: collection?.node?.id,
        title: collection?.node?.title,
        cursor: collection?.cursor,
        image: (
          <Image
            preview={false}
            src={collection?.node?.image?.originalSrc}
            width={32}
            height={32}
          />
        ),
      },
  );

  return [dataSource, keys];
};

const Collections = function Collections({
  searchQuery,
  collections,
  storedSelectedRows,
  selectedRowKeys,
  handleRowSelect,
  handleRowDeselect,
  setCollections,
  handleDecodedKeys,
  setLoading,
}) {
  const table = useRef();
  // const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  //  call collection's API based on filters and pagination
  const fetchCollections = useCallback(async () => {
    const response = await axios
      .post('/graphql', {
        query: `
      {
          collections(first: 10, sortKey: TITLE,after: ${JSON.stringify(
            collections.after,
          )}, query: ${JSON.stringify(searchQuery)}) {
              pageInfo {
                  hasNextPage
                  }
          edges {
              node {
                  id
                  title
                  handle
                  image {
                    originalSrc
                    altText
                }
              }
              cursor
          }
          }
      }
  `,
      })
      .then((res) => Promise.resolve(res.data.body))
      .catch((error) => Promise.reject(error));

    return response;
  }, [collections.after, searchQuery]);

  useEffect(() => {
    let isMounted = true;

    setLoading(true);

    fetchCollections()
      .then((body) => {
        if (isMounted) {
          const [dataSource, keys] = getCollections(body);

          // Decode selected keys
          const decodedKeys = decodeSelectedKeys(
            storedSelectedRows,
            dataSource,
            false,
          );

          // save decoded keys
          handleDecodedKeys(decodedKeys);

          // save collections data
          setCollections({
            dataSource: [...dataSource],
            expandedKeys: [...keys],
            hasNextPage: body.data.collections.pageInfo.hasNextPage,
            after: body.data.collections.edges.at(-1)
              ? body.data.collections.edges.at(-1).cursor
              : null,
          });
        }
      })
      .catch(() => {
        if (isMounted) {
          message.error('Something went wrong');
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [page, searchQuery]);

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        handleRowSelect(selectedRows.map((row) => row.key));
      } else {
        handleRowDeselect([record.key]);
      }
    },
  };

  return (
    <InfiniteScroll
      dataLength={collections.dataSource.length}
      hasMore={collections.hasNextPage}
      next={() => setPage((prev) => prev + 1)}
      height={397}
    >
      <Table
        ref={table}
        className={classes.tableTree}
        columns={columns}
        dataSource={collections.dataSource}
        pagination={false}
        showHeader={false}
        // loading={{
        //   spinning: loading,
        //   indicator: loadingIcon,
        //   size: 'large',
        //   tip: 'Loading...',
        // }}
        scroll={{ x: 0, y: 'auto' }}
        rowSelection={{
          ...rowSelection,
          checkStrictly: false,
          selectedRowKeys,
          preserveSelectedRowKeys: true,
        }}
        expandable={{
          expandIcon: () => null,
        }}
      />
    </InfiniteScroll>
  );
};

export default Collections;
