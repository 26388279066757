import moment from 'moment';
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import { default as CalendarOutlined } from '@ant-design/icons/lib/icons/CalendarOutlined';
import { default as ClockCircleOutlined } from '@ant-design/icons/lib/icons/ClockCircleOutlined';
import { useLocation } from 'react-router-dom';
import { useRef } from 'react';
import classes from './AdvancedSettingsCard.module.less';

const { Text } = Typography;

const CommonUI = ({ editMode, style }) => (
  <Space direction='vertical' className='fullWidth' style={style}>
    <Row gutter={[10]}>
      <Col span={14}>
        <Form.Item dependencies={['start_date']}>
          {({ getFieldValue }) => (
            <Form.Item
              name='start_date'
              label='Start date'
              rules={[
                {
                  required: true,
                  message: 'Select Start Date',
                },
              ]}
            >
              <Input
                className={classes.dateInput}
                type='date'
                suffix={<CalendarOutlined />}
                min={
                  editMode &&
                  getFieldValue('start_date') &&
                  moment(getFieldValue('start_date')).toISOString() <=
                    moment(new Date()).toISOString()
                    ? moment(getFieldValue('start_date')).format('YYYY-MM-DD')
                    : moment(new Date()).format('YYYY-MM-DD')
                }
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          name='start_time'
          label='Start time'
          rules={[{ required: true, message: 'Select Start Time' }]}
        >
          <Input
            type='time'
            className={classes.dateInput}
            suffix={<ClockCircleOutlined />}
          />
        </Form.Item>
      </Col>
    </Row>
  </Space>
);

const NonEditModeUI = ({ style, children }) => {
  const ref = useRef('immediate');
  return (
    <div style={style}>
      <Form.Item
        name='campaignTimeSelection'
        initialValue='immediate'
        style={{ marginBottom: 10 }}
      >
        <Radio.Group>
          <Space direction='vertical' size={0}>
            <Radio value='immediate'>Start immediately</Radio>
            <Radio value='future'>Schedule for future</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item dependencies={['campaignTimeSelection']} noStyle>
        {(form) => {
          const currValue = form.getFieldValue('campaignTimeSelection');
          if (currValue !== ref.current) {
            ref.current = currValue;
            if (currValue === 'immediate') {
              form.setFieldsValue({
                start_date: moment(new Date()).format('YYYY-MM-DD'),
                start_time: moment(new Date()).format('HH:mm'),
                setEndDate: false,
                end_date: null,
                end_time: null,
              });
            }
          }

          if (currValue === 'future') {
            return (
              <div
                style={{
                  display: 'inline-block',
                  width: '100%',
                  maxWidth: 350,
                  // paddingLeft: 'calc(1rem + 8px)',
                  marginBottom: 10,
                }}
              >
                <CommonUI editMode={false} />
              </div>
            );
          }

          return null;
        }}
      </Form.Item>
      {children}
    </div>
  );
};

const EditModeUI = ({ style, children }) => (
  <>
    <CommonUI editMode style={{ ...style, maxWidth: 350 }} />
    {children}
  </>
);

export const AdvancedSettingsCardUI = ({ style }) => {
  const { state } = useLocation();
  const editMode = state?.editMode;

  const renderRemainingFields = ({ getFieldValue }) => {
    // is set end date checkbox is selected or not?
    const isSetEndDate = getFieldValue('setEndDate');

    // Get start date which will be min selection for the end date
    const minEndDate = getFieldValue('start_date');

    // If start_date and end_date is same
    // then min end_time will be start_time
    // else min end_time can be any
    const minEndTime =
      getFieldValue('start_date') === getFieldValue('end_date')
        ? getFieldValue('start_time')
        : null;
    return (
      <Space
        direction='vertical'
        className={`fullWidth ${classes.endDateInputSpace}`}
        style={!editMode ? { marginTop: 10 } : {}}
      >
        <Form.Item
          key='setEndDate'
          name='setEndDate'
          valuePropName='checked'
          noStyle={!isSetEndDate}
        >
          <Checkbox>Set end date</Checkbox>
        </Form.Item>
        {isSetEndDate && (
          <Row gutter={[10]} style={{ maxWidth: 360 }}>
            <Col span={14}>
              <Form.Item
                name='end_date'
                label='End date'
                rules={[{ required: true, message: 'Select End Date' }]}
              >
                <Input
                  className={classes.dateInput}
                  suffix={<CalendarOutlined />}
                  type='date'
                  min={moment(minEndDate).format('YYYY-MM-DD')}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name='end_time'
                label='End Time'
                rules={[{ required: true, message: 'Select End Time' }]}
              >
                <Input
                  type='time'
                  className={classes.dateInput}
                  suffix={<ClockCircleOutlined />}
                  min={minEndTime}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Space>
    );
  };

  const child = (
    <Form.Item
      dependencies={['setEndDate', 'start_date', 'start_time', 'end_date']}
      noStyle
    >
      {renderRemainingFields}
    </Form.Item>
  );

  return (
    <Space direction='vertical' size='small' class={classes.contentContainer}>
      <Text className={classes.timeFormItemLabelText}>
        Campaign start settings
      </Text>
      {editMode ? (
        <EditModeUI style={style}>{child}</EditModeUI>
      ) : (
        <NonEditModeUI style={style}>{child}</NonEditModeUI>
      )}
    </Space>
  );
};

const AdvancedSettingsCard = function AdvancedSettingsCard() {
  return (
    <Card className={classes.collapseCard}>
      <AdvancedSettingsCardUI />
    </Card>
  );
};

export default AdvancedSettingsCard;
