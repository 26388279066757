/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useState, useEffect } from 'react';
import {
  Card,
  // Select,
  Space,
  Button,
  Form,
  Typography,
  message,
  Spin,
} from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import ShopContext from '../../../context/ShopContext';
import { productsRadio } from '../../../utils/constants/selectProductRadios';

import Step1 from '../Step1';
import Step2 from '../Step2';
import Step3 from '../Step3';
import Step4 from '../Step4';
import StepsModal from '../StepsModal';

import classes from './index.module.less';
import { decodeSegments } from '../../../utils/formatSelectedSegments/segments';
import { offerDiscountOptions } from '../../../utils/constants/discountTypes';
import SettingsContext from '../../../context/SettingContext';
import useMounted from '../../../hooks/useMounted';
import CreateCamapignForm from '../../Campaigns/CreateCampaignModel/ConfirmCampaignModal/CreateCamapignForm';
import getCampaignData from '../../../hooks/getCampaignData';
import ShopContext from '../../../context/ShopContext';

// const { Option } = Select;

const Welcome = function Welcome() {
  const { shop } = useContext(ShopContext);
  const navigate = useNavigate();
  // const { shop } = useContext(ShopContext);
  const mounted = useMounted();
  const { handleSettingsData } = useContext(SettingsContext);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRadioButton, setSelectedRadioButton] = useState(productsRadio);
  const [stepReached, setStepReached] = useState(0);
  const [defaultAudience, setDefaultAudience] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch all customers api
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    axios
      .get('/segments/all_customers')
      .then((res) => {
        if (isMounted) {
          const segments = decodeSegments([res.data]);
          setDefaultAudience(segments);
          setIsLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          message.error('Something went wrong');
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle product/collection radio button selected value
  const handleRadioSelect = useCallback((newValue) => {
    setSelectedRadioButton(newValue);
  }, []);

  // handle setup
  const handleSetupStart = () => {
    setIsModalOpen(true);
    setStepReached((prev) => prev + 1);
  };

  // handle steps modal close
  const handleModalClose = () => {
    setStepReached((prev) => prev - 1);
    setIsModalOpen(false);
  };

  // handle back button
  const handlePreviousStep = () => {
    setStepReached((prev) => prev - 1);
  };

  // handle campaign create
  const handleCreateCampaign = useCallback(
    async (campaign) => {
      setIsLoading(true);

      const data = getCampaignData(campaign);

      // create campaign post request
      await axios
        .post('/campaigns', data)
        .then(async () => {
          let res;
          try {
            res = await axios.put('/settings', { isOnboardingCompleted: true });
            await handleSettingsData(res.data);
            navigate('/campaigns');
          } catch (err) {
            console.error(err);
          }
          if (!mounted.current) return;
          if (!res) {
            message.error('Something went wrong');
          }
        })
        .then(() => {
          message.success('Campaign created successfully');
        })
        .then(() => {
          setIsLoading(false);
          handleModalClose();
          navigate('/campaigns');
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(
            error?.response?.data?.message ?? 'Something went wrong',
          );
          console.error(error);
        });
    },
    [handleSettingsData, mounted, navigate],
  );

  // handle Modal's Next / Finish Button
  const onFinish = useCallback(async () => {
    if (stepReached === 5) {
      handleCreateCampaign({
        ...form.getFieldsValue(true),
        selectedRadioButton,
        defaultAudience: defaultAudience[0]?.value,
      });
    } else {
      setStepReached((prev) => prev + 1);
    }
  }, [
    defaultAudience,
    form,
    handleCreateCampaign,
    selectedRadioButton,
    stepReached,
  ]);

  // on finish failed
  const onValidationFailed = () => {
    message.error('Please fill required fields');
  };

  // form initial values
  const initialValues = {
    name: '',
    description: '',
    audienceRadio: 0,
    selected_segments: [],
    start_date: moment(Date.now()).format('YYYY-MM-DD'),
    start_time: moment(Date.now()).format('HH:mm'),
    end_date: null,
    end_time: null,
    setEndDate: false,
    // free_shipping_to_offers: false,
    heading_text: ['Before you go!'],
    sub_heading_text:
      "Here's a special one-time offer. Add this to your order within {{timer}}",
    accept_offer_button_text: 'Pay now • {{total_amount}}',
    decline_offer_button_text: 'Decline upsell offer',
    offerProduct: shop?.password_enabled ? 2 : 1,
    products: [], // select product modal : selected products
    collections: [], // select product modal : selected collections
    discount_type: offerDiscountOptions.percent.value,
    fixed_discount_value: 1,
    percentage_discount_value: 25,
    offer_no_of_products_to_show: 1,
    campaignTimeout: 5,
    enableCampaignTimeout: true,
  };

  // handle save segments
  const handleSaveSegments = useCallback(
    (segments) => {
      form.setFieldsValue({
        selected_segments: [...segments],
      });
    },
    [form],
  );

  // handle remove segments
  const handleRemoveSegment = useCallback(
    (id) => {
      form.setFieldsValue({
        selected_segments: [
          ...form
            .getFieldValue('selected_segments')
            .filter((segment) => segment.value !== id),
        ],
      });
    },
    [form],
  );

  // handle save products
  const handleSaveProducts = useCallback(
    (products) => {
      form.setFieldsValue({
        products,
      });
    },
    [form],
  );

  // handle save collections
  const handleSaveCollections = useCallback(
    (collections) => {
      form.setFieldsValue({
        collections,
      });
    },
    [form],
  );

  // const cardTitle = (
  //   <div className={classes.cardTitle}>
  //     <Image
  //       preview={false}
  //       src={CEOImage}
  //       width={100}
  //       height={100}
  //       alt='CEO MLveda'
  //     />
  //   </div>
  // );

  // all steps markup
  const stepsMarkup = {
    1: (
      <Form.Item dependencies={['selected_segments']}>
        {() => {
          const segments = form.getFieldValue('selected_segments');
          return (
            <Step1
              segments={segments}
              handleSaveSegments={handleSaveSegments}
              handleRemoveSegment={handleRemoveSegment}
              allCustomersId={defaultAudience[0]?.value}
            />
          );
        }}
      </Form.Item>
    ),
    2: (
      <Step2
        selectedRadioButton={selectedRadioButton}
        handleRadioSelect={handleRadioSelect}
        handleSaveCollections={handleSaveCollections}
        handleSaveProducts={handleSaveProducts}
      />
    ),

    3: <Step3 />,
    4: <Step4 />,
    5: <CreateCamapignForm />,
  };

  return (
    <div className={classes.page}>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          layout='vertical'
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onValidationFailed}
          onValuesChange={(changedValues) => {
            // validation for campaignTimeout is enabled? then check for variable is used or not!
            form.validateFields(['sub_heading_text']);
            if (
              changedValues?.enableCampaignTimeout === true &&
              !form.getFieldValue('sub_heading_text').includes('{{timer}}')
            ) {
              const subHeading = `${form.getFieldValue(
                'sub_heading_text',
              )} {{timer}}`;
              form.setFieldsValue({
                sub_heading_text: subHeading,
              });
            }
            if (changedValues.audienceRadio) {
              if (changedValues?.audienceRadio === 0)
                form.setFieldsValue({
                  selected_segments: [defaultAudience.value],
                });
              else {
                form.setFieldsValue({
                  selected_segments: [],
                });
              }
            }
          }}
          scrollToFirstError
          className={classes.form}
        >
          <div className={classes.container}>
            <Space direction='vertical'>
              {/* <div className={classes.languageTranslate}>
                <Select defaultValue='en' style={{ width: 80 }}>
                  <Option value='en'>En</Option>
                  <Option value='hi'>Hindi</Option>
                </Select>
              </div> */}
              <Card className={classes.card}>
                <Space direction='vertical' size={16}>
                  {/* <Typography.Text
                    strong
                  >{`Hi ${shop?.name},`}</Typography.Text> */}
                  {/* <Typography.Text className={classes.content}>
                    This App creates post purchase page and help you to create
                    offers to your customers based on more than 40 parameters.
                  </Typography.Text> */}
                  <div>
                    <Typography.Text strong>
                      Three points you must know
                    </Typography.Text>

                    <ol className={classes.orderList}>
                      <li>
                        This app creates offers on checkout page after the
                        payment step.
                      </li>
                      <li>
                        These offers are not intrusive and customers can accept
                        or decline the offer in a single click.
                      </li>
                      <li>
                        This is the easiest way to increase average order value
                        of a customer by up to 30% without spending any extra
                        money on marketing ads.
                      </li>
                    </ol>
                  </div>
                  <Space
                    direction='vertical'
                    wrap={false}
                    size={5}
                    className={classes.buttonGroup}
                  >
                    <Button
                      type='primary'
                      className='primaryButton'
                      onClick={handleSetupStart}
                    >
                      <Space
                        size={0}
                        wrap
                        align='center'
                        className={classes.btnText}
                      >
                        <Typography.Text>Start Setup</Typography.Text>{' '}
                        <Typography.Text style={{ marginLeft: 5 }}>
                          (Takes less than 60 sec)
                        </Typography.Text>
                        <i className='las la-arrow-right' />
                      </Space>
                    </Button>
                    {/* <Button type='link'>Upgrade to Super Success plan</Button> */}
                    <Typography.Text>
                      For any queries,{' '}
                      <Typography.Link
                        onClick={() => {
                          window.$crisp.push(['do', 'chat:open']);
                        }}
                      >
                        message us
                      </Typography.Link>{' '}
                      in the chat box.
                    </Typography.Text>
                  </Space>
                </Space>
              </Card>
            </Space>
          </div>
          <div id='steps-modal'>
            {isModalOpen && (
              <StepsModal
                stepReached={stepReached}
                handleModalClose={handleModalClose}
                handlePreviousStep={handlePreviousStep}
              >
                {stepsMarkup[stepReached]}
              </StepsModal>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default Welcome;
