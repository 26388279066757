/* eslint-disable no-console */
import { Card, Divider, Space } from 'antd';
import { useCallback, useState, useEffect } from 'react';
import ChooseAudience from '../../../../components/ChooseAudience';
import CardTitle from '../shared/CardTitle';
import AudienceList from './AudienceList';

const AudienceCard = function AudienceCard({
  segments,
  handleSaveSegments,
  handleRemoveSegment,
  allCustomersId,
}) {
  const [isChooseAudienceModalOpen, setIsChooseAudienceModalOpen] =
    useState(false);

  // Locally save the selected segments in modal
  const [segmentsSelected, setSegmentsSelected] = useState(segments);

  useEffect(() => {
    setSegmentsSelected(segments);
  }, [segments, isChooseAudienceModalOpen]);

  // handle select segments
  // save segments as object {label,value,key}
  //  as antd Select component has labelInValue prop true.
  const handleSegmentSelect = useCallback((segment) => {
    setSegmentsSelected((prev) => [
      ...prev,
      {
        value: segment?.id,
        label: segment?.name,
        key: segment?.id,
      },
    ]);
  }, []);

  // de-select selected segment based on its value (id as in props)
  const handleSegmentDeselect = useCallback((id) => {
    setSegmentsSelected((prev) =>
      prev.filter((segment) => segment.value !== id),
    );
  }, []);

  const handleChooseModalClose = useCallback(() => {
    setIsChooseAudienceModalOpen(false);
  }, []);
  const handleChooseModalOpen = useCallback(() => {
    setIsChooseAudienceModalOpen(true);
  }, []);

  return (
    <Card key='audience-card'>
      <Space className='fullWidth' direction='vertical' size={16}>
        <CardTitle
          description='Select the customers to whom you want to show this offer'
          title='Step 1: Customers'
          // helpText='Help'
        />
        <Divider style={{ marginBlock: 0 }} />

        <AudienceList
          handleChooseModalOpen={handleChooseModalOpen}
          handleRemoveSegment={handleRemoveSegment}
        />
      </Space>
      {isChooseAudienceModalOpen && (
        <ChooseAudience
          segmentsSelected={segmentsSelected}
          handleModalClose={handleChooseModalClose}
          handleSegmentSelect={handleSegmentSelect}
          handleSegmentDeselect={handleSegmentDeselect}
          handleSaveSegments={handleSaveSegments}
          allCustomersId={allCustomersId}
        />
      )}
    </Card>
  );
};

export default AudienceCard;
