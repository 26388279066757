import Layout, { Content } from 'antd/lib/layout/layout';
import Button from 'antd/lib/button';
import Result from 'antd/lib/result';
import Typography from 'antd/lib/typography';

import classes from './index.module.less';

const { Text } = Typography;

const SessionExpiredPage = ({ shop }) => (
  <Layout className={classes.layout}>
    <Content className={classes.content}>
      <Result
        status='403'
        title={<Text strong>Session expired</Text>}
        subTitle={
          <Text>
            Your session expired. Please go to apps and open the app again.
          </Text>
        }
        extra={
          shop?.myshopify_domain ? (
            <Button
              href={`https://${shop?.myshopify_domain}/admin/apps`}
              target='_blank'
              type='primary'
            >
              Redirect
            </Button>
          ) : null
        }
      />
    </Content>
  </Layout>
);

export default SessionExpiredPage;
