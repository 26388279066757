export const offerDiscountOptions = {
  percent: {
    label: 'Percentage Discount',
    value: 'percentage',
  },
  fixed: {
    label: 'Fixed Discount',
    value: 'fixed_amount',
  },
  // no: {
  //   label: 'No Discount',
  //   value: 'none',
  // },
};
