import { Typography, Tooltip } from 'antd';
import { default as QuestionCircleOutlined } from '@ant-design/icons/lib/icons/QuestionCircleOutlined';

import classes from './CardTitle.module.less';

const { Text, Title } = Typography;

const CardTitle = function CardTitle({ title, description, helpText = null }) {
  return (
    <div>
      <div>
        <Title
          level={5}
          style={{ fontSize: 18, marginBottom: 0, lineHeight: 1.4 }}
        >
          {title}{' '}
          {helpText && (
            <Tooltip title={helpText}>
              <QuestionCircleOutlined className={classes.questionIcon} />
            </Tooltip>
          )}
        </Title>
      </div>
      {description && (
        <Text type='secondary' style={{ whiteSpace: 'break-spaces' }}>
          {description}
        </Text>
      )}
    </div>
  );
};

export default CardTitle;
