import { Button, Form, Modal, Space, Typography } from 'antd';
import { default as QuestionCircleOutlined } from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import { useMemo } from 'react';
import moment from 'moment';
import CardTitle from '../../Campaigns/CreateCampaignModel/shared/CardTitle';

import classes from './index.module.less';

const StepsModal = function StepsModal({
  stepReached,
  handleModalClose,
  handlePreviousStep,
  children,
}) {
  // modal header's based on stepReached
  const header = useMemo(() => {
    switch (stepReached) {
      case 1: {
        return (
          <CardTitle
            title='Step 1: Customers'
            description='Select the customers to whom you want to show this offer'
            // helpText='Help'
          />
        );
      }
      case 2: {
        return (
          <CardTitle
            title='Step 2: Products'
            description='Select the products that you want to upsell'
            // helpText='Help'
          />
        );
      }
      case 3: {
        return (
          <CardTitle
            title='Step 3: Discount'
            description='Select the discount type and its corresponding value '
            // helpText='Help'
          />
        );
      }
      case 4: {
        return (
          <CardTitle
            title='Final Step: Upsell Offer Preview'
            // description='Configure the message your customers will see in their offer'
            // helpText='Help'
          />
        );
      }
      case 5: {
        return (
          <CardTitle
            title='Save & start your campaign'
            description='Give a meaningful name and description to your campaign for your reference '
          />
        );
      }
      default:
        return null;
    }
  }, [stepReached]);

  // modal's footer
  const footer = (
    <Space
      wrap={false}
      align='center'
      style={{ justifyContent: 'space-between', display: 'flex' }}
    >
      <Typography.Link
        onClick={() => {
          window.$crisp.push(['do', 'chat:open']);
        }}
        className={classes.helpText}
      >
        Need Help <QuestionCircleOutlined className={classes.questionMark} />
      </Typography.Link>

      <Space>
        <Button
          type='ghost'
          className='primaryButton'
          onClick={stepReached === 1 ? handleModalClose : handlePreviousStep}
        >
          {stepReached === 1 ? 'Cancel' : 'Back'}
        </Button>
        <Form.Item noStyle dependencies={[]}>
          {(form) => {
            const startDate = form.getFieldValue('start_date');
            const startTime = form.getFieldValue('start_time');
            const isInPast =
              startTime && startDate
                ? moment(`${startDate} ${startTime}`).isBefore(moment())
                : false;

            return (
              <Button
                type='primary'
                className='primaryButton'
                htmlType='submit'
              >
                {stepReached === 5
                  ? isInPast
                    ? 'Start Campaign'
                    : 'Schedule Campaign'
                  : 'Next'}{' '}
                <i className='las la-arrow-right' />
              </Button>
            );
          }}
        </Form.Item>
      </Space>
    </Space>
  );
  return (
    <Modal
      visible
      title={header}
      width={700}
      footer={footer}
      closable={false}
      centered
      mask
      maskStyle={{ backgroundColor: '#36363678' }}
      getContainer={() => document.getElementById('steps-modal')}
      maskClosable={false}
      maskTransitionName={null}
      onCancel={handleModalClose}
      className={classes.modal}
      // bodyStyle={{ height: 320, overflow: 'auto' }}
    >
      {children}
    </Modal>
  );
};

export default StepsModal;
