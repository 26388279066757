/**
 * *  Encode Selected keys for POST request
 *
 * @param {Array of DataSource} rows
 * @param {Array of Selected keys} selectedKeys
 * @param {Boolean true: products / false : collections} bool
 * @returns
 */

export const encodeSelectedKeys = (rows, selectedKeys, bool) => {
  const keys = rows.map((row) => {
    let selected = false;
    let id;
    let variantIds = [];

    // check for products or collections selected keys
    if (bool) {
      id = Number(row.key.replace('gid://shopify/Product/', ''));
    } else {
      id = Number(row.key.replace('gid://shopify/Collection/', ''));
    }

    if (selectedKeys.includes(row.key)) {
      selected = true;
    }

    // check for product variants.
    if (bool) {
      // selected variant ids
      variantIds =
        row && row.children && row.children.length !== 0
          ? row.children
              .map((elem) => {
                if (selectedKeys.includes(elem.key)) {
                  selected = true;
                  return Number(
                    elem.key.replace('gid://shopify/ProductVariant/', ''),
                  );
                }
                return null;
              })
              .filter((elem) => elem !== null)
          : [
              Number(
                row.firstVariantId.replace('gid://shopify/ProductVariant/', ''),
              ),
            ];
    }
    return {
      selected,
      id,
      variant_ids: variantIds,
    };
  });
  return keys
    .filter((product) => product.selected)
    .map((product) => ({
      id: product.id,
      variant_ids: product.variant_ids,
    }));
};

/**
 * *  Decode Selected keys from GET request
 *
 * @param {Api data =>  Products / Collections} storedSelectedRows
 * @param {Table Rows} rows
 * @param {boolean true: products / false : collections} bool
 * @returns
 */

// products from api, rows => table rows

export const decodeSelectedKeys = (storedSelectedRows, rows, bool) => {
  const keys = [];
  storedSelectedRows.map((item) => {
    if (bool) {
      // For products data will be array of objects (product id = item.id)
      let parentKey = true;

      // Check for all variants are selected => yes then add product id to keys
      for (const row of rows) {
        if (row.key === `gid://shopify/Product/${item.id}`) {
          const { children } = row;

          // for variants
          item &&
            item.variant_ids.map((variant) => {
              keys.push(`gid://shopify/ProductVariant/${variant}`);
              return `gid://shopify/ProductVariant/${variant}`;
            });

          for (const elem of children) {
            if (
              !item.variant_ids.includes(
                elem.key.replace('gid://shopify/ProductVariant/', ''),
              )
            ) {
              parentKey = false;
              break;
            }
          }
          if (parentKey) {
            keys.push(`gid://shopify/Product/${item.id}`);
          } else {
            break;
          }
        }
      }
    } else {
      // for collection data will be in format of array of string so (collection id = item).
      keys.push(`gid://shopify/Collection/${item}`);
    }
    return item;
  });
  return keys;
};

export const productSelectedCount = (selectedProducts) => {
  let count = 0;

  for (const item of selectedProducts) {
    count += item.variant_ids.length;
  }
  return count;
};

export const removeArrayDuplicate = (data) => {
  const filtered = [...data].reduce((a, b) => {
    if (a.indexOf(Number(b)) < 0) {
      a.push(b);
    }
    return a;
  }, []);

  return filtered;
};

export const removeArrayOfObjectsDuplicate = (data) => {
  const filtered = data.reverse().reduce(
    (previous, current) => {
      const key = current.key || current.id;

      if (previous.temp.indexOf(key) === -1) {
        previous.output.push(current);
        previous.temp.push(key);
      }
      return previous;
    },
    // initial values for reduce function
    {
      temp: [],
      output: [],
    },
  ).output;

  return filtered;
};
