import { useCallback, useState } from 'react';
import { Button, Card, Tag, Col, Typography, Row, Space, Form } from 'antd';
import { default as ShopOutlined } from '@ant-design/icons/lib/icons/ShopOutlined';
import { default as CheckCircleOutlined } from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import { default as PlusOutlined } from '@ant-design/icons/lib/icons/PlusOutlined';
import { default as CheckOutlined } from '@ant-design/icons/lib/icons/CheckOutlined';
import AudienceDetail from '../AudienceDetail';
import classes from './index.module.less';

const shopIcon = <ShopOutlined />;

const checkedIcon = <CheckCircleOutlined className={classes.checkedIcon} />;

const SegmentDetails = function SegmentDetails({
  segment,
  segmentsSelected,
  handleSegmentSelect,
  handleSegmentDeselect,
}) {
  const [toggleDetail, setToggleDetail] = useState(false);

  // toggle show details
  const handleToggleDetail = useCallback(() => {
    setToggleDetail((prev) => !prev);
  }, []);

  const audienceDetail = toggleDetail && (
    <AudienceDetail
      segmentData={{ rules: segment.rules }}
      onEditSegmentClicked={() => {}}
      showEditIcon={false}
    />
  );

  const getSelectButtons = (form) => {
    // get selected segments by value
    const selected = segmentsSelected.map(
      (selectedSegment) => selectedSegment.value,
    );

    // check specific segment is selected or not
    return selected.includes(segment.id) ? (
      <Button
        className={`primaryButton ${classes.selectBtn} ${classes.selected}`}
        onClick={() => handleSegmentDeselect(segment.id)}
      >
        <Space size={4} wrap={false}>
          <CheckOutlined />
          Selected
        </Space>
      </Button>
    ) : (
      <Button
        className={`primaryButton ${classes.selectBtn} ${classes.default}`}
        onClick={() => handleSegmentSelect(segment)}
      >
        <Space size={4} wrap={false}>
          <PlusOutlined />
          Select
        </Space>
      </Button>
    );
  };

  return (
    <Col span={24} className={classes.audienceCard}>
      <Card>
        <Space
          direction='vertical'
          wrap={false}
          className={classes.audienceCardSpace}
          size={6}
        >
          <Row
            justify='space-between'
            align='top'
            gutter={[24, 6]}
            wrap={false}
          >
            <Col sm={16} md={18}>
              <Space direction='vertical' size={0}>
                {/* Title with checked icon */}
                <Typography.Text strong className='font14'>
                  {segment.name}{' '}
                  {segment.created_by === 'default' && checkedIcon}
                </Typography.Text>
                <Space size={4} direction='vertical'>
                  {/* Description */}
                  <Typography.Text className='font14'>
                    {segment.description}
                  </Typography.Text>

                  {/* Total Shop Tag */}
                  {/* <Tag className={classes.tag}>
                    {shopIcon}
                    {` ${segment.used_in_campaigns.length}+ Stores`}
                  </Tag> */}
                </Space>
              </Space>
            </Col>

            <Col sm={8} md={6}>
              <Form.Item dependencies={['selected_segments']}>
                {getSelectButtons}
              </Form.Item>
              <Button type='link' onClick={handleToggleDetail}>
                <Typography.Text underline className={classes.seeDetailBtn}>
                  See details
                </Typography.Text>
              </Button>
            </Col>
          </Row>
          {audienceDetail}
        </Space>
      </Card>
    </Col>
  );
};

export default SegmentDetails;
