import { Navigate } from 'react-router-dom';

const ProtectedRoute = function ProtectedRoute({ settings, children }) {
  if (settings?.isOnboardingCompleted) {
    return children;
  }

  return <Navigate replace to='/' />;
};
export default ProtectedRoute;
